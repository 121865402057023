import { Card } from "@/components/ui/card";
import { Eye, Heart, MessageSquare, Star, Link as LinkIcon, Share2, Lock } from "lucide-react";
import { Button } from "@/components/ui/button";

interface Post {
  id: number;
  text: string;
  views: number;
  likes: number;
  comments: number;
  shares: number;
  isTopPerformer: boolean;
  link: string;
  thumbnail: string;
}

interface RecentPostsListProps {
  posts: Post[];
}

export const RecentPostsList = ({ posts }: RecentPostsListProps) => (
  <div className="space-y-6">
    <h3 className="text-lg font-semibold text-gray-900">Recent Posts</h3>
    <div className="grid gap-4">
      {posts.map((post) => (
        <Card key={post.id} className="p-6 hover:shadow-lg transition-all duration-300">
          <div className="flex gap-4">
            <img 
              src={post.thumbnail} 
              alt="Post thumbnail" 
              className="w-24 h-24 rounded-lg object-cover bg-gray-100 transition-transform hover:scale-105"
            />
            <div className="flex-1">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <p className="text-gray-900 mb-3">{post.text}</p>
                  <div className="flex flex-wrap gap-6">
                    <div className="flex items-center gap-2 group">
                      <Eye className="w-4 h-4 text-gray-500 group-hover:text-linkedin-primary transition-colors" />
                      <span className="text-sm text-gray-600 group-hover:text-linkedin-primary transition-colors">
                        {post.views.toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 group">
                      <Heart className="w-4 h-4 text-gray-500 group-hover:text-red-500 transition-colors" />
                      <span className="text-sm text-gray-600 group-hover:text-red-500 transition-colors">
                        {post.likes}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 group">
                      <MessageSquare className="w-4 h-4 text-gray-500 group-hover:text-linkedin-primary transition-colors" />
                      <span className="text-sm text-gray-600 group-hover:text-linkedin-primary transition-colors">
                        {post.comments}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 group">
                      <Share2 className="w-4 h-4 text-gray-500 group-hover:text-linkedin-primary transition-colors" />
                      <span className="text-sm text-gray-600 group-hover:text-linkedin-primary transition-colors">
                        {post.shares}
                      </span>
                    </div>
                    <a 
                      href={post.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-linkedin-primary hover:text-linkedin-dark transition-colors"
                    >
                      <LinkIcon className="w-4 h-4" />
                    </a>
                  </div>
                </div>
                {post.isTopPerformer && (
                  <div className="bg-linkedin-primary/10 text-linkedin-primary text-xs font-medium px-3 py-1.5 rounded-full flex items-center gap-1.5 animate-fade-up">
                    <Star className="w-3.5 h-3.5" />
                    Top Performer
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>

    <div className="text-center space-y-4 pt-4">
      <div className="flex items-center justify-center gap-2 text-sm text-gray-600">
        <Lock className="w-4 h-4 text-linkedin-primary" />
        <span>Get detailed post analysis and content recommendations</span>
      </div>
      <Button
        className="bg-linkedin-primary/10 text-linkedin-primary hover:bg-linkedin-primary/20 transition-all"
      >
        Analyze All Posts with Pro
      </Button>
    </div>
  </div>
);