
export const pricingPlans = [
  {
    name: "Individual",
    price: "10",
    lifetimePrice: "100",
    description: "For solo professionals",
    features: [
      "**1 Profile**",
      "1 User",
      "**Extended Data History (90 Days)** ✨",
      "**Full Content Analysis**",
      "Enhanced Goal Tracking (Up to 5 Goals)",
      "**All Posts** ✨",
      "PDF Export",
      "Smart Recommendations",
      "Target Keywords (15)",
    ],
  },
  {
    name: "Pro",
    description: "For Growing Brands 🚀",
    price: "18",
    lifetimePrice: "180",
    popular: true,
    features: [
      "**Up to 5 Profiles** 🚀",
      "3 Users",
      "**AI-Powered Action Items** ✨",
      "**Full Data History (1 Year+)**",
      "**Competitor Tracking (3 Per Profile)** 🚀",
      "CSV Export",
      "Unlimited Goal Tracking",
      "Increased Keyword Limits (20 Per Profile)",
      "Priority Support",
    ],
  },
  {
    name: "Team",
    description: "For agencies & marketing teams",
    price: "35",
    lifetimePrice: "350",
    features: [
      "All Pro Features",
      "**Manage Up to 20 Profiles** 🚀",
      "**5 Users**",
      "**Aggregated Team Metrics** ✨",
      "Branded Reports",
      "**Comment & Sentiment Analysis** 🚀",
      "Priority Support",
      "Dedicated Account Manager",
    ],
  },
] as const;

export const getPlanByName = (planName?: string) => {
  if (!planName) return pricingPlans[0]; // Default to Individual plan
  return pricingPlans.find(plan => plan.name.toLowerCase() === planName.toLowerCase()) || pricingPlans[0];
};

export const getPlanFeatures = (planName?: string) => {
  const plan = getPlanByName(planName);
  return plan.features;
};

export const isPlanOrHigher = (userPlan: string, requiredPlan: string) => {
  const planRank = {
    'free': 0,
    'individual': 1,
    'pro': 2,
    'team': 3
  };
  
  const userRank = planRank[userPlan.toLowerCase() as keyof typeof planRank] || 0;
  const requiredRank = planRank[requiredPlan.toLowerCase() as keyof typeof planRank] || 0;
  
  return userRank >= requiredRank;
};
