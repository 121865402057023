import { FC, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, PieChart, Pie, Cell, BarChart, Bar } from "recharts";
import { Clock, Calendar, ChevronLeft, ChevronRight } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";

interface ContentAnalysisProps {
  tier: 'free' | 'individual' | 'pro';
  bestPostingTime: string;
  postTypes: {
    type: string;
    performance: number;
    trend: number;
  }[];
  mostUsedPostType: string;
}
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28BFC'];

// Generate impression data
const generateImpressionData = () => {
  const data = [];
  const now = new Date();
  for (let i = 29; i >= 0; i--) {
    const date = new Date();
    date.setDate(now.getDate() - i);
    data.push({
      date: date.toISOString().split('T')[0],
      impressions: 1000 + Math.floor(Math.random() * 2000)
    });
  }
  return data;
};

// Generate calendar data for the last 30 days
const generateCalendarData = () => {
  const data = [];
  const now = new Date();

  // Generate data for the last 30 days
  for (let i = 0; i < 30; i++) {
    const date = new Date();
    date.setDate(now.getDate() - 29 + i);
    data.push({
      date: date.toISOString().split('T')[0],
      day: date.getDate(),
      weekday: date.getDay(),
      posts: Math.random() > 0.7 ? Math.floor(Math.random() * 3) + 1 : 0 // 30% chance of having posts
    });
  }
  return data;
};

export const ContentAnalysis: FC<ContentAnalysisProps> = ({
  tier,
  bestPostingTime,
  postTypes,
  mostUsedPostType
}) => {
  const isMobile = useIsMobile();
  const isFree = tier === 'free';
  const pieData = postTypes.map(item => ({
    name: item.type,
    value: item.performance
  }));
  const impressionsData = generateImpressionData();
  const [calendarData, setCalendarData] = useState(generateCalendarData());
  
  // For calendar navigation
  const [currentDateRange, setCurrentDateRange] = useState({
    start: 0,
    end: 30
  });

  const handlePrevDateRange = () => {
    setCurrentDateRange(prev => ({
      start: prev.start + 30,
      end: prev.end + 30
    }));
    // Generate new data for previous period
    setCalendarData(generateCalendarData());
  };

  const handleNextDateRange = () => {
    if (currentDateRange.start === 0) return; // Don't go beyond current date
    setCurrentDateRange(prev => ({
      start: Math.max(0, prev.start - 30),
      end: Math.max(30, prev.end - 30)
    }));
    // Generate new data for next period
    setCalendarData(generateCalendarData());
  };

  // Create posting time heatmap data
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const hoursOfDay = ['12a', '4a', '8a', '12p', '4p', '8p'];

  // Generate posting time data with some patterns
  const postingTimeData = [];

  // Generate engagement data for each day-hour combination
  daysOfWeek.forEach(day => {
    hoursOfDay.forEach(hour => {
      // Simulate patterns in the data
      let baseEngagement = 0;

      // Higher engagement during work hours on weekdays
      if (['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].includes(day) && ['8a', '12p', '4p'].includes(hour)) {
        baseEngagement = 45;
      }

      // Higher engagement in evenings
      if (['4p', '8p'].includes(hour)) {
        baseEngagement = 40;
      }

      // Lower engagement during early morning hours
      if (['12a', '4a'].includes(hour)) {
        baseEngagement = 10;
      }

      // Special peak times
      if (day === 'Thu' && hour === '4p' || day === 'Sun' && hour === '12p' || day === 'Sun' && hour === '4p' || day === 'Tue' && hour === '12p') {
        baseEngagement = 85;
      }

      // Add some randomness
      const engagement = baseEngagement + Math.floor(Math.random() * 15);
      postingTimeData.push({
        day,
        hour,
        engagement
      });
    });
  });

  return <div className="space-y-8 mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Impressions Trend Chart */}
        <Card>
          <CardHeader className="pb-0">
            <CardTitle className="text-base">Estimated Impressions (Last 30 Days)</CardTitle>
          </CardHeader>
          <CardContent className="pt-4 h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={impressionsData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} opacity={0.3} />
                <XAxis dataKey="date" axisLine={false} tickLine={false} tickFormatter={date => {
                const d = new Date(date);
                return `${d.getDate()}/${d.getMonth() + 1}`;
              }} style={{
                fontSize: '0.7rem'
              }} />
                <YAxis axisLine={false} tickLine={false} tickFormatter={value => value >= 1000 ? `${(value / 1000).toFixed(0)}k` : value} style={{
                fontSize: '0.7rem'
              }} />
                <Tooltip formatter={value => [value.toLocaleString(), "Impressions"]} labelFormatter={label => new Date(label).toLocaleDateString()} />
                <Line type="monotone" dataKey="impressions" stroke="#0A66C2" strokeWidth={2} dot={false} activeDot={{
                r: 5
              }} />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        
        {/* Post Type Performance - Pie Chart */}
        <Card>
          <CardHeader className="pb-0">
            <CardTitle className="text-base">Post Type Performance</CardTitle>
          </CardHeader>
          <CardContent className="pt-4 h-[300px] flex justify-center">
            <div className="w-full h-full flex flex-col">
              <div className="flex-1">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie 
                      data={pieData} 
                      cx="50%" 
                      cy="50%" 
                      innerRadius={60} 
                      outerRadius={90} 
                      paddingAngle={5} 
                      dataKey="value" 
                      label={({
                        name,
                        percent
                      }) => isMobile ? `${(percent * 100).toFixed(0)}%` : `${name} (${(percent * 100).toFixed(0)}%)`} 
                      labelLine={false}
                    >
                      {pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                    </Pie>
                    <Tooltip formatter={value => [`${value} engagement`, "Performance"]} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              {isMobile && (
                <div className="mt-2 flex flex-wrap justify-center gap-2">
                  {pieData.map((entry, index) => (
                    <div key={index} className="flex items-center gap-1">
                      <div className="w-3 h-3 rounded-full" style={{ backgroundColor: COLORS[index % COLORS.length] }}></div>
                      <span className="text-xs">{entry.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        
        {/* Posting Activity Calendar */}
        <Card>
          <CardHeader className="pb-0 flex flex-row justify-between items-center">
            <CardTitle className="text-base">Posting Activity (Last 30 Days)</CardTitle>
            <div className="flex items-center space-x-2">
              <Button 
                variant="outline" 
                size="icon" 
                className="h-8 w-8"
                onClick={handlePrevDateRange}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button 
                variant="outline" 
                size="icon" 
                className="h-8 w-8"
                onClick={handleNextDateRange}
                disabled={currentDateRange.start === 0}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </CardHeader>
          <CardContent className="pt-4 h-[300px]">
            <div className="h-full">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={calendarData} margin={{
                top: 20,
                right: 5,
                left: 0,
                bottom: 5
              }}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} opacity={0.3} />
                  <XAxis dataKey="date" axisLine={false} tickLine={false} tickFormatter={date => {
                  const d = new Date(date);
                  return `${d.getDate()}/${d.getMonth() + 1}`;
                }} style={{
                  fontSize: '0.7rem'
                }} />
                  <YAxis axisLine={false} tickLine={false} style={{
                  fontSize: '0.7rem'
                }} />
                  <Tooltip formatter={value => [value, "Posts"]} labelFormatter={label => new Date(label).toLocaleDateString()} />
                  <Bar dataKey="posts" fill="#0A66C2" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
        
        {/* Best Posting Time - Compact Heat Map */}
        <Card>
          <CardHeader className="pb-0">
            <CardTitle className="text-base">Best Times to Post</CardTitle>
          </CardHeader>
          <CardContent className="pt-4 h-[300px]">
            <div className="flex flex-col w-full h-full">
              <div className="flex flex-col h-full">
                {/* Heatmap based on the provided image */}
                <div className="flex-1 flex flex-col">
                  {daysOfWeek.map((day, dayIndex) => <div key={dayIndex} className="flex items-center h-[35px]">
                      <div className="w-[40px] text-xs text-gray-500 font-medium flex-shrink-0">
                        {day}
                      </div>
                      <div className="flex-1 flex items-center">
                        {hoursOfDay.map((hour, hourIndex) => {
                      const dataPoint = postingTimeData.find(d => d.day === day && d.hour === hour);
                      const engagement = dataPoint ? dataPoint.engagement : 0;
                      const size = engagement > 80 ? "w-4 h-4" : engagement > 60 ? "w-3.5 h-3.5" : engagement > 40 ? "w-3 h-3" : engagement > 20 ? "w-2.5 h-2.5" : "w-2 h-2";
                      const opacity = engagement > 80 ? "opacity-100" : engagement > 60 ? "opacity-90" : engagement > 40 ? "opacity-80" : engagement > 20 ? "opacity-70" : engagement > 10 ? "opacity-60" : "opacity-50";
                      return <div key={hourIndex} className="flex-1 flex justify-center">
                              <div className={`rounded-full bg-indigo-400 ${size} ${opacity}`} title={`${day} at ${hour}: ${engagement}% engagement`}></div>
                            </div>;
                    })}
                      </div>
                    </div>)}
                </div>
                {/* X-axis labels for hours */}
                <div className="flex mt-2 pl-[40px]">
                  {hoursOfDay.map((hour, index) => <div key={index} className="flex-1 text-xs text-gray-500 text-center">
                      {hour}
                    </div>)}
                </div>
              </div>
            </div>
            
            <div className="mt-4 flex flex-wrap justify-center gap-2">
              <div className="flex items-center gap-1">
                <div className="w-3 h-3 rounded-full bg-indigo-400 opacity-100"></div>
                <span className="text-xs">High</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-3 h-3 rounded-full bg-indigo-400 opacity-80"></div>
                <span className="text-xs">Medium</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-3 h-3 rounded-full bg-indigo-400 opacity-60"></div>
                <span className="text-xs">Low</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>;
};
