
import { useState, useEffect } from "react";
import { UnifiedHeader } from "@/components/profile-analysis/UnifiedHeader";
import { ProfileGoalsSection } from "@/components/profile-analysis/ProfileGoalsSection";
import { ProfileAnalyticsSection } from "@/components/profile-analysis/ProfileAnalyticsSection";
import { ExportOptions } from "@/components/profile-analysis/ExportOptions";
import { UpgradePopup } from "@/components/profile-analysis/UpgradePopup";
import { AIInsights } from "@/components/profile-analysis/AIInsights";
import { CompetitorBenchmark } from "@/components/profile-analysis/CompetitorBenchmark";
import { AnalyzeUrlField } from "@/components/profile-analysis/AnalyzeUrlField"; 
import { useToast } from "@/components/ui/use-toast";
import { useIsMobile } from "@/hooks/use-mobile";
import { useNavigate } from "react-router-dom";
import { LinkedInProfileWizard } from "@/components/onboarding/LinkedInProfileWizard";
import { useUser } from "@clerk/clerk-react";

const FreeProfileAnalysis = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [profileData, setProfileData] = useState<any>(null);
  const [goals, setGoals] = useState<any[]>([]);
  const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
  const [competitors, setCompetitors] = useState<any[]>([]);
  const [upgradePopupContent, setUpgradePopupContent] = useState({
    title: "",
    description: "",
    features: [""],
    targetPlan: 'individual' as 'individual' | 'pro'
  });
  const [hasProjects, setHasProjects] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isLoaded, isSignedIn } = useUser();
  const isDevelopment = process.env.NODE_ENV === 'development' || window.location.hostname.includes('lovable.dev');
  
  useEffect(() => {
    // Check if there are any projects
    const projects = JSON.parse(localStorage.getItem('projects') || '[]');
    setHasProjects(projects.length > 0);
    
    // In production, if the user accidentally reaches this page directly without data,
    // redirect them to the homepage
    if (!isDevelopment && !hasProjects && isLoaded) {
      navigate('/', { replace: true });
    }
    
    setIsLoading(false);
  }, [isDevelopment, navigate, hasProjects, isLoaded]);

  // Listen for storage updates to refresh projects count
  useEffect(() => {
    const handleStorageUpdate = () => {
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      setHasProjects(projects.length > 0);
    };
    
    window.addEventListener('storageUpdated', handleStorageUpdate);
    return () => {
      window.removeEventListener('storageUpdated', handleStorageUpdate);
    };
  }, []);

  // Initialize with appropriate profile data once a project is selected
  useEffect(() => {
    if (hasProjects) {
      // Get current active project and profile
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      if (projects.length > 0) {
        const activeProject = projects[0];
        if (activeProject.profiles && activeProject.profiles.length > 0) {
          const activeProfile = activeProject.profiles[0];
          
          // In a real app, we would fetch real data for this profile here
          // For development purposes only, use mock data
          if (isDevelopment) {
            // Import is done dynamically only in development
            import('@/utils/mockProfileData').then(module => {
              const mockData = module.getMockProfileData('free');
              setProfileData(mockData);
              setGoals(mockData.goals.slice(0, 3));
            });
          }
        }
      }
    }
  }, [hasProjects, isDevelopment]);
  
  const handleAddGoal = () => {
    showUpgradePopup("Goal Customization Locked", "Custom goals help you track your LinkedIn growth more effectively.", ["Create custom goals based on your LinkedIn strategy", "Set specific targets for growth and engagement", "Track progress with detailed analytics over 90 days"], 'individual');
  };
  
  const showUpgradePopup = (title: string, description: string, features: string[], targetPlan: 'individual' | 'pro') => {
    setUpgradePopupContent({
      title,
      description,
      features,
      targetPlan
    });
    setIsUpgradePopupOpen(true);
  };
  
  const handleDateRangeChange = () => {
    showUpgradePopup("Extended History Locked", "See your LinkedIn performance over longer periods with an Individual plan.", ["Access up to 90 days of historical data", "View trend analysis over extended periods", "Track long-term growth patterns"], 'individual');
  };
  
  const handleViewAllPosts = () => {
    showUpgradePopup("All Posts Analysis Locked", "Access detailed insights for all your LinkedIn posts with an Individual plan.", ["See performance data for all posts over the last 90 days", "Sort and filter posts by various metrics", "Identify patterns in your best performing content"], 'individual');
  };
  
  const handleUpgradeClick = () => {
    navigate('/pricing');
  };

  const handleAnalysisComplete = (action: 'upgrade' | 'replace' | 'discard', newProfile: any) => {
    if (action === 'upgrade') {
      // Navigate to pricing page
      navigate('/pricing');
    } else if (action === 'replace') {
      // Replace the current profile with the new one
      toast({
        title: "Profile Updated",
        description: `Now tracking ${newProfile.name || 'new profile'}.`,
      });
      
      // Update the projects in localStorage
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      if (projects.length > 0 && projects[0].profiles.length > 0) {
        // Update the first profile in the first project
        projects[0].profiles[0].url = newProfile.url || 'https://linkedin.com/in/new-profile';
        projects[0].profiles[0].name = newProfile.name || 'New Profile';
        localStorage.setItem('projects', JSON.stringify(projects));
        
        // Trigger storage update event
        window.dispatchEvent(new Event('storageUpdated'));
      }
      
      // Update the displayed profile data
      setProfileData(newProfile);
      if (newProfile.goals) {
        setGoals(newProfile.goals.slice(0, 3));
      }
    } else {
      // Discard - just show a toast
      toast({
        title: "Analysis Discarded",
        description: "The new profile analysis has been discarded.",
      });
    }
  };

  // If not loaded yet, show loading state
  if (isLoading || !isLoaded) {
    return null;
  }
  
  // If user is signed in and has no projects, show the wizard
  if (isSignedIn && !hasProjects) {
    return (
      <div className="flex-1 p-4 md:p-6 overflow-auto flex items-center justify-center">
        <LinkedInProfileWizard />
      </div>
    );
  }
  
  // If there's no profile data, show a loading state or error
  if (!profileData) {
    return (
      <div className="flex-1 p-4 md:p-6 overflow-auto flex items-center justify-center">
        <p className="text-center text-gray-500">
          No profile data available. Please select a LinkedIn profile to analyze.
        </p>
      </div>
    );
  }
  
  return (
    <main className="flex-1 p-4 md:p-6 overflow-auto">
      <div className="max-w-5xl mx-auto">
        {/* Add the AnalyzeUrlField component at the top */}
        {isSignedIn && hasProjects && (
          <AnalyzeUrlField onAnalyzeComplete={handleAnalysisComplete} />
        )}
        
        <UnifiedHeader profileName={profileData.name} profileImageUrl={profileData.avatarUrl} headline={profileData.headline} tier="free" score={profileData.score} metrics={profileData.metrics} taggedTopics={profileData.topHashtags} />
        
        <AIInsights tier="free" profileData={{
          name: profileData.name,
          engagementRate: profileData.metrics.engagementRate,
          followerGrowth: profileData.metrics.followerChange,
          postFrequency: profileData.metrics.postFrequency,
          topHashtags: profileData.topHashtags
        }} onUpgrade={handleUpgradeClick} />
        
        <ProfileGoalsSection tier="free" goals={goals} onAddGoal={handleAddGoal} />
        
        <ProfileAnalyticsSection tier="free" profileData={profileData} onViewAllPosts={handleViewAllPosts} onDateRangeChange={handleDateRangeChange} />
        
        <CompetitorBenchmark tier="free" competitors={competitors} onAddCompetitor={() => navigate('/pricing')} />
        
        {/* Export options moved to bottom of report */}
        <div className="mt-8 pt-4 border-t border-gray-200">
          <h3 className="text-lg font-medium mb-4">Export</h3>
          <ExportOptions tier="free" />
        </div>
      </div>
      
      <UpgradePopup isOpen={isUpgradePopupOpen} onClose={() => setIsUpgradePopupOpen(false)} title={upgradePopupContent.title} description={upgradePopupContent.description} features={upgradePopupContent.features} targetPlan={upgradePopupContent.targetPlan} />
    </main>
  );
};

export default FreeProfileAnalysis;
