import { Trophy, ArrowUpRight } from "lucide-react";
import { Button } from "@/components/ui/button";

export const ProUpgradeButton = () => {
  return (
    <div className="space-y-2">
      <Button
        className="bg-gradient-to-r from-orange-500 to-orange-600 text-white group px-8 py-6 text-lg relative overflow-hidden hover:scale-105 transition-all duration-300"
      >
        <Trophy className="w-5 h-5 mr-2 transition-transform group-hover:rotate-12" />
        Upgrade to Pro
        <ArrowUpRight className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
      </Button>
      <p className="text-sm text-gray-600">
        Unlock competitor insights, advanced analytics, and post recommendations
      </p>
    </div>
  );
};