import { ChartLine, Users, Lightbulb } from "lucide-react";

export const FeaturesList = () => {
  return (
    <div className="space-y-8 text-center md:text-left">
      <div className="flex items-start space-x-4 p-4 hover:bg-white/50 rounded-lg transition-colors group">
        <div className="mt-1">
          <ChartLine className="w-5 h-5 text-linkedin-primary group-hover:scale-110 transition-transform" />
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Monitor performance metrics in real-time</h3>
          <p className="text-sm text-gray-600 mt-1">Track engagement and reach with instant analytics</p>
        </div>
      </div>
      
      <div className="flex items-start space-x-4 p-4 hover:bg-white/50 rounded-lg transition-colors group">
        <div className="mt-1">
          <Users className="w-5 h-5 text-linkedin-primary group-hover:scale-110 transition-transform" />
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Identify key demographics to grow</h3>
          <p className="text-sm text-gray-600 mt-1">Target and expand your audience effectively</p>
        </div>
      </div>
      
      <div className="flex items-start space-x-4 p-4 hover:bg-white/50 rounded-lg transition-colors group">
        <div className="mt-1">
          <Lightbulb className="w-5 h-5 text-linkedin-primary group-hover:scale-110 transition-transform" />
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Get AI-driven recommendations</h3>
          <p className="text-sm text-gray-600 mt-1">Optimize your content with smart insights</p>
        </div>
      </div>
    </div>
  );
};