import { Users, FileText, Eye, TrendingUp, Lock } from "lucide-react";
import { Card } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface MetricsOverviewProps {
  followers: number;
  followerGrowth: number;
  posts: number;
  views: number;
}

export const MetricsOverview = ({ followers, followerGrowth, posts, views }: MetricsOverviewProps) => {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card className="p-6 hover:shadow-lg transition-all duration-300 group">
          <div className="flex items-center gap-4">
            <Users className="w-8 h-8 text-linkedin-primary transition-transform group-hover:scale-110" />
            <div>
              <div className="text-2xl font-bold animate-fade-up">{followers.toLocaleString()}</div>
              <div className="text-sm text-gray-600">Followers</div>
              <div className="text-sm text-green-500 flex items-center mt-1 animate-fade-up">
                <TrendingUp className="w-4 h-4 mr-1" />
                +{followerGrowth}% growth
              </div>
            </div>
          </div>
        </Card>

        <Card className="p-6 hover:shadow-lg transition-all duration-300 group">
          <div className="flex items-center gap-4">
            <FileText className="w-8 h-8 text-linkedin-primary transition-transform group-hover:scale-110" />
            <div>
              <div className="text-2xl font-bold animate-fade-up">{posts}</div>
              <div className="text-sm text-gray-600">Total Posts</div>
            </div>
          </div>
        </Card>

        <Card className="p-6 hover:shadow-lg transition-all duration-300 group">
          <div className="flex items-center gap-4">
            <Eye className="w-8 h-8 text-linkedin-primary transition-transform group-hover:scale-110" />
            <div>
              <div className="text-2xl font-bold animate-fade-up">{views.toLocaleString()}</div>
              <div className="text-sm text-gray-600">Total Views</div>
            </div>
          </div>
        </Card>
      </div>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center justify-center gap-2 text-sm text-gray-600 cursor-pointer group">
              <Lock className="w-4 h-4 text-linkedin-primary" />
              <span className="group-hover:text-linkedin-primary transition-colors">
                See how these numbers compare to industry leaders with Pro
              </span>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Unlock competitor analysis and benchmarking</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};