
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useToast } from "@/components/ui/use-toast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Bell, Mail, LogOut, User, Trash2 } from "lucide-react";

const Settings = () => {
  const { user, isLoaded } = useUser();
  const { toast } = useToast();
  const [allEmailsEnabled, setAllEmailsEnabled] = useState(true);
  const [notificationPreferences, setNotificationPreferences] = useState({
    summaries: true,
    metricChanges: true,
    goalMilestones: false,
    productUpdates: true
  });
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState(false);
  const [pushPermissionStatus, setPushPermissionStatus] = useState<string>("default");

  useEffect(() => {
    if ('Notification' in window) {
      setPushPermissionStatus(Notification.permission);
    }
  }, []);

  useEffect(() => {
    if (!allEmailsEnabled) {
      setNotificationPreferences({
        summaries: false,
        metricChanges: false,
        goalMilestones: false,
        productUpdates: false
      });
    }
  }, [allEmailsEnabled]);

  const handleDeleteAccount = () => {
    toast({
      title: "Account deletion initiated",
      description: "We've started the process to delete your account. You'll receive a confirmation email shortly.",
    });
  };

  const handleNotificationPreferenceChange = (key: string, checked: boolean) => {
    setNotificationPreferences(prev => ({
      ...prev,
      [key]: checked
    }));
    
    if (checked) {
      setAllEmailsEnabled(true);
    } else {
      const anyEnabled = Object.keys(notificationPreferences)
        .filter(k => k !== key)
        .some(k => notificationPreferences[k as keyof typeof notificationPreferences]);
      
      if (!anyEnabled && !checked) {
        setAllEmailsEnabled(false);
      }
    }
  };

  const requestPushPermission = async () => {
    if (!('Notification' in window)) {
      toast({
        title: "Browser not supported",
        description: "Your browser doesn't support push notifications.",
        variant: "destructive"
      });
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      setPushPermissionStatus(permission);
      
      if (permission === 'granted') {
        setPushNotificationsEnabled(true);
        toast({
          title: "Notifications enabled",
          description: "You will now receive browser notifications for important updates."
        });
      } else {
        setPushNotificationsEnabled(false);
        toast({
          title: "Notifications disabled",
          description: "You won't receive browser notifications. You can change this in your browser settings.",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      toast({
        title: "Error enabling notifications",
        description: "There was a problem enabling notifications. Please try again.",
        variant: "destructive"
      });
    }
  };

  if (!isLoaded) {
    return <div className="flex justify-center items-center h-screen">Loading settings...</div>;
  }

  return (
    <div className="p-6">
      <div className="container mx-auto max-w-4xl space-y-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Account Settings</h1>
          <p className="text-muted-foreground mt-2">
            Manage your account settings and preferences
          </p>
        </div>

        <Separator />
        
        {/* Profile Section */}
        <Card>
          <CardHeader>
            <div className="flex items-center gap-2">
              <User className="h-5 w-5 text-primary" />
              <CardTitle>Profile Details</CardTitle>
            </div>
            <CardDescription>
              Manage your personal information and connected accounts
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="flex flex-col sm:flex-row gap-6 items-start sm:items-center">
              <Avatar className="h-20 w-20 border">
                {user?.imageUrl ? (
                  <AvatarImage src={user.imageUrl} alt={user.fullName || "User"} />
                ) : (
                  <AvatarFallback className="text-xl">{user?.fullName?.charAt(0) || "U"}</AvatarFallback>
                )}
              </Avatar>
              
              <div className="space-y-1 flex-1">
                <h3 className="font-medium text-lg">{user?.fullName || "User"}</h3>
                <p className="text-muted-foreground">{user?.primaryEmailAddress?.emailAddress || "No email address"}</p>
              </div>
              
              <Button variant="outline" size="sm" onClick={() => window.open("https://accounts.trackinfluence.com/user", "_blank")}>
                Edit Profile
              </Button>
            </div>
            
            <Separator />
            
            <div>
              <h3 className="font-medium mb-3">Linked Accounts</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center justify-between p-3 border rounded-md">
                  <div className="flex items-center gap-2">
                    <div className="h-8 w-8 bg-[#5865F2] rounded-full flex items-center justify-center">
                      <LogOut className="h-4 w-4 text-white" />
                    </div>
                    <span>Sign in with OAuth</span>
                  </div>
                  <Button variant="outline" size="sm">Connect</Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        
        {/* Notifications Section */}
        <Card>
          <CardHeader>
            <div className="flex items-center gap-2">
              <Bell className="h-5 w-5 text-primary" />
              <CardTitle>Notification Preferences</CardTitle>
            </div>
            <CardDescription>
              Control how and when you receive notifications
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Mail className="h-5 w-5 text-muted-foreground" />
                  <h3 className="font-medium">Email Notifications</h3>
                </div>
                <div className="flex items-center gap-2">
                  <Label htmlFor="all-emails">Enable all emails</Label>
                  <Switch 
                    id="all-emails" 
                    checked={allEmailsEnabled} 
                    onCheckedChange={setAllEmailsEnabled}
                  />
                </div>
              </div>
              
              <div className="space-y-3 pl-7">
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="summaries" 
                    checked={notificationPreferences.summaries}
                    disabled={!allEmailsEnabled}
                    onCheckedChange={(checked) => 
                      handleNotificationPreferenceChange('summaries', checked as boolean)
                    }
                  />
                  <Label htmlFor="summaries">Weekly performance summaries</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="metrics" 
                    checked={notificationPreferences.metricChanges}
                    disabled={!allEmailsEnabled}
                    onCheckedChange={(checked) => 
                      handleNotificationPreferenceChange('metricChanges', checked as boolean)
                    }
                  />
                  <Label htmlFor="metrics">Significant metric changes</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="milestones" 
                    checked={notificationPreferences.goalMilestones}
                    disabled={!allEmailsEnabled}
                    onCheckedChange={(checked) => 
                      handleNotificationPreferenceChange('goalMilestones', checked as boolean)
                    }
                  />
                  <Label htmlFor="milestones">Goal milestones</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="updates" 
                    checked={notificationPreferences.productUpdates}
                    disabled={!allEmailsEnabled}
                    onCheckedChange={(checked) => 
                      handleNotificationPreferenceChange('productUpdates', checked as boolean)
                    }
                  />
                  <Label htmlFor="updates">Product updates and new features</Label>
                </div>
              </div>
            </div>
            
            <Separator />
            
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Bell className="h-5 w-5 text-muted-foreground" />
                  <h3 className="font-medium">Browser Push Notifications</h3>
                </div>
                <Button 
                  variant={pushNotificationsEnabled ? "outline" : "default"}
                  onClick={requestPushPermission}
                  disabled={pushPermissionStatus === 'denied'}
                >
                  {pushPermissionStatus === 'granted' ? 'Enabled' : 
                   pushPermissionStatus === 'denied' ? 'Blocked by Browser' : 
                   'Enable Notifications'}
                </Button>
              </div>
              <p className="text-sm text-muted-foreground pl-7">
                {pushPermissionStatus === 'granted' ? 
                  'You will receive notifications for important updates and alerts.' : 
                  pushPermissionStatus === 'denied' ? 
                  'Notifications are blocked. Please update your browser settings to enable them.' : 
                  'Enable browser notifications to stay updated about significant changes in your LinkedIn performance.'}
              </p>
            </div>
          </CardContent>
        </Card>
        
        {/* Danger Zone */}
        <Card className="border-red-200">
          <CardHeader>
            <div className="flex items-center gap-2">
              <Trash2 className="h-5 w-5 text-red-500" />
              <CardTitle className="text-red-500">Danger Zone</CardTitle>
            </div>
            <CardDescription>
              Irreversible actions that will affect your account
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="font-medium">Delete Account</h3>
                  <p className="text-sm text-muted-foreground">
                    Permanently delete your account and all associated data
                  </p>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive">Delete Account</Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete your
                        account and remove all of your data from our servers.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={handleDeleteAccount}>
                        Delete Account
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Settings;
