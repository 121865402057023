import { Card } from "@/components/ui/card";
import { Users, FileText, Eye, TrendingUp } from "lucide-react";
import { LineChart, Line, ResponsiveContainer } from "recharts";

interface GlobalMetricsProProps {
  followers: number;
  followerGrowth: number;
  posts: number;
  views: number;
}

const generateSparklineData = (value: number, points: number = 7) => {
  return Array.from({ length: points }, (_, i) => ({
    value: value * (0.85 + Math.random() * 0.3),
  }));
};

export const GlobalMetricsPro = ({
  followers,
  followerGrowth,
  posts,
  views,
}: GlobalMetricsProProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <MetricCard
        icon={Users}
        label="Followers"
        value={followers}
        growth={followerGrowth}
        sparklineData={generateSparklineData(followers)}
      />
      <MetricCard
        icon={FileText}
        label="Total Posts"
        value={posts}
        sparklineData={generateSparklineData(posts)}
      />
      <MetricCard
        icon={Eye}
        label="Total Views"
        value={views}
        sparklineData={generateSparklineData(views)}
      />
      <MetricCard
        icon={TrendingUp}
        label="Engagement Rate"
        value={2.8}
        suffix="%"
        sparklineData={generateSparklineData(2.8)}
      />
    </div>
  );
};

interface MetricCardProps {
  icon: React.ElementType;
  label: string;
  value: number;
  growth?: number;
  suffix?: string;
  sparklineData: Array<{ value: number }>;
}

const MetricCard = ({
  icon: Icon,
  label,
  value,
  growth,
  suffix = "",
  sparklineData,
}: MetricCardProps) => {
  return (
    <Card className="p-6 hover:shadow-lg transition-all duration-300">
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Icon className="w-8 h-8 text-linkedin-primary" />
          {growth !== undefined && (
            <div className="text-sm text-green-500 flex items-center">
              <TrendingUp className="w-4 h-4 mr-1" />
              +{growth}%
            </div>
          )}
        </div>
        
        <div>
          <div className="text-2xl font-bold">
            {value.toLocaleString()}{suffix}
          </div>
          <div className="text-sm text-gray-600">{label}</div>
        </div>
        
        <div className="h-12">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={sparklineData}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#0A66C2"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Card>
  );
};