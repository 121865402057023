import { Lightbulb, ArrowRight } from "lucide-react";
import { Card } from "@/components/ui/card";

const suggestions = [
  {
    title: "Industry Trends Analysis",
    description: "Create a post discussing emerging trends in Technology and their impact",
    category: "Thought Leadership"
  },
  {
    title: "Project Showcase",
    description: "Share insights and achievements from your recent successful projects",
    category: "Case Study"
  },
  {
    title: "Video Content Strategy",
    description: "Create an engaging video post about current marketing trends",
    category: "Video Content"
  },
  {
    title: "Team Methodology",
    description: "Write about your team's best practices and innovative approaches",
    category: "Best Practices"
  },
  {
    title: "Industry Challenges",
    description: "Share your perspective on emerging challenges and solutions",
    category: "Problem Solving"
  }
];

export const ContentSuggestions = () => {
  return (
    <Card className="p-6 bg-gradient-to-br from-linkedin-primary/5 to-linkedin-light/5 border-linkedin-primary/20">
      <div className="flex items-center gap-2 text-linkedin-primary mb-6">
        <Lightbulb className="w-6 h-6" />
        <h4 className="font-semibold text-xl">Content Ideas</h4>
      </div>
      <div className="grid gap-4">
        {suggestions.map((suggestion, index) => (
          <div 
            key={index}
            className="p-4 bg-white/80 rounded-lg hover:bg-white hover:shadow-md transition-all duration-300 group cursor-pointer border border-gray-100"
          >
            <div className="flex items-start gap-3">
              <ArrowRight className="w-5 h-5 text-linkedin-primary opacity-0 group-hover:opacity-100 transition-opacity mt-1" />
              <div className="space-y-1">
                <h5 className="font-medium text-gray-900 group-hover:text-linkedin-primary transition-colors">
                  {suggestion.title}
                </h5>
                <p className="text-sm text-gray-600">
                  {suggestion.description}
                </p>
                <span className="inline-block text-xs font-medium text-linkedin-primary/70 bg-linkedin-primary/10 px-2 py-1 rounded-full">
                  {suggestion.category}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};