import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Card } from "@/components/ui/card";
import { Lock } from "lucide-react";
import { useState, useEffect } from 'react';

interface FollowerGrowthChartProps {
  data: Array<{
    date: string;
    followers: number;
  }>;
}

export const FollowerGrowthChart = ({ data }: FollowerGrowthChartProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [animatedData, setAnimatedData] = useState<typeof data>([]);

  useEffect(() => {
    setIsLoading(true);
    // Simulate data loading
    const timer = setTimeout(() => {
      setAnimatedData(data);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [data]);

  const getGrowthPercentage = () => {
    if (data.length < 2) return 0;
    const firstDay = data[0].followers;
    const lastDay = data[data.length - 1].followers;
    return ((lastDay - firstDay) / firstDay * 100).toFixed(1);
  };

  const getGrowthColor = (current: number, previous: number) => {
    if (current > previous) return "#22c55e";
    if (current < previous) return "#ef4444";
    return "#94a3b8";
  };

  const customizedData = animatedData.map((item, index) => ({
    ...item,
    color: index > 0 ? getGrowthColor(item.followers, data[index - 1].followers) : "#94a3b8"
  }));

  return (
    <Card className={`p-6 space-y-4 hover:shadow-lg transition-all duration-300 ${isLoading ? 'opacity-50' : 'opacity-100'}`}>
      <div>
        <h3 className="text-lg font-semibold mb-2">Follower Growth (Last 30 Days)</h3>
        <div className="text-sm text-gray-600">
          Your audience grew by <span className="text-green-500 font-medium">{getGrowthPercentage()}%</span> this month
        </div>
      </div>

      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={customizedData}>
            <XAxis 
              dataKey="date" 
              stroke="#94a3b8"
              fontSize={12}
              tickLine={false}
              axisLine={false}
            />
            <YAxis 
              stroke="#94a3b8"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value.toLocaleString()}`}
            />
            <Tooltip 
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const currentValue = payload[0].value as number;
                  const previousValue = label !== data[0].date ? 
                    data[data.findIndex(d => d.date === label) - 1].followers : 
                    currentValue;
                  const growth = ((currentValue - previousValue) / previousValue * 100).toFixed(1);
                  
                  return (
                    <div className="bg-white p-3 border rounded shadow-lg">
                      <p className="text-sm font-medium">{label}</p>
                      <p className="text-sm text-linkedin-primary">
                        {currentValue.toLocaleString()} followers
                      </p>
                      {label !== data[0].date && (
                        <p className={`text-sm ${Number(growth) > 0 ? 'text-green-500' : 'text-red-500'}`}>
                          {growth}% {Number(growth) > 0 ? '↑' : '↓'}
                        </p>
                      )}
                    </div>
                  );
                }
                return null;
              }}
            />
            <Line 
              type="monotone" 
              dataKey="followers" 
              stroke="#0A66C2" 
              strokeWidth={2}
              dot={{
                stroke: '#0A66C2',
                strokeWidth: 2,
                r: 4,
                fill: 'white'
              }}
              activeDot={{
                stroke: '#0A66C2',
                strokeWidth: 2,
                r: 6,
                fill: 'white'
              }}
              animationDuration={1500}
              animationBegin={0}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
        <div className="flex items-center gap-2 text-sm text-gray-600">
          <Lock className="w-4 h-4 text-linkedin-primary" />
          <span>Identify when your audience grows the fastest with Pro</span>
        </div>
      </div>
    </Card>
  );
};