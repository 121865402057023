
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Trophy, X } from "lucide-react";

interface UpgradePopupProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  features: string[];
  targetPlan: 'individual' | 'pro';
}

export const UpgradePopup: FC<UpgradePopupProps> = ({
  isOpen,
  onClose,
  title,
  description,
  features,
  targetPlan
}) => {
  const navigate = useNavigate();
  
  const handleUpgrade = () => {
    onClose();
    navigate('/pricing');
  };
  
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Trophy className="h-5 w-5 text-orange-500" /> 
            {title}
          </DialogTitle>
          <DialogDescription>
            {description}
          </DialogDescription>
        </DialogHeader>
        <div className="p-4 bg-gray-50 rounded-md">
          <h4 className="font-medium mb-2">With the {targetPlan === 'individual' ? 'Individual' : 'Pro'} plan, you can:</h4>
          <ul className="space-y-2 text-sm">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start gap-2">
                <div className="h-5 w-5 text-green-500 flex-shrink-0">✓</div>
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <DialogFooter className="flex-col sm:flex-row gap-2">
          <DialogClose asChild>
            <Button variant="outline" className="sm:w-auto w-full">
              <X className="h-4 w-4 mr-2" />
              Maybe Later
            </Button>
          </DialogClose>
          <Button 
            className="bg-orange-500 hover:bg-orange-600 sm:w-auto w-full" 
            onClick={handleUpgrade}
          >
            <Trophy className="h-4 w-4 mr-2" />
            Upgrade Now
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
