
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from "react-router-dom";

import RootLayout from "@/layouts/RootLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import Home from "@/pages/Index"; 
import { Pricing } from "@/components/Pricing"; 
import Login from "@/pages/SignIn"; 
import SignUp from "@/pages/SignUp";
import { UserAnalysisRedirect } from "@/components/UserAnalysisRedirect"; 
import { EmptyState } from "@/components/EmptyState"; 
import ProfileAudit from "@/pages/ProfileAudit";
import FreeProfileAnalysis from "@/pages/FreeProfileAnalysis";
import IndividualProfileAnalysis from "@/pages/IndividualProfileAnalysis";
import ProProfileAnalysis from "@/pages/ProProfileAnalysis";
import AllPostsAnalysis from "@/pages/AllPostsAnalysis";
import Settings from "@/pages/Settings";
import UserProfileLayout from "@/pages/UserProfileLayout";
import BrightDataTest from "@/pages/BrightDataTest";
import BrightDataDebugPage from "@/pages/BrightDataDebugPage";
import BrightDataGuidePage from "@/pages/BrightDataGuidePage";
import BrightDataIntegrationPage from "@/pages/BrightDataIntegrationPage";
import CallbackPage from "@/pages/CallbackPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      {/* Use Home component for the index page, not redirecting */}
      <Route index element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/callback" element={<CallbackPage />} />
      
      {/* Dashboard Layout */}
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route index element={<UserAnalysisRedirect />} />
        <Route path="project/:id" element={<EmptyState />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      
      <Route path="/user" element={<UserProfileLayout />} />
      
      <Route path="/analysis">
        <Route index element={<UserAnalysisRedirect />} />
        <Route path="free" element={<FreeProfileAnalysis />} />
        <Route path="individual" element={<IndividualProfileAnalysis />} />
        <Route path="pro" element={<ProProfileAnalysis />} />
      </Route>

      <Route path="/profile-audit" element={<ProfileAudit />} />
      <Route path="/all-posts" element={<AllPostsAnalysis />} />
      
      {/* Admin routes for BrightData */}
      <Route path="/admin/brightdata/test" element={<BrightDataTest />} />
      <Route path="/admin/brightdata/debug" element={<BrightDataDebugPage />} />
      <Route path="/admin/brightdata/guide" element={<BrightDataGuidePage />} />
      <Route path="/admin/brightdata/integration" element={<BrightDataIntegrationPage />} />
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
