
import { FC } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Eye, Heart, MessageSquare, Share2, Trophy, ArrowUpRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ExportButton } from "./ExportButton";
import { useIsMobile } from "@/hooks/use-mobile";

export interface Post {
  id: string;
  text: string;
  likes: number;
  comments: number;
  shares: number;
  views: number;
  date: string;
  image?: string;
  type?: string;
}

interface TopPostsListProps {
  tier: 'free' | 'individual' | 'pro';
  posts: Post[];
  onViewAllPosts?: () => void;
}

export const TopPostsList: FC<TopPostsListProps> = ({
  tier,
  posts,
  onViewAllPosts
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isFree = tier === 'free';
  const displayPosts = isFree ? posts.slice(0, 6) : posts;

  // Split posts into left and right columns (3 posts each)
  const leftColumnPosts = displayPosts.slice(0, 3);
  const rightColumnPosts = displayPosts.slice(3, 6);
  
  return (
    <div className="space-y-4 mb-10">
      <Card className="border border-gray-200">
        <CardHeader className="pb-2 flex flex-row items-center justify-between">
          <div>
            <CardTitle className="text-base">Recent Top Performers</CardTitle>
            {isMobile && <div className="text-xs text-gray-500 italic">Last 30 Days</div>}
          </div>
          {!isFree && (
            <Button 
              variant="outline" 
              size="sm" 
              className="text-xs bg-transparent" 
              onClick={onViewAllPosts}
            >
              View All Posts
            </Button>
          )}
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Left Column */}
            <div className="space-y-3">
              {leftColumnPosts.map(post => <PostCard key={post.id} post={post} />)}
            </div>
            
            {/* Right Column */}
            <div className="space-y-3">
              {rightColumnPosts.map(post => <PostCard key={post.id} post={post} />)}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

// Helper component for individual post cards
const PostCard = ({
  post
}: {
  post: Post;
}) => {
  return (
    <div className="p-3 border rounded-md hover:bg-gray-50 transition-colors">
      <div className="flex gap-3">
        <div className="flex-shrink-0 w-10 h-10 bg-linkedin-primary/10 rounded-md flex items-center justify-center">
          {post.type === 'image' ? (
            <img 
              src={post.image || `https://api.dicebear.com/7.x/shapes/svg?seed=${post.id}`} 
              alt="Post thumbnail" 
              className="w-full h-full object-cover rounded-md" 
            />
          ) : post.type === 'video' ? (
            <div className="relative w-full h-full bg-linkedin-primary/20 rounded-md flex items-center justify-center">
              <div className="w-3 h-3 bg-white rounded-full"></div>
            </div>
          ) : (
            <span className="text-xs font-medium text-linkedin-primary">Text</span>
          )}
        </div>
        
        <div className="flex-1 min-w-0">
          <p className="text-sm line-clamp-2 mb-2">{post.text}</p>
          
          <div className="flex items-center gap-3 text-xs text-gray-500">
            <div className="flex items-center gap-1">
              <Heart className="h-3 w-3" />
              <span>{post.likes}</span>
            </div>
            <div className="flex items-center gap-1">
              <MessageSquare className="h-3 w-3" />
              <span>{post.comments}</span>
            </div>
            <div className="flex items-center gap-1">
              <Share2 className="h-3 w-3" />
              <span>{post.shares}</span>
            </div>
            <div className="flex items-center gap-1">
              <Eye className="h-3 w-3" />
              <span>{post.views.toLocaleString()}</span>
            </div>
            <div className="ml-auto text-xs text-gray-400">
              {new Date(post.date).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
