
import { FC } from "react";
import { ProfileOptimization } from "./ProfileOptimization";
import { RecommendationsList } from "./RecommendationsList";
import { CompetitorMetrics } from "./types";

interface OptimizationAndRecommendationsProps {
  tier: 'free' | 'individual' | 'pro';
  profileCompleteness: number;
  strengths: string[];
  weaknesses: string[];
  recommendations: string[];
  competitorMetrics?: CompetitorMetrics;
}

export const OptimizationAndRecommendations: FC<OptimizationAndRecommendationsProps> = ({
  tier,
  profileCompleteness,
  strengths,
  weaknesses,
  recommendations,
  competitorMetrics
}) => {
  return (
    <div className="space-y-6 mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ProfileOptimization 
          tier={tier}
          profileCompleteness={profileCompleteness}
          strengths={strengths}
          weaknesses={weaknesses}
          competitorMetrics={competitorMetrics}
        />
        
        <RecommendationsList 
          tier={tier}
          recommendations={recommendations}
        />
      </div>
    </div>
  );
};
