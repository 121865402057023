
import { FC } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { CheckCircle2, AlertCircle } from "lucide-react";
import { CompetitorMetrics } from "./types";

interface ProfileOptimizationProps {
  tier: 'free' | 'individual' | 'pro';
  profileCompleteness: number;
  strengths: string[];
  weaknesses: string[];
  competitorMetrics?: CompetitorMetrics;
}

export const ProfileOptimization: FC<ProfileOptimizationProps> = ({
  profileCompleteness,
  strengths,
  weaknesses,
  competitorMetrics
}) => {
  // Determine text and color for profile completeness
  const getCompletenessText = () => {
    if (profileCompleteness >= 90) return "Excellent";
    if (profileCompleteness >= 70) return "Good";
    if (profileCompleteness >= 50) return "Moderate";
    return "Needs Improvement";
  };
  
  const getCompletenessColor = () => {
    if (profileCompleteness >= 90) return "bg-green-500";
    if (profileCompleteness >= 70) return "bg-blue-500";
    if (profileCompleteness >= 50) return "bg-orange-500";
    return "bg-red-500";
  };
  
  return (
    <Card className="overflow-hidden border border-gray-200 hover:shadow-md transition-all duration-300">
      <CardHeader className="bg-gray-50 pb-2">
        <CardTitle className="text-base">Profile Completeness</CardTitle>
      </CardHeader>
      <CardContent className="pt-4">
        <div className="mb-2 flex justify-between items-center">
          <span className="text-sm text-gray-500">Completeness Score</span>
          <span className="font-semibold">{profileCompleteness}% - {getCompletenessText()}</span>
        </div>
        <Progress 
          value={profileCompleteness} 
          className={`h-2 mb-6 ${getCompletenessColor()}`}
        />
        
        <div className="space-y-4">
          <div>
            <h4 className="text-sm font-medium mb-2 text-green-600">Strengths</h4>
            <ul className="space-y-1">
              {strengths.map((strength, idx) => (
                <li key={idx} className="text-sm flex items-start">
                  <CheckCircle2 className="h-4 w-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span>{strength}</span>
                </li>
              ))}
            </ul>
          </div>
          
          <div>
            <h4 className="text-sm font-medium mb-2 text-orange-600">Areas to Improve</h4>
            <ul className="space-y-1">
              {weaknesses.map((weakness, idx) => (
                <li key={idx} className="text-sm flex items-start">
                  <AlertCircle className="h-4 w-4 text-orange-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span>{weakness}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
