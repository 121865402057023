import { Card } from "@/components/ui/card";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { FileText, Image, Video, FileSpreadsheet, Award, Info } from "lucide-react";
import {
  Tooltip as UITooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const data = [
  { 
    type: "Text", 
    engagement: 45, 
    views: 2500, 
    icon: FileText, 
    color: "#94a3b8",
    description: "Plain text posts with insights and thoughts",
    isTopPerformer: false
  },
  { 
    type: "Image", 
    engagement: 65, 
    views: 3800, 
    icon: Image, 
    color: "#0ea5e9",
    description: "Posts with images, infographics, or screenshots",
    isTopPerformer: false
  },
  { 
    type: "Video", 
    engagement: 85, 
    views: 5200, 
    icon: Video, 
    color: "#22c55e",
    description: "Video content including tutorials and updates",
    isTopPerformer: true
  },
  { 
    type: "Document", 
    engagement: 55, 
    views: 2800, 
    icon: FileSpreadsheet, 
    color: "#f59e0b",
    description: "PDF documents, presentations, and articles",
    isTopPerformer: false
  },
];

const ContentTypeIcon = ({ type, color, isTopPerformer }: { type: string; color: string; isTopPerformer: boolean }) => {
  const iconMap = {
    Text: FileText,
    Image: Image,
    Video: Video,
    Document: FileSpreadsheet,
  };
  const Icon = iconMap[type as keyof typeof iconMap];
  
  return (
    <div className="relative">
      <Icon className="w-4 h-4" style={{ color }} />
      {isTopPerformer && (
        <Award className="absolute -top-2 -right-2 w-3 h-3 text-yellow-500 animate-pulse" />
      )}
    </div>
  );
};

export const ContentStrategy = () => {
  return (
    <Card className="p-6 space-y-6 animate-fade-up">
      <div>
        <h3 className="text-xl font-semibold">Content Strategy</h3>
        <p className="text-sm text-gray-600">
          Your video posts receive 30% more engagement than other content types
        </p>
      </div>

      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart 
            data={data} 
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            className="animate-fade-in"
          >
            <XAxis 
              dataKey="type" 
              stroke="#94a3b8"
              tick={({ x, y, payload }) => {
                const item = data.find(d => d.type === payload.value);
                return (
                  <g transform={`translate(${x},${y})`}>
                    <ContentTypeIcon 
                      type={payload.value} 
                      color={item?.color || '#94a3b8'} 
                      isTopPerformer={item?.isTopPerformer || false}
                    />
                    <text x={0} y={20} textAnchor="middle" fill="#64748b" fontSize={12}>
                      {payload.value}
                    </text>
                  </g>
                );
              }}
            />
            <YAxis stroke="#94a3b8" />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const contentType = data.find(item => item.type === label);
                  return (
                    <div className="bg-white p-4 border rounded-lg shadow-lg">
                      <div className="flex items-center gap-2 mb-2">
                        <ContentTypeIcon 
                          type={label} 
                          color={contentType?.color || '#94a3b8'} 
                          isTopPerformer={contentType?.isTopPerformer || false}
                        />
                        <span className="font-medium">{label}</span>
                        {contentType?.isTopPerformer && (
                          <span className="text-xs text-yellow-600 font-medium px-2 py-0.5 bg-yellow-50 rounded-full">
                            Top Performer
                          </span>
                        )}
                      </div>
                      <p className="text-sm text-gray-600 mb-1">
                        {contentType?.description}
                      </p>
                      <p className="text-sm">
                        Engagement: <span className="font-medium">{payload[0].value}%</span>
                      </p>
                      <p className="text-sm">
                        Views: <span className="font-medium">{contentType?.views.toLocaleString()}</span>
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend />
            <Bar
              dataKey="engagement"
              fill="#0A66C2"
              radius={[4, 4, 0, 0]}
              name="Engagement Rate"
              animationDuration={1500}
              className={`transition-all duration-300 hover:opacity-80`}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-green-50 p-4 rounded-lg border border-green-100">
          <h4 className="font-medium text-green-700 flex items-center gap-2">
            <Video className="w-4 h-4" />
            Best Performing
          </h4>
          <p className="text-sm text-green-600 mt-1">
            Video content (85% engagement)
          </p>
        </div>
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <h4 className="font-medium text-blue-700">Content Ideas</h4>
          <p className="text-sm text-blue-600 mt-1">
            Create a video post on current trends in video marketing
          </p>
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-lg">
        <TooltipProvider>
          <UITooltip>
            <TooltipTrigger className="flex items-center gap-2">
              <Info className="w-4 h-4 text-gray-500" />
              <h4 className="font-medium">Key Insight</h4>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-sm">Based on your audience engagement patterns</p>
            </TooltipContent>
          </UITooltip>
        </TooltipProvider>
        <p className="text-sm text-gray-600 mt-2">
          Your audience in Finance is growing – consider content tailored to professionals in this industry
        </p>
      </div>
    </Card>
  );
};