import { User, ChartBar, Activity } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

export const LinkedInCard = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-300">
      <div className="flex items-center space-x-4 mb-6">
        <div className="relative">
          <div className="absolute inset-0 bg-blue-400 rounded-full blur opacity-20 animate-pulse"></div>
          <Avatar className="w-14 h-14 relative border-2 border-white shadow-sm">
            <AvatarImage src="https://api.dicebear.com/7.x/avataaars/svg?seed=Felix" alt="Profile Picture" />
            <AvatarFallback>FD</AvatarFallback>
            <div className="absolute -bottom-1 -right-1 bg-green-500 rounded-full p-1.5 border-2 border-white">
              <div className="w-2 h-2 bg-white rounded-full"></div>
            </div>
          </Avatar>
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-gray-900">Felix Davidson</h3>
          <p className="text-sm text-gray-600">Senior Product Designer</p>
        </div>
      </div>
      
      <div className="flex justify-center mb-8">
        <div className="relative w-24 h-24">
          <svg className="transform -rotate-90 w-24 h-24">
            <circle cx="48" cy="48" r="36" stroke="#E5E7EB" strokeWidth="8" fill="none" />
            <circle
              cx="48"
              cy="48"
              r="36"
              stroke="#0A66C2"
              strokeWidth="8"
              fill="none"
              strokeDasharray="226.19"
              strokeDashoffset="33.93"
              className="transition-all duration-1000 ease-out animate-pulse"
            />
          </svg>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-2xl font-bold text-linkedin-primary">85%</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="p-3 rounded-lg hover:bg-gray-50 transition-colors group">
          <User className="w-5 h-5 mx-auto mb-2 text-linkedin-primary group-hover:scale-110 transition-transform" />
          <div className="text-sm font-medium text-gray-900">Followers</div>
          <div className="text-sm font-medium text-green-500 flex items-center justify-center">
            <span className="inline-block transform transition-transform group-hover:translate-y-[-2px]">↑ 13%</span>
          </div>
        </div>
        <div className="p-3 rounded-lg hover:bg-gray-50 transition-colors group">
          <ChartBar className="w-5 h-5 mx-auto mb-2 text-linkedin-primary group-hover:scale-110 transition-transform" />
          <div className="text-sm font-medium text-gray-900">Engagement</div>
          <div className="text-sm font-medium text-green-500 flex items-center justify-center">
            <span className="inline-block transform transition-transform group-hover:translate-y-[-2px]">↑ 12%</span>
          </div>
        </div>
        <div className="p-3 rounded-lg hover:bg-gray-50 transition-colors group">
          <Activity className="w-5 h-5 mx-auto mb-2 text-linkedin-primary group-hover:scale-110 transition-transform" />
          <div className="text-sm font-medium text-gray-900">Activity</div>
          <div className="text-sm font-medium text-green-500 flex items-center justify-center">
            <span className="inline-block transform transition-transform group-hover:translate-y-[-2px]">↑ 52%</span>
          </div>
        </div>
      </div>
    </div>
  );
};