import { Card } from "@/components/ui/card";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";
import { TrendingUp } from "lucide-react";

const data = [
  { name: "Technology", value: 35, growth: 15 },
  { name: "Finance", value: 25, growth: 8 },
  { name: "Marketing", value: 20, growth: 12 },
  { name: "Other", value: 20, growth: 5 },
];

const COLORS = ["#0A66C2", "#378FE9", "#70B5F9", "#BFD8F6"];

export const AudienceInsights = () => {
  return (
    <Card className="p-6 space-y-6">
      <div>
        <h3 className="text-xl font-semibold">Audience Insights</h3>
        <p className="text-sm text-gray-600">
          Understanding your audience demographics and growth
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const data = payload[0].payload;
                    return (
                      <div className="bg-white p-3 border rounded shadow-lg">
                        <p className="font-medium">{data.name}</p>
                        <p className="text-sm">
                          {data.value}% of audience
                        </p>
                        <p className="text-sm text-green-500">
                          +{data.growth}% growth
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          <h4 className="font-medium">Growth by Sector</h4>
          {data.map((sector, index) => (
            <div key={index} className="bg-gray-50 p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">{sector.name}</span>
                <div className="flex items-center gap-1 text-green-500">
                  <TrendingUp className="w-4 h-4" />
                  <span className="text-sm">+{sector.growth}%</span>
                </div>
              </div>
              <div className="mt-2 h-2 bg-gray-200 rounded-full">
                <div
                  className="h-full rounded-full"
                  style={{
                    width: `${sector.value}%`,
                    backgroundColor: COLORS[index],
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-linkedin-primary/5 p-4 rounded-lg">
        <h4 className="font-medium text-linkedin-primary mb-2">Key Insight</h4>
        <p className="text-sm">
          The Technology sector shows the strongest growth at +15%, suggesting your content resonates well with tech professionals.
        </p>
      </div>
    </Card>
  );
};