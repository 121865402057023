
import { useState, useEffect } from "react";
import { ProfileAnalysisSidebar } from "@/components/profile-analysis/ProfileAnalysisSidebar";
import { AllPostsTable } from "@/components/profile-analysis/AllPostsTable";
import { useToast } from "@/components/ui/use-toast";
import { useIsMobile } from "@/hooks/use-mobile";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useNavigate } from "react-router-dom";
import { getMockProfileData } from "@/utils/mockProfileData";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { ExportButton } from "@/components/profile-analysis/ExportButton";
import { ChevronLeft } from "lucide-react";

const AllPostsAnalysis = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { userPlan, canAccessIndividual, canAccessPro, isAuthenticated } = usePlanFeatures();
  const [profileData, setProfileData] = useState(getMockProfileData(canAccessPro ? 'pro' : 'individual'));
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('30d');
  
  // Add debugging logs to help identify the issue
  console.log('Auth status:', isAuthenticated);
  console.log('User plan:', userPlan);
  console.log('Can access individual:', canAccessIndividual);
  console.log('Can access pro:', canAccessPro);
  
  // Add some delay before redirection to ensure all hooks have been processed
  useEffect(() => {
    if (!isAuthenticated) {
      // If user is not authenticated, we should still allow them to see the page
      // with appropriate upgrade prompts rather than redirecting
      return;
    }
    
    // Only redirect if user is authenticated and doesn't have appropriate tier
    const timer = setTimeout(() => {
      if (isAuthenticated && !canAccessIndividual && !canAccessPro) {
        toast({
          title: "Premium Feature",
          description: "Upgrade to access all posts analysis.",
          variant: "destructive"
        });
        navigate('/analysis/free');
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, [canAccessIndividual, canAccessPro, navigate, toast, isAuthenticated]);
  
  const handleFilterChange = (filter: string) => {
    setCurrentFilter(filter);
    toast({
      title: "Filter Applied",
      description: `Showing posts from the ${
        filter === '7d' ? 'last 7 days' :
        filter === '30d' ? 'last 30 days' :
        filter === 'quarter' ? 'current quarter' : 'last 365 days'
      }`
    });
  };
  
  return (
    <div className="flex flex-1">
      {/* Desktop sidebar */}
      {!isMobile && <ProfileAnalysisSidebar tier={userPlan} />}
      
      {/* Mobile sidebar */}
      {isMobile && (
        <Sheet open={mobileSidebarOpen} onOpenChange={setMobileSidebarOpen}>
          <SheetContent side="left" className="p-0 w-[250px]">
            <ProfileAnalysisSidebar tier={userPlan} />
          </SheetContent>
        </Sheet>
      )}
      
      <main className="flex-1 p-4 md:p-6 overflow-auto">
        <div className="max-w-5xl mx-auto">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <Button 
                variant="ghost" 
                size="sm" 
                className="mr-2"
                onClick={() => navigate(-1)}
              >
                <ChevronLeft className="mr-1 h-4 w-4" />
                Back
              </Button>
              <h1 className="text-2xl font-bold">All Posts Analysis</h1>
            </div>
            <ExportButton tier={userPlan} />
          </div>
          
          {/* Add upgrade notice for free users */}
          {!canAccessIndividual && !canAccessPro && (
            <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 mb-6">
              <h3 className="text-lg font-medium text-amber-800">Premium Feature</h3>
              <p className="text-amber-700">
                This feature requires an Individual or Pro plan. 
                <Button 
                  variant="link" 
                  className="text-amber-800 font-medium p-0 h-auto ml-1"
                  onClick={() => navigate('/pricing')}
                >
                  Upgrade now
                </Button>
              </p>
            </div>
          )}
          
          <div className="bg-white rounded-lg p-4 mb-6">
            <div className="flex flex-wrap justify-center md:justify-start gap-2">
              <Button 
                variant={currentFilter === '7d' ? 'default' : 'outline'} 
                size="sm"
                onClick={() => handleFilterChange('7d')}
              >
                Last 7 Days
              </Button>
              <Button 
                variant={currentFilter === '30d' ? 'default' : 'outline'} 
                size="sm"
                onClick={() => handleFilterChange('30d')}
              >
                Last 30 Days
              </Button>
              <Button 
                variant={currentFilter === 'quarter' ? 'default' : 'outline'} 
                size="sm"
                onClick={() => handleFilterChange('quarter')}
              >
                This Quarter
              </Button>
              <Button 
                variant={currentFilter === '365d' ? 'default' : 'outline'} 
                size="sm"
                onClick={() => handleFilterChange('365d')}
              >
                Last 365 Days
              </Button>
            </div>
          </div>
          
          <AllPostsTable 
            tier={userPlan} 
            posts={profileData.topPosts} 
          />
        </div>
      </main>
    </div>
  );
};

export default AllPostsAnalysis;
