
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { ArrowRight, Star, Search, Info } from "lucide-react";
import { useUser } from "@clerk/clerk-react";
import { useToast } from "@/components/ui/use-toast";
import type { Subscription } from "@/hooks/useSubscription";
import { useState } from "react";

interface URLInputSectionProps {
  linkedinUrl: string;
  setLinkedinUrl: (url: string) => void;
  isProVersion: boolean;
  setIsProVersion: (isPro: boolean) => void;
  isReportComplete: boolean;
  handleNewAnalysis: () => void;
  handleStartAnalysis: () => void;
  subscription: Subscription | null;
}

export const URLInputSection = ({
  linkedinUrl,
  setLinkedinUrl,
  isProVersion,
  setIsProVersion,
  isReportComplete,
  handleNewAnalysis,
  handleStartAnalysis,
  subscription,
}: URLInputSectionProps) => {
  const { isSignedIn } = useUser();
  const { toast } = useToast();
  const [showHelp, setShowHelp] = useState(false);

  const handleProToggle = (checked: boolean) => {
    if (checked && (!isSignedIn || subscription?.plan === 'free')) {
      toast({
        title: "Pro Version Restricted",
        description: "Please upgrade your plan to access Pro features.",
        variant: "destructive",
      });
      return;
    }
    setIsProVersion(checked);
  };

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };

  return (
    <div className="relative bg-white rounded-xl shadow-md p-6 sm:p-8 space-y-4 animate-fade-up">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-linkedin-primary">
          {isReportComplete ? "Analyze Another Profile" : "Analyze & Optimize Your LinkedIn Activity"}
        </h2>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-600">Pro Version</span>
          <Switch
            checked={isProVersion}
            onCheckedChange={handleProToggle}
            disabled={!isSignedIn || subscription?.plan === 'free'}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-3 justify-center items-center max-w-2xl mx-auto">
        <div className="relative flex-1 w-full">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <Input 
            placeholder="Enter a LinkedIn profile URL (linkedin.com/in/username)"
            className="h-12 text-base pl-12 border-2 border-linkedin-light/20 focus:border-linkedin-primary transition-colors"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
          />
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 h-8 w-8 text-gray-500"
            onClick={toggleHelp}
          >
            <Info className="h-4 w-4" />
          </Button>
        </div>
        <Button
          className="h-12 px-8 bg-linkedin-primary hover:bg-linkedin-dark text-white transition-all duration-300 hover:scale-105 hover:shadow-lg group w-full md:w-auto flex items-center justify-center"
          onClick={isReportComplete ? handleNewAnalysis : handleStartAnalysis}
        >
          <span>{isReportComplete ? "New Analysis" : "Start Analysis"}</span>
          <ArrowRight className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1" />
        </Button>
      </div>
      
      {showHelp && (
        <div className="bg-white p-4 rounded-md border border-gray-200 shadow-sm mt-2 text-sm">
          <h3 className="font-medium mb-2">URL Format Requirements:</h3>
          <p className="mb-2 font-semibold">LinkedIn Profile URL Format:</p>
          <ul className="list-disc pl-5 mb-3">
            <li>Profile URLs: <code className="bg-gray-100 px-1 rounded">linkedin.com/in/username</code></li>
          </ul>
          <p className="text-xs text-gray-500">
            Both standard and pro versions analyze LinkedIn profiles. Enter the URL of the profile you want to analyze.
            {isProVersion && " In Pro mode, your profile URL will be used to find recent posts from that profile."}
          </p>
          <button 
            className="text-linkedin-primary hover:underline text-xs mt-2" 
            onClick={toggleHelp}
          >
            Close help
          </button>
        </div>
      )}
      
      <div className="text-sm text-gray-600 mt-4">
        Using TrackInfluence {subscription?.plan || "Free"} Version.
        {subscription?.plan === 'free' && (
          <Button
            variant="link"
            className="text-linkedin-primary hover:text-linkedin-dark font-semibold ml-1 inline-flex items-center group"
            onClick={() => handleProToggle(true)}
          >
            <span>Upgrade to Pro</span>
            <Star className="w-4 h-4 ml-1 transition-transform group-hover:scale-110" />
          </Button>
        )}
      </div>
    </div>
  );
};
