
import { useState, useEffect } from "react";
import { Sidebar } from "@/components/Sidebar";
import { UnifiedHeader } from "@/components/profile-analysis/UnifiedHeader";
import { ProfileGoalsSection } from "@/components/profile-analysis/ProfileGoalsSection";
import { ProfileAnalyticsSection } from "@/components/profile-analysis/ProfileAnalyticsSection";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useToast } from "@/components/ui/use-toast";
import { useIsMobile } from "@/hooks/use-mobile";
import { useNavigate } from "react-router-dom";

const mockProfile = {
  name: "John Doe",
  headline: "Senior Marketing Manager | Digital Strategy | Content Creation",
  avatarUrl: "https://api.dicebear.com/7.x/avataaars/svg?seed=John",
  score: 86,
  summary: "Your LinkedIn profile is well-optimized but there are some opportunities for improvement.",
  metrics: {
    followers: 3865,
    followerChange: 12.8,
    engagementRate: 4.7,
    engagementChange: 2.1,
    postFrequency: 2.3,
    postFrequencyChange: -0.8,
    avgEngagements: 68,
    avgEngagementsChange: 5.2,
    avgViews: 1250,
    avgViewsChange: 8.5,
    profileStrength: 92,
    profileStrengthChange: 5
  },
  followerTrend: Array.from({ length: 30 }, (_, i) => ({
    date: new Date(Date.now() - (29 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    followers: 3700 + Math.floor(Math.random() * 200)
  })),
  profileStrengths: ["Your profile photo and banner are well optimized", "Your headline is compelling and keyword-rich", "Your experience section is detailed with measurable achievements"],
  profileWeaknesses: ["Add more media to your featured section"],
  postTypes: [{
    type: "Text",
    performance: 12,
    trend: 5
  }, {
    type: "Image",
    performance: 28,
    trend: 10
  }, {
    type: "Video",
    performance: 8,
    trend: 3
  }],
  topHashtags: ["leadership", "marketing", "innovation"],
  mostUsedPostType: "Text",
  competitorMetrics: {
    postingFrequency: -20,
    engagementRate: 12,
    profileOptimization: 8,
    contentQuality: 15,
    networkGrowth: 22
  },
  goals: [{
    id: "goal1",
    name: "Follower Growth",
    current: 3865,
    target: 5000,
    unit: "followers"
  }, {
    id: "goal2",
    name: "Weekly Posts",
    current: 2,
    target: 3,
    unit: "posts"
  }, {
    id: "goal3",
    name: "Engagement Rate",
    current: 4.7,
    target: 6,
    unit: "%"
  }],
  recommendations: ["Increase your posting frequency to 3-4 times per week to boost engagement", "Add more visual content - images and videos typically perform better than text-only posts", "Engage more with comments on your posts to encourage further discussions"],
  topPosts: [{
    id: "post1",
    text: "Excited to share that our team has launched a new product feature that increases efficiency by 30%! #productivity #innovation",
    likes: 145,
    comments: 28,
    shares: 12,
    views: 3560,
    date: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).toISOString(),
    type: "image"
  }, {
    id: "post2",
    text: "Just published my thoughts on the future of digital marketing. Check out the article in the comments below!",
    likes: 98,
    comments: 42,
    shares: 15,
    views: 2780,
    date: new Date(Date.now() - 12 * 24 * 60 * 60 * 1000).toISOString(),
    type: "text"
  }, {
    id: "post3",
    text: "Had a great time speaking at the Digital Marketing Summit yesterday. Thanks to everyone who attended my session on content strategy!",
    likes: 215,
    comments: 32,
    shares: 25,
    views: 4120,
    date: new Date(Date.now() - 18 * 24 * 60 * 60 * 1000).toISOString(),
    type: "video"
  }]
};

export const ProfileAudit = () => {
  const { userPlan, canAccessIndividual, canAccessPro, isAuthenticated } = usePlanFeatures();
  const [currentTier, setCurrentTier] = useState<'free' | 'individual' | 'pro'>('free');
  const { toast } = useToast();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(mockProfile);
  const [goals, setGoals] = useState<typeof mockProfile.goals>([]);
  const isMobile = useIsMobile();
  
  useEffect(() => {
    if (canAccessPro) {
      setCurrentTier('pro');
      setGoals(mockProfile.goals);
    } else if (canAccessIndividual) {
      setCurrentTier('individual');
      setGoals(mockProfile.goals);
    } else {
      setCurrentTier('free');
      setGoals(mockProfile.goals.slice(0, 3));
    }
  }, [canAccessPro, canAccessIndividual]);
  
  const handleAddGoal = (newGoal: Omit<typeof mockProfile.goals[0], 'id'>) => {
    if (currentTier === 'free') {
      toast({
        title: "Feature Not Available",
        description: "Please upgrade to customize goals.",
        variant: "destructive"
      });
      return;
    }
    
    const goalWithId = {
      ...newGoal,
      id: `goal-${Date.now()}`
    };
    
    const maxGoals = currentTier === 'individual' ? 5 : 10;
    if (goals.length >= maxGoals) {
      toast({
        title: "Goal Limit Reached",
        description: `You can track up to ${maxGoals} goals on your current plan.`,
        variant: "destructive"
      });
      return;
    }
    
    setGoals([...goals, goalWithId]);
  };
  
  const handleViewAllPosts = () => {
    if (currentTier === 'free') {
      toast({
        title: "Feature Not Available",
        description: "Please upgrade to view all posts.",
        variant: "destructive"
      });
    } else {
      navigate('/posts');
    }
  };
  
  return (
    <div className="flex flex-1">
      {isAuthenticated && <Sidebar />}
      <main className={`flex-1 p-4 md:p-6 overflow-auto ${!isAuthenticated ? 'w-full mx-auto' : ''}`}>
        <div className="max-w-5xl mx-auto">
          <UnifiedHeader 
            profileName={profileData.name} 
            profileImageUrl={profileData.avatarUrl} 
            headline={profileData.headline} 
            tier={currentTier} 
            score={profileData.score} 
            metrics={{
              followers: profileData.metrics.followers,
              followerChange: profileData.metrics.followerChange,
              engagementRate: profileData.metrics.engagementRate,
              engagementChange: profileData.metrics.engagementChange,
              postFrequency: profileData.metrics.postFrequency,
              postFrequencyChange: profileData.metrics.postFrequencyChange,
              avgEngagements: profileData.metrics.avgEngagements,
              avgEngagementsChange: profileData.metrics.avgEngagementsChange,
              avgViews: profileData.metrics.avgViews,
              avgViewsChange: profileData.metrics.avgViewsChange
            }}
            taggedTopics={profileData.topHashtags}
          />
          
          <ProfileGoalsSection 
            tier={currentTier}
            goals={goals}
            onAddGoal={handleAddGoal}
          />
          
          <ProfileAnalyticsSection 
            tier={currentTier}
            profileData={profileData}
            onViewAllPosts={handleViewAllPosts}
          />
        </div>
      </main>
    </div>
  );
};

export default ProfileAudit;
