
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useUser } from "@clerk/clerk-react";

export const UserAnalysisRedirect = () => {
  const { userPlan, isAuthenticated } = usePlanFeatures();
  const { isLoaded } = useUser();
  const [hasProjects, setHasProjects] = useState(false);
  
  // Check if there are any projects
  useEffect(() => {
    try {
      const projectsData = localStorage.getItem('projects');
      if (!projectsData) {
        setHasProjects(false);
        return;
      }
      
      let projects;
      try {
        projects = JSON.parse(projectsData);
      } catch (parseError) {
        console.error("Error parsing projects data:", parseError);
        setHasProjects(false);
        return;
      }
      
      setHasProjects(Array.isArray(projects) && projects.length > 0);
      
      // Listen for storage updates
      const handleStorageUpdate = () => {
        try {
          const updatedProjectsData = localStorage.getItem('projects');
          if (!updatedProjectsData) {
            setHasProjects(false);
            return;
          }
          
          let updatedProjects;
          try {
            updatedProjects = JSON.parse(updatedProjectsData);
          } catch (parseError) {
            console.error("Error parsing updated projects data:", parseError);
            setHasProjects(false);
            return;
          }
          
          setHasProjects(Array.isArray(updatedProjects) && updatedProjects.length > 0);
        } catch (error) {
          console.error("Error handling storage update:", error);
          setHasProjects(false);
        }
      };
      
      window.addEventListener('storageUpdated', handleStorageUpdate);
      return () => {
        window.removeEventListener('storageUpdated', handleStorageUpdate);
      };
    } catch (error) {
      console.error("Error checking projects:", error);
      setHasProjects(false);
    }
  }, []);
  
  // Wait for auth to load before redirecting
  if (!isLoaded) {
    return null;
  }
  
  // If user is not authenticated, redirect to home page where GuestHomePage will be shown
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  
  // If Pro user has no projects, redirect to dashboard to set up a project
  if (!hasProjects && userPlan === 'pro') {
    return <Navigate to="/dashboard" replace />;
  }
  
  // If Free or Individual user has no projects, redirect to the appropriate analysis page
  // where the wizard will be shown
  if (!hasProjects) {
    switch (userPlan) {
      case 'individual':
        return <Navigate to="/analysis/individual" replace />;
      default:
        return <Navigate to="/analysis/free" replace />;
    }
  }
  
  // All users with projects are routed to appropriate analysis page based on plan
  switch (userPlan) {
    case 'pro':
      return <Navigate to="/analysis/pro" replace />;
    case 'individual':
      return <Navigate to="/analysis/individual" replace />;
    default:
      return <Navigate to="/analysis/free" replace />;
  }
};
