
import { Outlet } from "react-router-dom";
import { Header } from "@/components/Header";
import { useLocation } from "react-router-dom";
import { Sidebar } from "@/components/Sidebar";
import { useIsMobile } from "@/hooks/use-mobile";

const RootLayout = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  // Update the condition to ensure settings page shows the sidebar
  const showSidebar = !["/", "/pricing", "/login", "/sign-up"].includes(location.pathname) && !isMobile;
  
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1">
        {showSidebar && <Sidebar />}
        <main className={`${showSidebar ? "flex-1" : "w-full"}`}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default RootLayout;
