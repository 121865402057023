import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { FileText, BarChart, Eye, TrendingUp, Users } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { ExportButton } from "./ExportButton";
import { useIsMobile } from "@/hooks/use-mobile";
import { Separator } from "@/components/ui/separator";

interface UnifiedHeaderProps {
  profileName: string;
  profileImageUrl: string;
  headline?: string;
  tier: 'free' | 'individual' | 'pro';
  score: number;
  metrics: {
    followers: number;
    followerChange: number;
    engagementRate: number;
    engagementChange: number;
    postFrequency: number;
    postFrequencyChange: number;
    avgEngagements: number;
    avgEngagementsChange: number;
    avgViews: number;
    avgViewsChange: number;
  };
  taggedTopics?: string[];
}

export const UnifiedHeader: FC<UnifiedHeaderProps> = ({
  profileName,
  profileImageUrl,
  headline = "LinkedIn Professional",
  tier,
  score,
  metrics,
  taggedTopics = ["Leadership", "Marketing", "Innovation"]
}) => {
  const navigate = useNavigate();
  const {
    toast
  } = useToast();
  const isMobile = useIsMobile();
  const [isExporting, setIsExporting] = useState(false);

  const getScoreColor = () => {
    if (score >= 80) return 'text-green-500';
    if (score >= 60) return 'text-yellow-500';
    return 'text-red-500';
  };
  const getScoreBgColor = () => {
    if (score >= 80) return 'bg-green-100';
    if (score >= 60) return 'bg-yellow-100';
    return 'bg-red-100';
  };

  const scoreItems = [{
    label: "Content Quality",
    value: 91,
    color: "bg-green-500"
  }, {
    label: "Engagement",
    value: 76,
    color: "bg-yellow-500"
  }, {
    label: "Network Growth",
    value: 100,
    color: "bg-green-500"
  }, {
    label: "Profile Optimization",
    value: 81,
    color: "bg-green-500"
  }];

  return <div className="bg-gradient-to-br from-white via-gray-50 to-blue-50 rounded-xl p-6 shadow-lg border border-blue-100 mb-8 animate-fade-up">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col md:flex-row items-start gap-6">
          <div className="flex gap-4 items-center w-full">
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-linkedin-primary to-linkedin-light rounded-full blur-sm opacity-20 group-hover:opacity-30 transition-opacity"></div>
              <Avatar className="w-16 h-16 border-2 border-white shadow-xl transition-transform duration-300 group-hover:scale-105">
                <AvatarImage src={profileImageUrl} alt={profileName} className="object-cover" />
                <AvatarFallback className="text-xl font-bold bg-linkedin-primary text-white">{profileName.charAt(0)}</AvatarFallback>
              </Avatar>
            </div>
            
            <div className="flex-1">
              <div>
                <h1 className="text-xl font-bold text-gray-900">{profileName}</h1>
                <p className="text-gray-600 mt-1 text-sm">{headline}</p>
                
                {!isMobile && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {taggedTopics.map((topic, index) => (
                      <div key={index} className="flex items-center gap-1 bg-blue-100 text-blue-700 text-xs px-2 py-0.5 rounded-full border border-blue-200">
                        <span className="font-medium">{topic}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto">
            <div className="flex flex-col items-center justify-center w-full md:w-auto">
              {isMobile ? (
                <div className="w-full">
                  <div className="flex flex-wrap gap-1 mb-3">
                    {taggedTopics.slice(0, 2).map((topic, index) => (
                      <div key={index} className="flex items-center bg-blue-100 text-blue-700 text-xs px-2 py-0.5 rounded-full border border-blue-200 mb-1">
                        <span className="font-medium truncate">{topic}</span>
                      </div>
                    ))}
                  </div>
                  
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-2 w-full">
                      <div className={`h-14 w-full px-4 rounded-md ${getScoreBgColor()} border border-gray-200 flex items-center justify-center shadow-md`}>
                        <div className="flex flex-col items-center">
                          <span className={`text-2xl font-bold ${getScoreColor()}`}>{score}</span>
                          <span className="text-xs text-gray-600 mt-0.5">Influence Score</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1.5 w-full">
                    {scoreItems.map((item, index) => <div key={index} className="flex items-center gap-2">
                        <span className="w-28 text-xs text-gray-600">{item.label}</span>
                        <div className="flex-1">
                          <Progress value={item.value} className={`h-1.5 ${item.color}`} />
                        </div>
                        <span className="text-xs font-medium text-gray-700 w-6 text-right">{item.value}%</span>
                      </div>)}
                  </div>
                </div>
              ) : (
                <>
                  <div className="relative mb-4">
                    <div className={`w-32 h-24 rounded-md ${getScoreBgColor()} border border-gray-200 flex flex-col items-center justify-center mx-auto shadow-md relative`}>
                      <div className="absolute inset-0 rounded-md bg-gradient-to-br from-linkedin-primary/5 to-transparent"></div>
                      <span className={`text-5xl font-bold ${getScoreColor()}`}>{score}</span>
                      <span className="text-xs text-gray-600 mt-1">Influence Score</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        
        {!isMobile && <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="md:col-span-8 grid grid-cols-2 md:grid-cols-4 gap-3">
              <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
                <div className="flex justify-between items-start mb-2">
                  <Users className="h-5 w-5 text-linkedin-primary" />
                  <div className="text-green-500 text-xs flex items-center font-medium">
                    <TrendingUp className="h-3 w-3 mr-0.5" />
                    <span>{metrics.followerChange.toFixed(1)}%</span>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-lg font-bold">{metrics.followers.toLocaleString()}</p>
                  <p className="text-gray-500 text-xs mt-0.5">Followers</p>
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
                <div className="flex justify-between items-start mb-2">
                  <BarChart className="h-5 w-5 text-linkedin-primary" />
                  <div className="text-green-500 text-xs flex items-center font-medium">
                    <TrendingUp className="h-3 w-3 mr-0.5" />
                    <span>{metrics.engagementChange.toFixed(1)}%</span>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-lg font-bold">{metrics.engagementRate.toFixed(1)}%</p>
                  <p className="text-gray-500 text-xs mt-0.5">Engagement Rate</p>
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
                <div className="flex justify-between items-start mb-2">
                  <BarChart className="h-5 w-5 text-linkedin-primary" />
                  <div className="text-green-500 text-xs flex items-center font-medium">
                    <TrendingUp className="h-3 w-3 mr-0.5" />
                    <span>{metrics.avgEngagementsChange.toFixed(1)}%</span>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-lg font-bold">{metrics.avgEngagements}</p>
                  <p className="text-gray-500 text-xs mt-0.5">Avg. Engagements</p>
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
                <div className="flex justify-between items-start mb-2">
                  <Eye className="h-5 w-5 text-linkedin-primary" />
                  <div className="text-green-500 text-xs flex items-center font-medium">
                    <TrendingUp className="h-3 w-3 mr-0.5" />
                    <span>{metrics.avgViewsChange.toFixed(1)}%</span>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-lg font-bold">{metrics.avgViews.toLocaleString()}</p>
                  <p className="text-gray-500 text-xs mt-0.5">Avg. Views</p>
                </div>
              </div>
            </div>
            
            <div className="hidden md:flex justify-center">
              <Separator orientation="vertical" className="h-full bg-gray-200" />
            </div>
            
            <div className="md:col-span-3">
              <div className="space-y-2">
                {scoreItems.map((item, index) => <div key={index} className="flex items-center gap-2">
                    <span className="w-28 text-xs text-gray-600">{item.label}</span>
                    <div className="flex-1">
                      <Progress value={item.value} className={`h-1.5 ${item.color}`} />
                    </div>
                    <span className="text-xs font-medium text-gray-700 w-8 text-right">{item.value}%</span>
                  </div>)}
              </div>
            </div>
          </div>}
        
        {isMobile && <div className="grid grid-cols-2 gap-3 w-full">
            <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100">
              <div className="flex justify-between items-start mb-1">
                <Users className="h-4 w-4 text-linkedin-primary" />
                <div className="text-green-500 text-xs flex items-center font-medium">
                  <TrendingUp className="h-3 w-3 mr-0.5" />
                  <span>{metrics.followerChange.toFixed(1)}%</span>
                </div>
              </div>
              <div className="mt-1">
                <p className="text-base font-bold">{metrics.followers.toLocaleString()}</p>
                <p className="text-gray-500 text-xs">Followers</p>
              </div>
            </div>
            
            <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100">
              <div className="flex justify-between items-start mb-1">
                <BarChart className="h-4 w-4 text-linkedin-primary" />
                <div className="text-green-500 text-xs flex items-center font-medium">
                  <TrendingUp className="h-3 w-3 mr-0.5" />
                  <span>{metrics.engagementChange.toFixed(1)}%</span>
                </div>
              </div>
              <div className="mt-1">
                <p className="text-base font-bold">{metrics.engagementRate.toFixed(1)}%</p>
                <p className="text-gray-500 text-xs">Engagement Rate</p>
              </div>
            </div>
            
            <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100">
              <div className="flex justify-between items-start mb-1">
                <BarChart className="h-4 w-4 text-linkedin-primary" />
                <div className="text-green-500 text-xs flex items-center font-medium">
                  <TrendingUp className="h-3 w-3 mr-0.5" />
                  <span>{metrics.avgEngagementsChange.toFixed(1)}%</span>
                </div>
              </div>
              <div className="mt-1">
                <p className="text-base font-bold">{metrics.avgEngagements}</p>
                <p className="text-gray-500 text-xs">Avg. Engagements</p>
              </div>
            </div>
            
            <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100">
              <div className="flex justify-between items-start mb-1">
                <Eye className="h-4 w-4 text-linkedin-primary" />
                <div className="text-green-500 text-xs flex items-center font-medium">
                  <TrendingUp className="h-3 w-3 mr-0.5" />
                  <span>{metrics.avgViewsChange.toFixed(1)}%</span>
                </div>
              </div>
              <div className="mt-1">
                <p className="text-base font-bold">{metrics.avgViews.toLocaleString()}</p>
                <p className="text-gray-500 text-xs">Avg. Views</p>
              </div>
            </div>
          </div>}

        <div className="text-sm text-gray-500 italic text-center mt-2">
          <span>Data from the last 30 days</span>
        </div>
      </div>
    </div>;
};
