import { Card } from "@/components/ui/card";
import { PostsList } from "./posts/PostsList";
import { ContentSuggestions } from "./posts/ContentSuggestions";

const rankedPosts = [
  {
    rank: 1,
    type: "Video",
    title: "How to Increase Your LinkedIn Engagement",
    views: 12500,
    likes: 450,
    comments: 85,
    shares: 120,
    engagement: 8.5,
    date: "2024-03-01",
    tooltip: "High engagement rate due to visual content and actionable tips"
  },
  {
    rank: 2,
    type: "Image",
    title: "Team Building Event Highlights",
    views: 8900,
    likes: 380,
    comments: 65,
    shares: 95,
    engagement: 7.2,
    date: "2024-02-28",
    tooltip: "Visual content showing company culture performs well"
  },
  {
    rank: 3,
    type: "Article",
    title: "Industry Trends 2024",
    views: 7500,
    likes: 320,
    comments: 55,
    shares: 80,
    engagement: 6.8,
    date: "2024-02-25",
    tooltip: "Thought leadership content drives meaningful discussions"
  },
  {
    rank: 4,
    type: "Poll",
    title: "What's Your Biggest Challenge?",
    views: 6800,
    likes: 290,
    comments: 120,
    shares: 45,
    engagement: 6.5,
    date: "2024-02-22",
    tooltip: "Interactive content encourages audience participation"
  },
  {
    rank: 5,
    type: "Text",
    title: "Breaking News: Industry Update",
    views: 6200,
    likes: 275,
    comments: 48,
    shares: 62,
    engagement: 6.2,
    date: "2024-02-20",
    tooltip: "Timely updates generate immediate engagement"
  }
];

export const PostPerformance = () => {
  return (
    <div className="space-y-6">
      <Card className="p-6 space-y-6">
        <div>
          <h3 className="text-xl font-semibold">Post Performance</h3>
          <p className="text-sm text-gray-600">Analysis of your best and worst performing content</p>
        </div>
        <PostsList posts={rankedPosts} />
      </Card>
      <ContentSuggestions />
    </div>
  );
};
