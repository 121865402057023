
import { useState, useEffect } from 'react';
import { Progress } from "@/components/ui/progress";
import { Star, CheckCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from 'react-router-dom';

const statusMessages = [
  "Analyzing profile structure...",
  "Scanning engagement metrics...",
  "Evaluating content performance...",
  "Checking audience insights...",
  "Generating recommendations...",
];

export const ReportCreation = ({ linkedinUrl }: { linkedinUrl: string }) => {
  const [progress, setProgress] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prev + 1;
      });
    }, 100);

    const statusInterval = setInterval(() => {
      setCurrentStatus(prev => (prev + 1) % statusMessages.length);
    }, 3000);

    return () => {
      clearInterval(progressInterval);
      clearInterval(statusInterval);
    };
  }, []);

  // When progress reaches 100%, set complete state and redirect
  useEffect(() => {
    if (progress === 100) {
      setIsComplete(true);
      
      // In a real app, you'd redirect to the actual report
      const redirectTimer = setTimeout(() => {
        navigate('/audit'); // Replace with your actual report route
      }, 1500);
      
      return () => clearTimeout(redirectTimer);
    }
  }, [progress, navigate]);

  return (
    <div className="max-w-2xl mx-auto space-y-6">
      <div className="text-sm text-gray-600">
        Using Trackinfluence Free Version With 1 Profile.
        <Button
          variant="link"
          className="text-linkedin-primary hover:text-linkedin-dark font-semibold ml-1 inline-flex items-center group"
        >
          <span>Upgrade to Pro</span>
          <Star className="w-4 h-4 ml-1 transition-transform group-hover:scale-110" />
        </Button>
      </div>

      <div className="space-y-3">
        <Progress 
          value={progress} 
          className={`h-3 ${isComplete ? 'bg-green-100' : 'bg-blue-100'}`}
        />
        <div className="flex justify-between items-center text-sm">
          <span className={`font-medium ${isComplete ? 'text-green-600 flex items-center gap-1' : 'text-linkedin-primary'}`}>
            {isComplete ? (
              <>
                <CheckCircle className="w-4 h-4" />
                <span>Report complete! Redirecting to your analysis...</span>
              </>
            ) : (
              statusMessages[currentStatus]
            )}
          </span>
          <span className={`font-semibold ${isComplete ? 'text-green-600' : 'text-linkedin-primary'}`}>
            {progress}%
          </span>
        </div>
      </div>
    </div>
  );
};
