
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useUser } from "@clerk/clerk-react";

export interface Subscription {
  id: string;
  plan: string;
  status: string;
  max_profiles: number;
  max_reports_per_profile: number;
  can_export: boolean;
  can_export_csv: boolean;
  trial_end?: string;
  lemonsqueezy_subscription_id?: string;
}

const getPlanFromVariantId = (subscriptionId: string | null): string => {
  if (!subscriptionId) {
    console.log('No subscription ID found, defaulting to free plan');
    return 'free';
  }
  
  // Ensure subscriptionId is a string and has a value before splitting
  if (typeof subscriptionId !== 'string' || !subscriptionId.trim()) {
    console.log('Invalid subscription ID format, defaulting to free plan');
    return 'free';
  }
  
  // Only split if there's a hyphen
  const variantId = subscriptionId.includes('-') ? subscriptionId.split('-')[0] : subscriptionId;
  console.log('Extracted variant ID:', variantId);
  
  switch (variantId) {
    case '688138':
    case '688139':
      console.log('Identified as Individual plan');
      return 'individual';
    case '688140':
    case '688141':
      console.log('Identified as Pro plan');
      return 'pro';
    case '688142':
    case '688143':
      console.log('Identified as Team plan');
      return 'team';
    default:
      console.log('Unknown variant ID, defaulting to free plan');
      return 'free';
  }
};

// Helper function to create a default free subscription
function createDefaultFreeSubscription(): Subscription {
  return {
    id: 'default-free',
    plan: 'free',
    status: 'active',
    max_profiles: 1,
    max_reports_per_profile: 1,
    can_export: false,
    can_export_csv: false,
  };
}

export const useSubscription = () => {
  const isDevelopment = process.env.NODE_ENV === 'development' || 
    (typeof window !== 'undefined' && (
      window.location.hostname.includes('lovable.dev') || 
      window.location.hostname.includes('preview--trackfluence-home.lovable.app')
    ));

  // Return mock data for development
  if (isDevelopment) {
    return {
      data: {
        id: 'dev-subscription',
        plan: 'pro',
        status: 'active',
        max_profiles: 100,
        max_reports_per_profile: 100,
        can_export: true,
        can_export_csv: true,
      } as Subscription,
      isLoading: false,
      error: null,
    };
  }

  const { user, isSignedIn } = useUser();

  // If user is not signed in, immediately return free subscription
  if (!isSignedIn) {
    console.log('User not signed in, returning default free subscription');
    return {
      data: createDefaultFreeSubscription(),
      isLoading: false,
      error: null,
    };
  }

  const result = useQuery({
    queryKey: ['subscription', user?.id],
    queryFn: async (): Promise<Subscription> => {
      if (!user?.id) {
        console.log('No user ID found, returning default free subscription');
        return createDefaultFreeSubscription();
      }

      console.log('Fetching subscription for user:', user.id);
      
      try {
        const { data, error } = await supabase
          .from('subscriptions')
          .select('*')
          .eq('clerk_user_id', user.id)
          .limit(1)
          .maybeSingle();

        if (error) {
          console.error('Error fetching subscription:', error);
          console.log('Returning default free subscription due to error');
          return createDefaultFreeSubscription();
        }

        console.log('Raw subscription data:', data);

        if (data) {
          // Determine plan based on variant ID
          const planType = getPlanFromVariantId(data.lemonsqueezy_subscription_id);
          return {
            ...data,
            plan: planType,
          };
        } else {
          console.log('No subscription found for user, returning default free subscription');
          return createDefaultFreeSubscription();
        }
      } catch (err) {
        console.error('Unexpected error fetching subscription:', err);
        return createDefaultFreeSubscription();
      }
    },
    enabled: !!user?.id && isSignedIn, // Only enable when user ID is available and signed in
    retry: 1,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });
  
  // Ensure we always return a valid data object, even if the query hasn't completed yet
  return {
    ...result,
    data: result.data || createDefaultFreeSubscription(),
  };
};
