
import { supabase } from "@/integrations/supabase/client";

// Regular expression for valid LinkedIn profile URLs
const linkedinProfileUrlRegex = /.*linkedin\.com\/in\/[a-zA-Z0-9_-]+.*$/;

interface LinkedInReportOptions {
  url: string;
  isPro?: boolean;
  isIndividual?: boolean;
  useTestEndpoint?: boolean; // Flag to use the test endpoint instead
}

/**
 * Fetches LinkedIn report from the Supabase Edge Function
 */
export const fetchLinkedInReport = async ({ 
  url, 
  isPro = false, 
  isIndividual = false,
  useTestEndpoint = false 
}: LinkedInReportOptions) => {
  const requestId = crypto.randomUUID();
  const requestStartTime = performance.now();
  
  try {
    // Get stored BrightData API key
    const apiKey = localStorage.getItem("brightdata_api_key")?.trim();
    const fallbackApiKey = localStorage.getItem("brightdata_profiles_api_key")?.trim(); // Fallback for legacy storage
    const effectiveApiKey = apiKey || fallbackApiKey;

    console.group(`[${requestId}] LinkedIn Report API Request`);
    console.log("Request parameters:", { 
      url, 
      isPro, 
      isIndividual, 
      useTestEndpoint,
      userPlan: isPro ? "Pro" : isIndividual ? "Individual" : "Free",
      timestamp: new Date().toISOString()
    });
    
    // Log API key status (not the actual key)
    if (effectiveApiKey) {
      console.log(`API key available (length: ${effectiveApiKey.length}, first/last chars: ${effectiveApiKey.substring(0, 2)}...${effectiveApiKey.substring(effectiveApiKey.length - 2)})`);
    } else {
      console.warn("No API key found in localStorage");
    }

    // Validate URL
    if (!url) {
      const error = new Error("LinkedIn URL is required");
      console.error("Validation error:", error.message);
      console.groupEnd();
      throw error;
    }
    
    // Validate URL format
    if (!linkedinProfileUrlRegex.test(url)) {
      const error = new Error("A valid LinkedIn profile URL is required in the format: linkedin.com/in/username");
      console.error("URL format validation error:", {
        providedUrl: url,
        regexPattern: linkedinProfileUrlRegex.toString()
      });
      console.groupEnd();
      throw error;
    }

    // Create request payload
    const requestPayload = { 
      url, 
      isPro, 
      isIndividual,
      apiKey: effectiveApiKey, // Pass the API key from localStorage to the edge function
    };

    // Determine which endpoint to use
    const functionName = useTestEndpoint ? "linkedin-echo-test" : "generate-linkedin-report";
    console.log(`Using edge function: ${functionName} with tier: ${isPro ? "Pro" : isIndividual ? "Individual" : "Free"}`);
    
    // Log the request before sending
    console.log("Sending request to edge function", {
      functionName,
      payload: {
        ...requestPayload,
        apiKey: effectiveApiKey ? "******" : null
      }
    });
    
    // Call the edge function
    const functionStartTime = performance.now();
    const { data, error } = await supabase.functions.invoke(functionName, {
      body: requestPayload,
    });
    const functionDuration = performance.now() - functionStartTime;

    if (error) {
      console.error(`Error invoking ${functionName} function:`, {
        message: error.message,
        name: error.name,
        code: error.code,
        statusCode: error.status,
        details: error.details,
        hint: error.hint,
        duration: functionDuration.toFixed(2) + "ms"
      });
      
      // Parse the error message for better user feedback
      let errorMessage = error.message || `Failed to call LinkedIn API`;
      let detailedError = "";
      
      // Try to extract more detailed error from the response
      if (error.message.includes("non-2xx")) {
        try {
          const match = error.message.match(/returned\s+(\d+)/i);
          const statusCode = match ? match[1] : null;
          
          if (statusCode === "429") {
            errorMessage = "Rate limit exceeded. Please try again later.";
          } else if (statusCode === "401" || statusCode === "403") {
            errorMessage = "Authentication failed. Please check your BrightData API key.";
          } else if (statusCode === "504") {
            errorMessage = "Request timed out. BrightData service may be experiencing delays.";
          }
          
          detailedError = `Status code: ${statusCode}`;
        } catch (parseError) {
          // Unable to parse the error, use the original message
          detailedError = "Could not parse detailed error information";
          console.error("Error parsing error response:", parseError);
        }
      }
      
      console.error("Error details:", {
        message: errorMessage,
        details: detailedError,
        originalError: error.message
      });
      
      console.groupEnd();
      throw new Error(errorMessage);
    }

    if (!data) {
      console.error("No data returned from the LinkedIn API");
      console.groupEnd();
      throw new Error("No data returned from the LinkedIn API");
    }

    // Log the response data (summary only)
    const totalDuration = performance.now() - requestStartTime;
    console.log("LinkedIn API response received:", {
      success: true,
      dataKeys: data ? Object.keys(data) : [],
      functionUsed: functionName,
      tierInfo: {
        isPro,
        isIndividual,
        tier: isPro ? "Pro" : isIndividual ? "Individual" : "Free"
      },
      timing: {
        totalDuration: totalDuration.toFixed(2) + "ms",
        functionCallDuration: functionDuration.toFixed(2) + "ms"
      }
    });
    
    if (data.profile) {
      console.log("Profile data summary:", {
        name: data.profile.name,
        followers: data.profile.followers,
        postsCount: data.postsData?.length || 0
      });
    }
    
    console.groupEnd();
    return data;

  } catch (error) {
    console.error(`[${requestId}] Error in fetchLinkedInReport:`, error);
    console.groupEnd();
    throw error;
  }
};

/**
 * Tests the BrightData connection using the echo endpoint
 */
export const testBrightDataConnection = async (url: string, isPro: boolean = false, isIndividual: boolean = false) => {
  const requestId = crypto.randomUUID();
  
  try {
    console.group(`[${requestId}] Testing BrightData connection with echo endpoint...`);
    console.log("Test parameters:", {
      url,
      isPro,
      isIndividual,
      timestamp: new Date().toISOString()
    });
    
    const result = await fetchLinkedInReport({ 
      url, 
      isPro,
      isIndividual,
      useTestEndpoint: true 
    });
    
    console.log("Test endpoint response:", {
      hasApiKey: result.request?.hasApiKey,
      isValidUrl: result.validation?.isValidUrl,
      tier: result.planInfo?.tier
    });
    
    console.groupEnd();
    return result;
  } catch (error) {
    console.error(`[${requestId}] BrightData test connection failed:`, error);
    console.groupEnd();
    throw error;
  }
};
