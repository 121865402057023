
import { FC } from "react";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { GoalTracking } from "./GoalTracking";

interface ProfileGoalsSectionProps {
  tier: 'free' | 'individual' | 'pro';
  goals: Array<{
    id: string;
    name: string;
    current: number;
    target: number;
    unit: string;
  }>;
  onAddGoal: (newGoal: Omit<{
    id: string;
    name: string;
    current: number;
    target: number;
    unit: string;
  }, 'id'>) => void;
  showAddButton?: boolean;
}

export const ProfileGoalsSection: FC<ProfileGoalsSectionProps> = ({
  tier,
  goals,
  onAddGoal,
  showAddButton = true
}) => {
  const historyLabel = tier === 'free' ? 'Last 30 Days' : tier === 'individual' ? 'Last 90 Days' : 'Full History';
  
  return (
    <>
      <div className="flex flex-row items-center justify-between mt-10 mb-4">
        <div>
          <h2 className="text-xl font-bold text-gray-800 border-l-4 border-linkedin-primary pl-3">Goal Tracking</h2>
          <div className="text-sm text-gray-500 italic mt-1">Based on {historyLabel}</div>
        </div>
        {showAddButton && (tier === 'individual' || tier === 'pro') && (
          <Button 
            onClick={() => onAddGoal({
              name: "New Goal",
              current: 0,
              target: 100,
              unit: "actions"
            })} 
            size="sm" 
            className="bg-linkedin-primary hover:bg-linkedin-dark"
          >
            <Plus className="h-4 w-4 mr-2" />
            <span className="text-sm">Add Goal</span>
          </Button>
        )}
      </div>
      
      <GoalTracking tier={tier} goals={goals} onAddGoal={onAddGoal} />
    </>
  );
};
