import { useState } from "react";
import { ChevronDown, Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useToast } from "@/components/ui/use-toast";

type Profile = {
  id: string;
  name: string;
  title: string;
  avatarUrl: string;
};

const defaultProfiles: Profile[] = [
  {
    id: "1",
    name: "Felix Davidson",
    title: "Senior Product Designer",
    avatarUrl: "https://api.dicebear.com/7.x/avataaars/svg?seed=Felix",
  },
  {
    id: "2",
    name: "Sarah Johnson",
    title: "Marketing Manager",
    avatarUrl: "https://api.dicebear.com/7.x/avataaars/svg?seed=Sarah",
  },
  {
    id: "3",
    name: "Michael Chen",
    title: "Software Engineer",
    avatarUrl: "https://api.dicebear.com/7.x/avataaars/svg?seed=Michael",
  },
];

interface ProfileSelectorProps {
  onProfileChange?: (profile: Profile) => void;
}

export const ProfileSelector = ({ onProfileChange }: ProfileSelectorProps) => {
  const [profiles] = useState<Profile[]>(defaultProfiles);
  const [selectedProfile, setSelectedProfile] = useState<Profile>(profiles[0]);
  const { toast } = useToast();

  const handleProfileSelect = (profile: Profile) => {
    setSelectedProfile(profile);
    onProfileChange?.(profile);
    toast({
      title: "Profile Changed",
      description: `Switched to ${profile.name}'s profile`,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          className="w-[300px] justify-between hover:bg-accent transition-colors group"
        >
          <div className="flex items-center gap-2">
            <Avatar className="h-6 w-6">
              <AvatarImage src={selectedProfile.avatarUrl} />
              <AvatarFallback>{selectedProfile.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col items-start">
              <span className="font-medium">{selectedProfile.name}</span>
              <span className="text-xs text-muted-foreground">{selectedProfile.title}</span>
            </div>
          </div>
          <ChevronDown className="h-4 w-4 opacity-50 group-hover:opacity-100 transition-opacity ml-2 animate-bounce" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[300px]" align="start">
        {profiles.map((profile) => (
          <DropdownMenuItem
            key={profile.id}
            className={cn(
              "flex items-center gap-2 cursor-pointer",
              selectedProfile.id === profile.id && "bg-accent"
            )}
            onClick={() => handleProfileSelect(profile)}
          >
            <Avatar className="h-6 w-6">
              <AvatarImage src={profile.avatarUrl} />
              <AvatarFallback>{profile.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col">
              <span className="font-medium">{profile.name}</span>
              <span className="text-xs text-muted-foreground">{profile.title}</span>
            </div>
            {selectedProfile.id === profile.id && (
              <Check className="h-4 w-4 ml-auto" />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};