
import { useState, useEffect } from "react";
import { UnifiedHeader } from "@/components/profile-analysis/UnifiedHeader";
import { ProfileGoalsSection } from "@/components/profile-analysis/ProfileGoalsSection";
import { ProfileAnalyticsSection } from "@/components/profile-analysis/ProfileAnalyticsSection";
import { ExportOptions } from "@/components/profile-analysis/ExportOptions";
import { UpgradePopup } from "@/components/profile-analysis/UpgradePopup";
import { AIInsights } from "@/components/profile-analysis/AIInsights";
import { CompetitorBenchmark } from "@/components/profile-analysis/CompetitorBenchmark";
import { AnalyzeUrlField } from "@/components/profile-analysis/AnalyzeUrlField";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { getMockProfileData } from "@/utils/mockProfileData";
import { LinkedInProfileWizard } from "@/components/onboarding/LinkedInProfileWizard";
import { useUser } from "@clerk/clerk-react";

const IndividualProfileAnalysis = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(getMockProfileData('individual'));
  const [goals, setGoals] = useState<typeof profileData.goals>([]);
  const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
  const [competitors, setCompetitors] = useState<any[]>([]);
  const [upgradePopupContent, setUpgradePopupContent] = useState({
    title: "",
    description: "",
    features: [""],
    targetPlan: 'pro' as 'individual' | 'pro'
  });
  const [hasProjects, setHasProjects] = useState(false);
  const { isLoaded, isSignedIn } = useUser();
  
  useEffect(() => {
    // Check if there are any projects
    const projects = JSON.parse(localStorage.getItem('projects') || '[]');
    setHasProjects(projects.length > 0);
  }, []);

  // Listen for storage updates to refresh projects count
  useEffect(() => {
    const handleStorageUpdate = () => {
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      setHasProjects(projects.length > 0);
    };
    
    window.addEventListener('storageUpdated', handleStorageUpdate);
    return () => {
      window.removeEventListener('storageUpdated', handleStorageUpdate);
    };
  }, []);

  // Initialize with individual tier limitations
  useEffect(() => {
    setGoals(profileData.goals); // Individual allows all goals
  }, [profileData.goals]);
  
  const handleAddGoal = () => {
    // Individual tier can add goals, so this would show a dialog to add a goal
    toast({
      title: "Feature coming soon",
      description: "Adding custom goals will be available soon!",
    });
  };
  
  const showUpgradePopup = (title: string, description: string, features: string[], targetPlan: 'individual' | 'pro') => {
    setUpgradePopupContent({
      title,
      description,
      features,
      targetPlan
    });
    setIsUpgradePopupOpen(true);
  };
  
  const handleDateRangeChange = () => {
    // Individual tier has 90 days of history, this would change the date range
    toast({
      title: "Date range changed",
      description: "Your data now shows the selected time period.",
    });
  };
  
  const handleViewAllPosts = () => {
    navigate('/all-posts');
  };
  
  const handleUpgradeClick = () => {
    navigate('/pricing');
  };

  const handleAnalysisComplete = (action: 'upgrade' | 'replace' | 'discard', newProfile: any) => {
    if (action === 'upgrade') {
      // Navigate to pricing page
      navigate('/pricing');
    } else if (action === 'replace') {
      // Replace the current profile with the new one
      toast({
        title: "Profile Updated",
        description: `Now tracking ${newProfile.name || 'new profile'}.`,
      });
      
      // Update the projects in localStorage
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      if (projects.length > 0 && projects[0].profiles.length > 0) {
        // Update the first profile in the first project
        projects[0].profiles[0].url = newProfile.url || 'https://linkedin.com/in/new-profile';
        projects[0].profiles[0].name = newProfile.name || 'New Profile';
        localStorage.setItem('projects', JSON.stringify(projects));
        
        // Trigger storage update event
        window.dispatchEvent(new Event('storageUpdated'));
      }
      
      // For demo purposes, update the displayed profile data
      setProfileData(newProfile);
    } else {
      // Discard - just show a toast
      toast({
        title: "Analysis Discarded",
        description: "The new profile analysis has been discarded.",
      });
    }
  };

  // If not loaded yet, show nothing
  if (!isLoaded) {
    return null;
  }
  
  // If user is signed in and has no projects, show the wizard
  if (isSignedIn && !hasProjects) {
    return (
      <div className="flex-1 p-4 md:p-6 overflow-auto flex items-center justify-center">
        <LinkedInProfileWizard />
      </div>
    );
  }
  
  return (
    <main className="flex-1 p-4 md:p-6 overflow-auto">
      <div className="max-w-5xl mx-auto">
        {/* Add the AnalyzeUrlField component at the top */}
        {isSignedIn && hasProjects && (
          <AnalyzeUrlField onAnalyzeComplete={handleAnalysisComplete} />
        )}
        
        <UnifiedHeader 
          profileName={profileData.name} 
          profileImageUrl={profileData.avatarUrl} 
          headline={profileData.headline} 
          tier="individual" 
          score={profileData.score} 
          metrics={profileData.metrics} 
          taggedTopics={profileData.topHashtags} 
        />
        
        <AIInsights 
          tier="individual" 
          profileData={{
            name: profileData.name,
            engagementRate: profileData.metrics.engagementRate,
            followerGrowth: profileData.metrics.followerChange,
            postFrequency: profileData.metrics.postFrequency,
            topHashtags: profileData.topHashtags
          }} 
          onUpgrade={handleUpgradeClick} 
        />
        
        <ProfileGoalsSection tier="individual" goals={goals} onAddGoal={handleAddGoal} />
        
        <ProfileAnalyticsSection 
          tier="individual" 
          profileData={profileData} 
          onViewAllPosts={handleViewAllPosts} 
          onDateRangeChange={handleDateRangeChange} 
        />
        
        <CompetitorBenchmark 
          tier="individual" 
          competitors={competitors} 
          onAddCompetitor={() => navigate('/pricing')} 
        />
        
        {/* Export options moved to bottom of report */}
        <div className="mt-8 pt-4 border-t border-gray-200">
          <h3 className="text-lg font-medium mb-4">Export</h3>
          <ExportOptions tier="individual" />
        </div>
      </div>
      
      <UpgradePopup 
        isOpen={isUpgradePopupOpen} 
        onClose={() => setIsUpgradePopupOpen(false)} 
        title={upgradePopupContent.title} 
        description={upgradePopupContent.description} 
        features={upgradePopupContent.features} 
        targetPlan={upgradePopupContent.targetPlan} 
      />
    </main>
  );
};

export default IndividualProfileAnalysis;
