
import { useState, useEffect } from "react";
import { PricingPlan } from "./pricing/PricingPlan";
import { pricingPlans } from "./pricing/pricingPlans";
import { BillingPeriodSelector } from "./pricing/BillingPeriodSelector";
import { useLocation } from "react-router-dom";
export const Pricing = () => {
  const [billingPeriod, setBillingPeriod] = useState<"monthly" | "lifetime">("monthly");
  const [selectedPlanForPayment, setSelectedPlanForPayment] = useState<any>(null);
  const location = useLocation();
  useEffect(() => {
    // Check location state for plan data and payment dialog flag
    const state = location.state as any;
    console.log("Pricing component: Location state", state);
    if (state?.selectedPlan) {
      console.log("Pricing component: Found selected plan in state", state.selectedPlan);
      setSelectedPlanForPayment(state.selectedPlan);
      setBillingPeriod(state.billingPeriod || "monthly");

      // Clear the state to prevent reopening on page refresh
      window.history.replaceState({}, document.title);
    }
  }, [location]);
  return <div className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-10 animate-fade-up">
          <p className="text-linkedin-primary font-semibold mb-2">
            Master Your LinkedIn Influence
          </p>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gray-900">
            Pricing
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">Start your 7-day free trial! Upgrade now to track detailed trends, analyze competitors, and get personalized recommendations</p>
        </div>
      
        <div className="flex justify-center mb-12">
          <BillingPeriodSelector billingPeriod={billingPeriod} onBillingPeriodChange={setBillingPeriod} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {pricingPlans.map((plan, index) => <PricingPlan key={index} plan={plan} billingPeriod={billingPeriod} autoOpenPayment={selectedPlanForPayment?.name === plan.name} onAutoOpenComplete={() => {
          console.log("Payment dialog auto-open complete");
          setSelectedPlanForPayment(null);
        }} />)}
        </div>
      </div>
    </div>;
};
