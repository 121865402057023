
import { Outlet } from "react-router-dom";
import { useIsMobile } from "@/hooks/use-mobile";

const DashboardLayout = () => {
  const isMobile = useIsMobile();
  
  return (
    <div className={`flex-1 ${isMobile ? 'p-3' : 'p-6'} bg-gray-50`}>
      <Outlet />
    </div>
  );
};

export default DashboardLayout;
