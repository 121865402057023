import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { ChartTooltipContent } from "./ChartTooltipContent";

interface EngagementChartProps {
  data: Array<{
    date: string;
    engagement: number;
    benchmark: number;
    followers: number;
  }>;
}

export const EngagementChart = ({ data }: EngagementChartProps) => {
  return (
    <div className="h-[300px] animate-fade-up">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="date" stroke="#94a3b8" />
          <YAxis stroke="#94a3b8" />
          <Tooltip content={ChartTooltipContent} />
          <Legend />
          <Line
            type="monotone"
            dataKey="engagement"
            stroke="#0A66C2"
            strokeWidth={2}
            name="Your Engagement"
            dot={{ r: 4 }}
            activeDot={{ r: 6 }}
            animationDuration={1500}
            animationBegin={0}
          />
          <Line
            type="monotone"
            dataKey="benchmark"
            stroke="#94a3b8"
            strokeDasharray="5 5"
            name="Industry Benchmark"
            animationDuration={1500}
            animationBegin={300}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};