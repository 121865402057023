import { TrendingUp } from "lucide-react";

export const PerformanceInsights = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
      <div className="bg-green-50 p-4 rounded-lg hover:shadow-md transition-all duration-300">
        <div className="flex items-center gap-2">
          <TrendingUp className="w-5 h-5 text-green-600" />
          <h4 className="font-medium text-green-700">Top Performance</h4>
        </div>
        <p className="text-sm text-green-600 mt-1">
          Peak engagement of 90% in May
        </p>
      </div>
      <div className="bg-blue-50 p-4 rounded-lg hover:shadow-md transition-all duration-300">
        <h4 className="font-medium text-blue-700">Growth Trend</h4>
        <p className="text-sm text-blue-600">
          Consistent growth over the last 3 months
        </p>
      </div>
    </div>
  );
};