import { FC, useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Lightbulb, TrendingUp, Award, Lock, Activity, Users, Clock, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useIsMobile } from "@/hooks/use-mobile";

interface InsightType {
  title: string;
  description: string;
  type: 'improvement' | 'opportunity' | 'achievement';
  category?: 'content' | 'engagement' | 'audience' | 'growth';
  priority?: 'high' | 'medium' | 'low';
}

interface AIInsightsProps {
  tier: 'free' | 'individual' | 'pro';
  profileData?: {
    name?: string;
    engagementRate?: number;
    followerGrowth?: number;
    postFrequency?: number;
    topHashtags?: string[];
  };
  insights?: InsightType[];
  onUpgrade?: () => void;
  onRefreshInsights?: () => void;
}

export const AIInsights: FC<AIInsightsProps> = ({ 
  tier, 
  profileData = {}, 
  insights = [], 
  onUpgrade,
  onRefreshInsights
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isMobile = useIsMobile();
  
  const generateInsights = (): InsightType[] => {
    const defaultInsights: InsightType[] = [
      {
        title: "Increase engagement with question posts",
        description: "Your posts that ask questions receive 37% more engagement. Consider including more questions in your content.",
        type: 'improvement',
        category: 'content',
        priority: 'high'
      },
      {
        title: "Profile visitors from tech industry",
        description: "You're getting significant profile visits from the tech industry. Consider creating more tech-focused content.",
        type: 'opportunity',
        category: 'audience',
        priority: 'medium'
      },
      {
        title: "Your comment strategy is working",
        description: "Your comments on others' posts are driving traffic to your profile. Keep engaging with industry thought leaders.",
        type: 'achievement',
        category: 'engagement',
        priority: 'medium'
      },
      {
        title: "Optimal posting times",
        description: "Your audience is most active on weekday mornings. Try scheduling more posts between 8-10am for maximum reach.",
        type: 'opportunity',
        category: 'content',
        priority: 'high'
      },
      {
        title: "Hashtag optimization needed",
        description: "Your posts with industry-specific hashtags perform 42% better. Include 3-5 relevant hashtags in each post.",
        type: 'improvement',
        category: 'content',
        priority: 'medium'
      },
      {
        title: "Strong profile completion",
        description: "Your complete profile is attracting more views. Keep your information updated to maintain this advantage.",
        type: 'achievement',
        category: 'growth',
        priority: 'low'
      }
    ];
    
    if (tier !== 'free') {
      const dynamicInsights: InsightType[] = [];
      
      if (profileData.followerGrowth !== undefined) {
        if (profileData.followerGrowth > 10) {
          dynamicInsights.push({
            title: "Outstanding follower growth",
            description: `Your profile has gained ${profileData.followerGrowth}% more followers this month. Your content strategy is effective.`,
            type: 'achievement',
            category: 'growth',
            priority: 'high'
          });
        } else if (profileData.followerGrowth < 2) {
          dynamicInsights.push({
            title: "Follower growth needs attention",
            description: "Your follower growth is below average. Try engaging more with trending topics in your industry.",
            type: 'improvement',
            category: 'growth',
            priority: 'high'
          });
        }
      }
      
      if (profileData.engagementRate !== undefined) {
        if (profileData.engagementRate < 3) {
          dynamicInsights.push({
            title: "Boost your engagement rate",
            description: "Your engagement rate is lower than industry average. Try ending posts with a question to encourage comments.",
            type: 'improvement',
            category: 'engagement',
            priority: 'high'
          });
        } else if (profileData.engagementRate > 6) {
          dynamicInsights.push({
            title: "Strong engagement metrics",
            description: "Your content is resonating well with your audience. Consider repurposing your top-performing content.",
            type: 'achievement',
            category: 'engagement',
            priority: 'medium'
          });
        }
      }
      
      if (profileData.postFrequency !== undefined) {
        if (profileData.postFrequency < 2) {
          dynamicInsights.push({
            title: "Increase posting frequency",
            description: "You're posting less than 2 times per week. Try to maintain a consistent schedule of 3-4 posts weekly.",
            type: 'improvement',
            category: 'content',
            priority: 'medium'
          });
        }
      }
      
      if (profileData.topHashtags && profileData.topHashtags.length > 0) {
        dynamicInsights.push({
          title: "Optimize your hashtag strategy",
          description: `Your top hashtags like #${profileData.topHashtags.join(', #')} are performing well. Try adding 2-3 more industry-specific hashtags.`,
          type: 'opportunity',
          category: 'content',
          priority: 'low'
        });
      }
      
      if (tier === 'pro') {
        dynamicInsights.push({
          title: "Content calendar optimization",
          description: "Based on your audience activity patterns, Tuesday and Thursday mornings are optimal times to post content.",
          type: 'opportunity',
          category: 'content',
          priority: 'medium'
        });
        
        dynamicInsights.push({
          title: "Audience sentiment analysis",
          description: "Your audience responds most positively to thought leadership content that includes specific examples or case studies.",
          type: 'opportunity',
          category: 'audience',
          priority: 'high'
        });
      }
      
      return [...dynamicInsights, ...defaultInsights].slice(0, tier === 'pro' ? 6 : 4);
    }
    
    return defaultInsights;
  };
  
  const allInsights = insights.length > 0 ? insights : generateInsights();
  
  const displayInsights = tier === 'free' ? allInsights.slice(0, 3) : allInsights;
  const lockedInsights = tier === 'free' ? allInsights.slice(3, 6) : [];
  
  const handleRefreshInsights = () => {
    if (onRefreshInsights) {
      setIsRefreshing(true);
      
      setTimeout(() => {
        onRefreshInsights();
        setIsRefreshing(false);
      }, 1500);
    }
  };
  
  const getIconForType = (type: string) => {
    switch (type) {
      case 'improvement':
        return <TrendingUp className="h-5 w-5 text-blue-500" />;
      case 'opportunity':
        return <Lightbulb className="h-5 w-5 text-yellow-500" />;
      case 'achievement':
        return <Award className="h-5 w-5 text-green-500" />;
      default:
        return <Lightbulb className="h-5 w-5 text-yellow-500" />;
    }
  };
  
  const getCategoryIcon = (category?: string) => {
    switch (category) {
      case 'content':
        return <Activity className="h-4 w-4 text-purple-500" />;
      case 'engagement':
        return <TrendingUp className="h-4 w-4 text-blue-500" />;
      case 'audience':
        return <Users className="h-4 w-4 text-green-500" />;
      case 'growth':
        return <TrendingUp className="h-4 w-4 text-orange-500" />;
      default:
        return null;
    }
  };
  
  const getPriorityColor = (priority?: string) => {
    switch (priority) {
      case 'high':
        return "bg-red-100 text-red-800";
      case 'medium':
        return "bg-amber-100 text-amber-800";
      case 'low':
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };
  
  if (tier === 'free') {
    return (
      <Card className="mt-8 overflow-hidden">
        <CardHeader className="bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-200">
          <CardTitle className="flex items-center">
            <Lightbulb className="h-5 w-5 mr-2 text-yellow-500" />
            AI-Powered Insights
          </CardTitle>
          <CardDescription>
            Smart recommendations based on your LinkedIn activity
          </CardDescription>
        </CardHeader>
        <CardContent className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">Available Insights</h3>
              {displayInsights.map((insight, index) => (
                <div 
                  key={index}
                  className="p-4 bg-white rounded-md border border-gray-200 hover:border-gray-300 transition-colors shadow-sm hover:shadow"
                >
                  <div className="flex items-start gap-3">
                    <div className="mt-1 flex-shrink-0">
                      {getIconForType(insight.type)}
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-wrap items-center gap-2 mb-1">
                        <h4 className="font-medium text-gray-800">{insight.title}</h4>
                        
                        {insight.priority && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Badge variant="outline" className={`${getPriorityColor(insight.priority)} ml-2 text-xs`}>
                                  {insight.priority}
                                </Badge>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{insight.priority === 'high' ? 'Focus on this first' : 
                                   insight.priority === 'medium' ? 'Address this soon' :
                                   'Nice to have'}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                        
                        {insight.category && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Badge variant="outline" className="bg-gray-100 text-gray-800 text-xs">
                                  <span className="flex items-center">
                                    {getCategoryIcon(insight.category)}
                                    <span className="ml-1">{insight.category}</span>
                                  </span>
                                </Badge>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Category: {insight.category}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                      </div>
                      <p className="text-sm text-gray-600">{insight.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {!isMobile ? (
              <div className="relative">
                <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">Premium Insights</h3>
                <div className="space-y-4">
                  {lockedInsights.map((insight, index) => (
                    <div 
                      key={index}
                      className="p-4 bg-white rounded-md border border-gray-200 mb-3 blur-[3px] opacity-70"
                    >
                      <div className="flex items-start gap-3">
                        <div className="mt-1 flex-shrink-0">
                          {getIconForType(insight.type)}
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-wrap items-center gap-2 mb-1">
                            <h4 className="font-medium text-gray-800">{insight.title}</h4>
                            {insight.priority && (
                              <Badge variant="outline" className={`${getPriorityColor(insight.priority)} ml-2 text-xs`}>
                                {insight.priority}
                              </Badge>
                            )}
                          </div>
                          <p className="text-sm text-gray-600">{insight.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
                <div className="absolute inset-0 flex items-center justify-center bg-white/30 backdrop-blur-[2px] rounded-lg mt-10">
                  <Button 
                    className="bg-orange-500 hover:bg-orange-600 shadow-lg px-6 py-2 text-white font-medium flex items-center gap-2 z-10 transform hover:scale-105 transition-all"
                    onClick={onUpgrade}
                  >
                    <Lock className="h-4 w-4" />
                    Upgrade to Unlock
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center p-6 bg-gray-50 rounded-lg border border-dashed border-gray-300">
                <Button 
                  className="bg-orange-500 hover:bg-orange-600 shadow-md px-6 py-2 text-white font-medium flex items-center gap-2 transform hover:scale-105 transition-all"
                  onClick={onUpgrade}
                >
                  <Lock className="h-4 w-4" />
                  Upgrade to Unlock More
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
  
  return (
    <Card className="mt-8 overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-200">
        <div>
          <CardTitle className="flex items-center">
            <Lightbulb className="h-5 w-5 mr-2 text-yellow-500" />
            AI-Powered Insights
          </CardTitle>
          <CardDescription>
            Smart recommendations based on your LinkedIn activity
          </CardDescription>
        </div>
        {tier === 'pro' && (
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleRefreshInsights}
            disabled={isRefreshing}
            className="shadow-sm hover:bg-gray-100"
          >
            {isRefreshing ? (
              <>
                <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                Refreshing...
              </>
            ) : (
              <>
                <RefreshCw className="h-4 w-4 mr-2" />
                Refresh Insights
              </>
            )}
          </Button>
        )}
      </CardHeader>
      <CardContent className="p-6 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {displayInsights.map((insight, index) => (
            <div 
              key={index}
              className="p-4 bg-white rounded-md border border-gray-200 hover:border-gray-300 transition-colors shadow-sm hover:shadow"
            >
              <div className="flex items-start gap-3">
                <div className="mt-1 flex-shrink-0">
                  {getIconForType(insight.type)}
                </div>
                <div className="flex-1">
                  <div className="flex flex-wrap items-center gap-2 mb-1">
                    <h4 className="font-medium text-gray-800">{insight.title}</h4>
                    
                    {insight.priority && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Badge variant="outline" className={`${getPriorityColor(insight.priority)} ml-2 text-xs`}>
                              {insight.priority}
                            </Badge>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{insight.priority === 'high' ? 'Focus on this first' : 
                               insight.priority === 'medium' ? 'Address this soon' :
                               'Nice to have'}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    
                    {insight.category && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Badge variant="outline" className="bg-gray-100 text-gray-800 text-xs">
                              <span className="flex items-center">
                                {getCategoryIcon(insight.category)}
                                <span className="ml-1">{insight.category}</span>
                              </span>
                            </Badge>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Category: {insight.category}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                  <p className="text-sm text-gray-600">{insight.description}</p>
                  
                  {tier === 'pro' && (
                    <div className="mt-2 pt-2 border-t border-dashed border-gray-200">
                      <Button variant="link" size="sm" className="h-6 p-0 text-xs text-linkedin-primary">
                        <Clock className="h-3 w-3 mr-1" />
                        Schedule for action
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {tier === 'individual' && (
          <div className="mt-2 p-3 bg-gray-50 rounded-md border border-dashed border-gray-300">
            <div className="flex items-center text-sm text-gray-600">
              <Lightbulb className="h-4 w-4 mr-2 text-yellow-500" />
              <span>Upgrade to Pro for 2x more AI-powered insights and weekly content recommendations.</span>
              <Button variant="link" size="sm" className="ml-2 text-xs text-linkedin-primary" onClick={onUpgrade}>
                Upgrade
              </Button>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
