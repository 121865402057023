
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useUser } from "@clerk/clerk-react";
import { ArrowRight } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

export function LinkedInProfileWizard() {
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [projectName, setProjectName] = useState("My Profile");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();
  const { userPlan } = usePlanFeatures();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!linkedinUrl) {
      toast({
        title: "LinkedIn URL Required",
        description: "Please enter your LinkedIn profile URL to continue.",
        variant: "destructive"
      });
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Create a new project in localStorage
      const projectId = crypto.randomUUID();
      const profile = {
        id: crypto.randomUUID(),
        name: projectName || "My Profile",
        url: linkedinUrl,
        isActive: true
      };
      
      const newProject = {
        id: projectId,
        name: projectName || "My Profile",
        profiles: [profile]
      };
      
      // Initialize an empty array if 'projects' doesn't exist in localStorage
      const existingProjects = localStorage.getItem('projects');
      const projects = existingProjects ? JSON.parse(existingProjects) : [];
      
      // Add the new project
      projects.push(newProject);
      localStorage.setItem('projects', JSON.stringify(projects));
      
      // Trigger storage update event
      window.dispatchEvent(new Event('storageUpdated'));
      
      // If user is authenticated, store in database too
      if (user?.id) {
        try {
          await supabase
            .from('linkedin_reports')
            .insert({
              clerk_user_id: user.id,
              linkedin_url: linkedinUrl,
              project_name: projectName || "My Profile",
              status: 'pending'
            });
        } catch (dbError) {
          console.error("Database error, continuing with local storage:", dbError);
          // Continue execution even if database insert fails
        }
      }
      
      console.log("Project created, redirecting to analysis page for plan:", userPlan);
      
      // Always navigate immediately to a specific route based on plan
      let targetRoute = '/analysis/free';
      
      if (userPlan === 'individual') {
        targetRoute = '/analysis/individual';
      } else if (userPlan === 'pro') {
        targetRoute = '/analysis/pro';
      }
      
      console.log(`Navigating to ${targetRoute} based on plan: ${userPlan}`);
      
      toast({
        title: "Profile Added",
        description: "Your LinkedIn profile is now being analyzed.",
      });
      
      // Navigate immediately without delay
      navigate(targetRoute, { replace: true });
      
    } catch (error) {
      console.error("Error adding profile:", error);
      setIsLoading(false);
      toast({
        title: "Something went wrong",
        description: "Failed to add your profile. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-lg mx-auto p-6 bg-white rounded-xl shadow-md animate-fade-up">
      <h1 className="text-2xl font-bold text-center mb-6">Analyze Your LinkedIn Profile</h1>
      <p className="text-gray-600 mb-6 text-center">
        Enter your LinkedIn profile URL to start tracking your influence.
      </p>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <label htmlFor="linkedin-url" className="text-sm font-medium">
            LinkedIn Profile URL <span className="text-red-500">*</span>
          </label>
          <Input
            id="linkedin-url"
            placeholder="https://linkedin.com/in/username"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
            required
          />
        </div>
        
        <div className="space-y-2">
          <label htmlFor="project-name" className="text-sm font-medium flex justify-between">
            <span>Project Name</span>
            <span className="text-gray-500 text-xs">(Optional but Recommended)</span>
          </label>
          <Input
            id="project-name"
            placeholder="My Profile"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </div>
        
        <Button 
          type="submit" 
          className="w-full h-12 bg-linkedin-primary hover:bg-linkedin-dark"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : (
            <span className="flex items-center justify-center">
              Start Analysis
              <ArrowRight className="ml-2 h-5 w-5" />
            </span>
          )}
        </Button>
      </form>
    </div>
  );
}
