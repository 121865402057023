
import { FC, useState } from "react";
import { FollowerTrendChartEnhanced } from "./FollowerTrendChartEnhanced";
import { ContentAnalysis } from "./ContentAnalysis";
import { TopPostsList, Post } from "./TopPostsList";
import { OptimizationAndRecommendations } from "./OptimizationAndRecommendations";
import { CompetitorMetrics } from "./types";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Star, ArrowRight } from "lucide-react";

interface ProfileAnalyticsSectionProps {
  tier: 'free' | 'individual' | 'pro';
  profileData: {
    followerTrend: { date: string; followers: number }[];
    postTypes: { type: string; performance: number; trend: number }[];
    mostUsedPostType: string;
    topPosts: Post[];
    profileStrengths: string[];
    profileWeaknesses: string[];
    recommendations: string[];
    metrics: {
      profileStrength: number;
      profileStrengthChange?: number;
    };
    competitorMetrics?: CompetitorMetrics;
  };
  onViewAllPosts?: () => void;
  onDateRangeChange?: (range: string) => void;
}

export const ProfileAnalyticsSection: FC<ProfileAnalyticsSectionProps> = ({
  tier,
  profileData,
  onViewAllPosts,
  onDateRangeChange
}) => {
  const historyLabel = tier === 'free' ? 'Last 30 Days' : tier === 'individual' ? 'Last 90 Days' : 'Full History';
  const [isPremiumFeatureOpen, setIsPremiumFeatureOpen] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  
  const handleAllPostsClick = () => {
    if (tier === 'free') {
      setIsPremiumFeatureOpen(true);
    } else {
      navigate('/all-posts');
    }
  };
  
  const handleUpgrade = () => {
    navigate('/pricing');
    setIsPremiumFeatureOpen(false);
  };
  
  return (
    <>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-10 mb-4">
        <div>
          <h2 className="text-xl font-bold text-gray-800 border-l-4 border-linkedin-primary pl-3">Profile Optimization</h2>
          <div className="text-sm text-gray-500 italic mt-1">{historyLabel}</div>
        </div>
      </div>
      
      <OptimizationAndRecommendations 
        tier={tier}
        profileCompleteness={profileData.metrics.profileStrength} 
        strengths={profileData.profileStrengths} 
        weaknesses={profileData.profileWeaknesses} 
        recommendations={profileData.recommendations} 
        competitorMetrics={tier !== 'free' ? profileData.competitorMetrics : undefined} 
      />
      
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-10 mb-4">
        <div>
          <h2 className="text-xl font-bold text-gray-800 border-l-4 border-linkedin-primary pl-3">Network Growth</h2>
          <div className="text-sm text-gray-500 italic mt-1">{historyLabel}</div>
        </div>
      </div>
      
      <FollowerTrendChartEnhanced 
        data={profileData.followerTrend} 
        tier={tier} 
        onDateRangeChange={onDateRangeChange}
      />
      
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-10 mb-4">
        <div>
          <h2 className="text-xl font-bold text-gray-800 border-l-4 border-linkedin-primary pl-3">Content Analysis</h2>
          <div className="text-sm text-gray-500 italic mt-1">{historyLabel}</div>
        </div>
      </div>
      
      <ContentAnalysis 
        tier={tier} 
        bestPostingTime="Tuesday 10:00 AM - 12:00 PM" 
        postTypes={profileData.postTypes} 
        mostUsedPostType={profileData.mostUsedPostType} 
      />
      
      <div className="flex justify-between items-center mt-10 mb-4">
        <div>
          <h2 className="text-xl font-bold text-gray-800 border-l-4 border-linkedin-primary pl-3">Top Posts</h2>
          <div className="text-sm text-gray-500 italic mt-1">{historyLabel}</div>
        </div>
        <Button 
          variant="ghost" 
          size="sm" 
          className="text-linkedin-primary flex items-center"
          onClick={handleAllPostsClick}
        >
          All Posts <ArrowRight className="ml-1 h-4 w-4" />
        </Button>
      </div>
      
      <TopPostsList 
        tier={tier} 
        posts={profileData.topPosts}
        onViewAllPosts={onViewAllPosts}
      />

      {/* Premium Feature Dialog */}
      <Dialog open={isPremiumFeatureOpen} onOpenChange={setIsPremiumFeatureOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Star className="h-5 w-5 text-amber-400" />
              You've Discovered a Premium Feature
            </DialogTitle>
            <DialogDescription>
              Get complete post analysis with advanced metrics and filtering capabilities.
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <h3 className="font-medium mb-2">With All Posts Analysis, you can:</h3>
            <ul className="space-y-2 pl-5 list-disc text-sm">
              <li>Track all your posts in one convenient dashboard</li>
              <li>Sort by engagement, impressions, or date</li>
              <li>Filter posts by time period (7 days to 365 days)</li>
              <li>Identify patterns in your top performing content</li>
              <li>Export data for deeper analysis</li>
            </ul>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsPremiumFeatureOpen(false)}>
              Maybe Later
            </Button>
            <Button onClick={handleUpgrade} className="bg-linkedin-primary hover:bg-linkedin-dark">
              Upgrade Now
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

