import { TooltipProps } from "recharts";

export const ChartTooltipContent = ({ 
  active, 
  payload, 
  label 
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 border rounded-lg shadow-lg">
        <p className="font-medium">{label}</p>
        {payload.map((entry, index) => (
          <p
            key={index}
            className="text-sm"
            style={{ color: entry.color }}
          >
            {entry.name}: {entry.value}%
          </p>
        ))}
        <p className="text-xs text-gray-600 mt-2">
          {label === "May" ? "Peak engagement due to video content" : ""}
        </p>
      </div>
    );
  }
  return null;
};