import { Card } from "@/components/ui/card";
import { ProfileHeaderPro } from "./linkedin/pro/ProfileHeaderPro";
import { GlobalMetricsPro } from "./linkedin/pro/GlobalMetricsPro";
import { AdvancedAnalytics } from "./linkedin/pro/AdvancedAnalytics";
import { ContentStrategy } from "./linkedin/pro/ContentStrategy";
import { PostPerformance } from "./linkedin/pro/PostPerformance";
import { AudienceInsights } from "./linkedin/pro/AudienceInsights";
import { useState } from "react";
import { ReportHeader } from "./linkedin/pro/header/ReportHeader";

interface LinkedInProReportProps {
  url: string;
}

const metrics = {
  followers: 4532,
  followerGrowth: 8,
  posts: 48,
  views: 125678,
  profileStrength: 72,
  engagementScore: 85,
};

export const LinkedInProReport = ({ url }: LinkedInProReportProps) => {
  const [timeframe, setTimeframe] = useState({
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString(),
    end: new Date().toLocaleDateString(),
  });

  const handleTimeframeChange = (value: string) => {
    const now = new Date();
    let start = new Date();
    
    switch (value) {
      case "30d":
        start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "90d":
        start = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
        break;
      case "6m":
        start = new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000);
        break;
      case "1y":
        start = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
        break;
    }

    setTimeframe({
      start: start.toLocaleDateString(),
      end: now.toLocaleDateString(),
    });
  };

  return (
    <div className="space-y-8 text-left animate-fade-up">
      <ReportHeader 
        timeframe={timeframe}
        onTimeframeChange={handleTimeframeChange}
      />
      
      <Card className="p-6 bg-white">
        <ProfileHeaderPro
          name="Felix Davidson"
          title="Senior Product Designer"
          avatarUrl="https://api.dicebear.com/7.x/avataaars/svg?seed=Felix"
          profileStrength={metrics.profileStrength}
          engagementScore={metrics.engagementScore}
        />
      </Card>

      <GlobalMetricsPro {...metrics} />
      <AdvancedAnalytics />
      <ContentStrategy />
      <PostPerformance />
      <AudienceInsights />
    </div>
  );
};