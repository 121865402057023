
import { Post } from "@/components/profile-analysis/TopPostsList";
import { CompetitorMetrics } from "@/components/profile-analysis/types";

export interface ProfileData {
  name: string;
  headline: string;
  avatarUrl: string;
  score: number;
  summary: string;
  metrics: {
    followers: number;
    followerChange: number;
    engagementRate: number;
    engagementChange: number;
    postFrequency: number;
    postFrequencyChange: number;
    avgEngagements: number;
    avgEngagementsChange: number;
    avgViews: number;
    avgViewsChange: number;
    profileStrength: number;
    profileStrengthChange: number;
  };
  followerTrend: {
    date: string;
    followers: number;
  }[];
  profileStrengths: string[];
  profileWeaknesses: string[];
  postTypes: {
    type: string;
    performance: number;
    trend: number;
  }[];
  topHashtags: string[];
  mostUsedPostType: string;
  competitorMetrics: CompetitorMetrics | null;
  goals: {
    id: string;
    name: string;
    current: number;
    target: number;
    unit: string;
  }[];
  recommendations: string[];
  topPosts: Post[];
}

export const getMockProfileData = (tier: 'free' | 'individual' | 'pro'): ProfileData => {
  // Base profile data
  const baseProfile: ProfileData = {
    name: "John Doe",
    headline: "Senior Marketing Manager | Digital Strategy | Content Creation",
    avatarUrl: "https://api.dicebear.com/7.x/avataaars/svg?seed=John",
    score: 86,
    summary: "Your LinkedIn profile is well-optimized but there are some opportunities for improvement.",
    metrics: {
      followers: 3865,
      followerChange: 12.8,
      engagementRate: 4.7,
      engagementChange: 2.1,
      postFrequency: 2.3,
      postFrequencyChange: -0.8,
      avgEngagements: 68,
      avgEngagementsChange: 5.2,
      avgViews: 1250,
      avgViewsChange: 8.5,
      profileStrength: 92,
      profileStrengthChange: 5
    },
    followerTrend: [],
    profileStrengths: [
      "Your profile photo and banner are well optimized", 
      "Your headline is compelling and keyword-rich", 
      "Your experience section is detailed with measurable achievements"
    ],
    profileWeaknesses: ["Add more media to your featured section"],
    postTypes: [
      { type: "Text", performance: 12, trend: 5 },
      { type: "Image", performance: 28, trend: 10 },
      { type: "Video", performance: 8, trend: 3 }
    ],
    topHashtags: ["leadership", "marketing", "innovation"],
    mostUsedPostType: "Text",
    competitorMetrics: null,
    goals: [
      {
        id: "goal1",
        name: "Follower Growth",
        current: 3865,
        target: 5000,
        unit: "followers"
      },
      {
        id: "goal2",
        name: "Weekly Posts",
        current: 2,
        target: 3,
        unit: "posts"
      },
      {
        id: "goal3",
        name: "Engagement Rate",
        current: 4.7,
        target: 6,
        unit: "%"
      }
    ],
    recommendations: [
      "Increase your posting frequency to 3-4 times per week to boost engagement",
      "Add more visual content - images and videos typically perform better than text-only posts",
      "Engage more with comments on your posts to encourage further discussions"
    ],
    topPosts: Array.from({ length: 6 }, (_, i) => ({
      id: `post${i+1}`,
      text: `Example post ${i+1} with some content that demonstrates engagement...`,
      likes: 50 + Math.floor(Math.random() * 150),
      comments: 10 + Math.floor(Math.random() * 40),
      shares: 5 + Math.floor(Math.random() * 20),
      views: 1000 + Math.floor(Math.random() * 3000),
      date: new Date(Date.now() - (i * 5 + 2) * 24 * 60 * 60 * 1000).toISOString(),
      type: ["text", "image", "video"][Math.floor(Math.random() * 3)]
    }))
  };
  
  // Generate follower trend data based on tier
  const dataHistoryDays = tier === 'pro' ? 365 : tier === 'individual' ? 90 : 30;
  baseProfile.followerTrend = Array.from({
    length: dataHistoryDays
  }, (_, i) => ({
    date: new Date(Date.now() - (dataHistoryDays - 1 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    followers: 3700 + Math.floor(Math.random() * 200)
  }));
  
  // Tier-specific enhancements
  if (tier === 'individual' || tier === 'pro') {
    baseProfile.profileStrengths.push("Your skills section is well populated with relevant skills");
    baseProfile.profileWeaknesses.push("Consider adding recommendations from colleagues");
    baseProfile.topHashtags.push("digitalmarketing", "contentcreation");
    baseProfile.competitorMetrics = {
      postingFrequency: -20,
      engagementRate: 12,
      profileOptimization: 8,
      contentQuality: 15,
      networkGrowth: 22
    };
    
    // Add AI insights for paid tiers
    baseProfile.recommendations = [
      "✨ Based on your audience engagement patterns, consider posting more frequently on Tuesdays and Thursdays",
      "Increase your posting frequency to 3-4 times per week to boost engagement", 
      "✨ Your image posts receive 32% higher engagement than text-only posts",
      "Add more visual content - images and videos typically perform better than text-only posts", 
      "Engage more with comments on your posts to encourage further discussions",
      "✨ Your content on leadership topics receives the highest engagement",
      "Complete your 'About' section with more detail for better profile optimization"
    ];
  }
  
  if (tier === 'pro') {
    baseProfile.goals.push(
      {
        id: "goal4",
        name: "Comments per Post",
        current: 12,
        target: 20,
        unit: "comments"
      },
      {
        id: "goal5",
        name: "Profile Views",
        current: 550,
        target: 1000,
        unit: "views/month"
      }
    );
    
    // Add more AI insights for pro tier
    baseProfile.recommendations.push(
      "✨ Industry comparison: Your post frequency is 28% lower than industry leaders",
      "✨ AI Recommendation: Add more video content to diversify your content mix",
      "✨ Engagement peaks between 8-10am, consider scheduling posts in this window"
    );
  }
  
  return baseProfile;
};
