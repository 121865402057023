
import { FC, useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useNavigate } from "react-router-dom";
import { Lock } from "lucide-react";

interface DataPoint {
  date: string;
  followers: number;
}

interface FollowerTrendChartEnhancedProps {
  data: DataPoint[];
  tier: 'free' | 'individual' | 'pro';
  onDateRangeChange?: (range: string) => void;
}

export const FollowerTrendChartEnhanced: FC<FollowerTrendChartEnhancedProps> = ({
  data,
  tier,
  onDateRangeChange
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { dataHistory } = usePlanFeatures();
  const [range, setRange] = useState("30d");
  
  // Apply range filter
  const visibleData = data.slice(-getDateRangeDays(range));
  
  function getDateRangeDays(rangeStr: string): number {
    switch (rangeStr) {
      case "7d": return 7;
      case "30d": return 30;
      case "90d": return 90;
      case "6m": return 180;
      case "1y": return 365;
      case "all": return data.length;
      default: return 30;
    }
  }
  
  const handleRangeChange = (newRange: string) => {
    // Check if the range is valid for the current tier
    const rangeDays = getDateRangeDays(newRange);
    
    if (rangeDays > dataHistory) {
      // If range exceeds the tier's data history limit, show upgrade message
      if (onDateRangeChange) {
        onDateRangeChange(newRange);
      } else {
        navigate("/pricing");
      }
      return;
    }
    
    setRange(newRange);
  };
  
  return (
    <div className="space-y-4 mb-10">
      <Card className="border border-gray-200">
        <CardContent className="pt-6">
          <div className="flex flex-wrap gap-3 mb-4">
            <Button 
              variant={range === "7d" ? "default" : "outline"} 
              size="sm"
              onClick={() => handleRangeChange("7d")}
            >
              7 Days
            </Button>
            <Button 
              variant={range === "30d" ? "default" : "outline"} 
              size="sm"
              onClick={() => handleRangeChange("30d")}
            >
              30 Days
            </Button>
            
            {(tier === "individual" || tier === "pro") && (
              <Button 
                variant={range === "90d" ? "default" : "outline"} 
                size="sm"
                onClick={() => handleRangeChange("90d")}
              >
                90 Days
              </Button>
            )}
            
            {tier === "pro" && (
              <>
                <Button 
                  variant={range === "6m" ? "default" : "outline"} 
                  size="sm"
                  onClick={() => handleRangeChange("6m")}
                >
                  6 Months
                </Button>
                <Button 
                  variant={range === "1y" ? "default" : "outline"} 
                  size="sm"
                  onClick={() => handleRangeChange("1y")}
                >
                  1 Year
                </Button>
                <Button 
                  variant={range === "all" ? "default" : "outline"} 
                  size="sm"
                  onClick={() => handleRangeChange("all")}
                >
                  All Time
                </Button>
              </>
            )}
            
            {tier === "free" && (
              <>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="text-gray-400"
                  onClick={() => onDateRangeChange && onDateRangeChange("90d")}
                >
                  <Lock className="h-3 w-3 mr-1" />
                  90 Days
                </Button>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="text-gray-400"
                  onClick={() => onDateRangeChange && onDateRangeChange("6m")}
                >
                  <Lock className="h-3 w-3 mr-1" />
                  6 Months
                </Button>
              </>
            )}
          </div>
          
          <div className="h-[300px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={visibleData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorFollowers" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0A66C2" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0A66C2" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => {
                    const d = new Date(date);
                    return d.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                  }}
                  tick={{ fontSize: 12 }}
                  tickMargin={10}
                  tickCount={isMobile ? 5 : 10}
                />
                <YAxis 
                  tick={{ fontSize: 12 }}
                  tickMargin={10}
                  domain={['auto', 'auto']}
                />
                <Tooltip 
                  formatter={(value: number) => [value, 'Followers']}
                  labelFormatter={(date) => {
                    const d = new Date(date);
                    return d.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
                  }}
                />
                <Area 
                  type="monotone" 
                  dataKey="followers" 
                  stroke="#0A66C2" 
                  fillOpacity={1} 
                  fill="url(#colorFollowers)" 
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          
          <div className="text-center mt-4">
            <div className="text-sm font-medium">
              {visibleData.length > 0 && (
                <>
                  Follower Growth: 
                  <span className="text-green-600 ml-1">
                    +{visibleData[visibleData.length - 1].followers - visibleData[0].followers} 
                    ({((visibleData[visibleData.length - 1].followers - visibleData[0].followers) / visibleData[0].followers * 100).toFixed(1)}%)
                  </span>
                </>
              )}
            </div>
            <div className="text-xs text-gray-500 mt-1">
              {new Date(visibleData[0]?.date).toLocaleDateString()} - {new Date(visibleData[visibleData.length - 1]?.date).toLocaleDateString()}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
