
import { Button } from "@/components/ui/button";
import { Logo } from "./Logo";
import { useIsMobile } from "@/hooks/use-mobile";
import { SignInButton, SignOutButton, useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useEffect, useState } from "react";
import { Bell, Menu, Trophy } from "lucide-react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Sidebar } from "./Sidebar";

export const Header = () => {
  const isMobile = useIsMobile();
  const isDevelopment = process.env.NODE_ENV === 'development' || 
    window.location.hostname.includes('lovable.dev') || 
    window.location.hostname.includes('preview--trackfluence-home.lovable.app');
  
  const { isSignedIn, user, isLoaded } = useUser();
  const [authLoaded, setAuthLoaded] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoaded) {
        setAuthLoaded(true);
        console.log('Auth timeout reached, forcing loaded state');
      }
    }, 2000);

    if (isLoaded) {
      setAuthLoaded(true);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoaded]);

  const getInitials = (name: string) => {
    return name?.charAt(0).toUpperCase() || "U";
  };

  const renderUserAvatar = () => (
    <Avatar className="h-8 w-8">
      <AvatarFallback className="bg-orange-500 text-white font-medium">
        {getInitials(user?.fullName || "")}
      </AvatarFallback>
    </Avatar>
  );

  const renderAuthButtons = () => {
    const effectivelyLoaded = isLoaded || authLoaded;

    if (!effectivelyLoaded) {
      return (
        <div className="flex items-center gap-4">
          <Button variant="ghost" className="font-semibold">Sign In</Button>
          <Button className="bg-linkedin-primary hover:bg-linkedin-dark font-semibold">
            Sign Up
          </Button>
        </div>
      );
    }

    return isSignedIn ? (
      <div className="flex items-center gap-4">
        <Link to="/pricing">
          <Button
            className="bg-orange-500 hover:bg-orange-600 text-white font-semibold flex items-center gap-2"
            size="sm"
          >
            <Trophy className="h-4 w-4" />
            Upgrade Plan
          </Button>
        </Link>
        <Link to="/dashboard">
          <Button variant="ghost" className="font-semibold">
            Dashboard
          </Button>
        </Link>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 rounded-full p-0">
              {renderUserAvatar()}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56 bg-white border border-gray-200 shadow-md z-50">
            <DropdownMenuItem asChild className="cursor-pointer hover:bg-gray-100">
              <a href="https://accounts.trackinfluence.com/user" className="cursor-pointer">
                Profile Settings
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem className="cursor-pointer hover:bg-gray-100">
              <SignOutButton>
                <span className="w-full cursor-pointer">Sign Out</span>
              </SignOutButton>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ) : (
      <div className="flex items-center gap-4">
        <Link to="/pricing">
          <Button
            className="bg-orange-500 hover:bg-orange-600 text-white font-semibold flex items-center gap-2"
            size="sm"
          >
            <Trophy className="h-4 w-4" />
            Upgrade Plan
          </Button>
        </Link>
        <SignInButton mode="modal">
          <Button variant="ghost" className="font-semibold">
            Sign In
          </Button>
        </SignInButton>
        <Link to="/sign-up">
          <Button className="bg-linkedin-primary hover:bg-linkedin-dark font-semibold">
            Sign Up
          </Button>
        </Link>
      </div>
    );
  };

  // Bell notification button
  const NotificationBellButton = () => (
    <Button variant="ghost" size="icon" className="relative">
      <Bell className="h-5 w-5" />
      {notificationCount > 0 && (
        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
          {notificationCount}
        </span>
      )}
    </Button>
  );

  return (
    <header className="border-b relative z-50">
      <div className="container mx-auto px-4 h-16 flex items-center">
        {isMobile ? (
          <div className="w-full flex justify-between items-center">
            {/* Mobile layout: Hamburger - Logo - Notifications/Avatar */}
            <Sheet open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
              <SheetTrigger asChild>
                <Button variant="ghost" size="icon" className="mr-2">
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="p-0 w-[250px]">
                <Sidebar showAuthButtons={true} />
              </SheetContent>
            </Sheet>
            
            {/* Centered logo */}
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <Logo />
            </div>
            
            {/* Notification bell and/or avatar */}
            <div className="flex items-center gap-2">
              <NotificationBellButton />
              {isLoaded && isSignedIn && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 rounded-full p-0">
                      {renderUserAvatar()}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-56 bg-white border border-gray-200 shadow-md z-50">
                    <DropdownMenuItem asChild className="cursor-pointer hover:bg-gray-100">
                      <Link to="/dashboard">Dashboard</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className="cursor-pointer hover:bg-gray-100">
                      <Link to="/pricing">Upgrade Plan</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className="cursor-pointer hover:bg-gray-100">
                      <a href="https://accounts.trackinfluence.com/user" className="cursor-pointer">
                        Profile Settings
                      </a>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </div>
        ) : (
          <>
            <Logo />
            <div className="ml-auto flex gap-4 items-center">
              <NotificationBellButton />
              {renderAuthButtons()}
            </div>
          </>
        )}
      </div>
    </header>
  );
};
