import { Card } from "@/components/ui/card";
import { Star } from "lucide-react";
import { ProfileHeader } from "./linkedin/ProfileHeader";
import { MetricsOverview } from "./linkedin/MetricsOverview";
import { EngagementOverview } from "./linkedin/EngagementOverview";
import { TimeframeBanner } from "./linkedin/TimeframeBanner";
import { FollowerGrowthChart } from "./linkedin/FollowerGrowthChart";
import { RecentPostsList } from "./linkedin/RecentPostsList";
import { UpgradeCTA } from "./linkedin/UpgradeCTA";
import { ProUpgradeButton } from "./linkedin/ProUpgradeButton";
import { useSubscription } from "@/hooks/useSubscription";
import { useToast } from "@/components/ui/use-toast";

interface LinkedInReportProps {
  url: string;
}

const followerData = Array.from({ length: 30 }, (_, i) => ({
  date: new Date(Date.now() - (29 - i) * 24 * 60 * 60 * 1000).toLocaleDateString(),
  followers: 4200 + Math.floor(Math.random() * 400),
}));

export const LinkedInReport = ({ url }: LinkedInReportProps) => {
  const { data: subscription } = useSubscription();
  const { toast } = useToast();

  const metrics = {
    followers: 4532,
    followerGrowth: 8,
    posts: 48,
    views: 125678,
    profileStrength: 72,
  };

  const recentPosts = [
    {
      id: 1,
      text: "Excited to announce our latest product launch! #innovation #tech",
      views: 15234,
      likes: 456,
      comments: 32,
      shares: 12,
      isTopPerformer: true,
      link: "https://linkedin.com/post/1",
      thumbnail: "https://api.dicebear.com/7.x/shapes/svg?seed=post1",
    },
    {
      id: 2,
      text: "Great meeting with the team today discussing future strategies.",
      views: 8765,
      likes: 234,
      comments: 15,
      shares: 8,
      isTopPerformer: false,
      link: "https://linkedin.com/post/2",
      thumbnail: "https://api.dicebear.com/7.x/shapes/svg?seed=post2",
    },
    {
      id: 3,
      text: "Sharing insights from our latest market research study.",
      views: 10234,
      likes: 345,
      comments: 28,
      shares: 15,
      isTopPerformer: false,
      link: "https://linkedin.com/post/3",
      thumbnail: "https://api.dicebear.com/7.x/shapes/svg?seed=post3",
    },
  ];

  const timeframe = {
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString(),
    end: new Date().toLocaleDateString(),
  };

  const handleExport = () => {
    if (!subscription?.can_export) {
      toast({
        title: "Export Not Available",
        description: "Please upgrade to a paid plan to export reports.",
        variant: "destructive",
      });
      return;
    }
    
    // Handle export logic here
    toast({
      title: "Export Started",
      description: "Your report will be downloaded shortly.",
    });
  };

  return (
    <div className="space-y-8 text-left">
      <TimeframeBanner {...timeframe} />

      <Card className="p-6 bg-white">
        <div className="flex flex-col md:flex-row md:items-start gap-6">
          <div className="flex-1">
            <ProfileHeader
              name="Felix Davidson"
              title="Senior Product Designer"
              avatarUrl="https://api.dicebear.com/7.x/avataaars/svg?seed=Felix"
              profileStrength={metrics.profileStrength}
              subtext={subscription?.plan === 'free' ? 
                "Upgrade to Pro for advanced insights and competitor analysis." :
                "Your profile is well-optimized—improve it further with advanced insights."}
            />
          </div>
          <div className="md:w-auto">
            <ProUpgradeButton />
          </div>
        </div>
      </Card>

      <MetricsOverview {...metrics} />
      <EngagementOverview posts={recentPosts} />
      <FollowerGrowthChart data={followerData} />
      <RecentPostsList posts={recentPosts} />
      {subscription?.plan === 'free' && <UpgradeCTA />}
    </div>
  );
};