
import { useState, useEffect } from "react";
import { useSubscription } from "@/hooks/useSubscription";
import { useUser } from "@clerk/clerk-react";

export type PlanTier = 'free' | 'individual' | 'pro';

export interface PlanLimits {
  maxProjects: number;
  maxProfiles: number;
  maxCompetitors: number;
  maxKeywords: number;
  maxGoals: number;
  dataHistory: number; // days
}

export const usePlanFeatures = () => {
  const { data: subscription, isLoading: subscriptionLoading } = useSubscription();
  const { isSignedIn } = useUser();
  const [userPlan, setUserPlan] = useState<PlanTier>('free');
  
  // Set the user plan based on subscription data
  useEffect(() => {
    if (!isSignedIn) {
      console.log('User not signed in, defaulting to free plan');
      setUserPlan('free');
      return;
    }
    
    // If subscription is loading, keep the current plan
    if (subscriptionLoading) {
      console.log('Subscription is loading, defaulting to free plan temporarily');
      return; // Keep current plan while loading
    }
    
    // Set plan based on subscription data
    if (subscription) {
      // Ensure we have a valid plan value
      const planType = (subscription.plan || 'free').toLowerCase();
      console.log('Setting plan from subscription:', planType);
      
      if (planType === 'pro') {
        setUserPlan('pro');
      } else if (planType === 'individual') {
        setUserPlan('individual');
      } else {
        setUserPlan('free');
      }
    } else {
      console.log('No subscription data, defaulting to free plan');
      setUserPlan('free');
    }
  }, [subscription, subscriptionLoading, isSignedIn]);
  
  // Define plan-specific limits
  const getPlanLimits = (): PlanLimits => {
    switch (userPlan) {
      case 'pro':
        return {
          maxProjects: 5,
          maxProfiles: 5,
          maxCompetitors: 3,
          maxKeywords: 20,
          maxGoals: 10,
          dataHistory: 365, // days
        };
      case 'individual':
        return {
          maxProjects: 3,
          maxProfiles: 1,
          maxCompetitors: 1,
          maxKeywords: 10,
          maxGoals: 5,
          dataHistory: 90, // days
        };
      case 'free':
      default:
        return {
          maxProjects: 1,
          maxProfiles: 1,
          maxCompetitors: 0,
          maxKeywords: 3,
          maxGoals: 3,
          dataHistory: 30, // days
        };
    }
  };

  const limits = getPlanLimits();
  
  return {
    userPlan,
    canAccessIndividual: userPlan === 'individual' || userPlan === 'pro',
    canAccessPro: userPlan === 'pro',
    isAuthenticated: isSignedIn, 
    limits,
    maxProjects: limits.maxProjects,
    maxProfiles: limits.maxProfiles,
    maxCompetitors: limits.maxCompetitors,
    maxKeywords: limits.maxKeywords,
    maxGoals: limits.maxGoals,
    dataHistory: limits.dataHistory,
  };
};
