
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { Hero } from "@/components/Hero";
import { Features } from "@/components/Features";
import { BlogPreview } from "@/components/BlogPreview";
import { CallToAction } from "@/components/CallToAction";
import { Footer } from "@/components/Footer";
import { GuestHomePage } from "@/components/GuestHomePage";
import { useEffect } from "react";

const Index = () => {
  const navigate = useNavigate();
  const { isSignedIn, isLoaded } = useUser();
  const isDevelopment = process.env.NODE_ENV === 'development' || 
    (typeof window !== 'undefined' && window.location.hostname.includes('lovable.dev'));

  useEffect(() => {
    // Initialize empty projects array if it doesn't exist
    try {
      const projects = localStorage.getItem('projects');
      if (!projects) {
        localStorage.setItem('projects', JSON.stringify([]));
      } else {
        // Validate projects structure
        try {
          const parsedProjects = JSON.parse(projects);
          if (!Array.isArray(parsedProjects)) {
            console.error("Projects data is not an array:", parsedProjects);
            localStorage.setItem('projects', JSON.stringify([]));
          }
        } catch (error) {
          console.error("Error parsing projects data:", error);
          localStorage.setItem('projects', JSON.stringify([]));
        }
      }
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }
    
    // Handle LinkedIn redirect
    if (typeof window !== 'undefined' && !isDevelopment) {
      const params = new URLSearchParams(window.location.search);
      const hasLinkedInCode = params.has('code');
      if (hasLinkedInCode) {
        navigate('/dashboard');
      }
    }
  }, [navigate, isDevelopment]);

  // If auth is not loaded yet, return null to prevent flash of content
  if (!isLoaded) {
    return null;
  }

  // For non-signed-in users, show the GuestHomePage component
  if (!isSignedIn) {
    return <GuestHomePage />;
  }

  // If we reach here, user is signed in, show the regular homepage
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-1">
        <Hero />
        <Features />
        <BlogPreview />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default Index;
