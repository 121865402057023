
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { LayoutDashboard, Settings, Folder, HelpCircle, Headset, Trophy, LogOut, LogIn } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { LemonSqueezyPaymentDialog } from "./pricing/LemonSqueezyPaymentDialog";
import { pricingPlans } from "./pricing/pricingPlans";
import { useSubscription } from "@/hooks/useSubscription";
import { useToast } from "@/components/ui/use-toast";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { SignInButton, SignOutButton, useUser } from "@clerk/clerk-react";
import { useIsMobile } from "@/hooks/use-mobile";

interface SidebarProps {
  showAuthButtons?: boolean;
}

export const Sidebar = ({ showAuthButtons = false }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showProUpgrade, setShowProUpgrade] = useState(false);
  
  // Get subscription data safely with defaults
  const { data: subscription } = useSubscription();
  const { canAccessIndividual, canAccessPro, userPlan, isAuthenticated } = usePlanFeatures();
  const { isSignedIn, isLoaded } = useUser();
  const isMobile = useIsMobile();
  
  // Don't render the sidebar on mobile
  if (isMobile) {
    return null;
  }

  if (!isLoaded || (!isSignedIn && !showAuthButtons)) {
    return null;
  }

  // Fix the profile analysis link to point to the appropriate page based on user plan
  const getAnalysisPath = () => {
    if (canAccessPro) {
      return '/analysis/pro';
    } else if (canAccessIndividual) {
      return '/analysis/individual';
    } else {
      return '/analysis/free';
    }
  };

  const getUpgradeConfig = () => {
    if (!subscription) {
      return {
        text: "Start 7-day free trial to unlock more data",
        buttonText: "Get Started",
        action: () => navigate('/pricing')
      };
    }

    // Ensure subscription.plan is a string before using toLowerCase
    const planName = (subscription.plan && typeof subscription.plan === 'string') 
      ? subscription.plan.toLowerCase() 
      : 'free';
    
    switch (planName) {
      case 'individual':
        return {
          text: "Upgrade to pro to unlock all data",
          buttonText: "Upgrade to Pro",
          action: () => setShowProUpgrade(true)
        };
      case 'free':
        return {
          text: "Upgrade to Individual for more features",
          buttonText: "Upgrade to Individual",
          action: () => navigate('/pricing')
        };
      default:
        return null;
    }
  };

  const upgradeConfig = getUpgradeConfig();
  const showUpgradeButton = upgradeConfig !== null;

  const handleUpgradeSuccess = () => {
    setShowProUpgrade(false);
    toast({
      title: "Success",
      description: "Your subscription has been updated.",
    });
    navigate(getAnalysisPath());
  };

  // Only show Dashboard and All Posts for Pro users
  const showDashboard = canAccessPro;
  const showAllPosts = canAccessPro;

  return (
    <aside className="w-full md:w-64 bg-sidebar-background border-r border-sidebar-border p-4 flex flex-col">
      <nav className="flex-1 space-y-4">
        {showAuthButtons && !isSignedIn && (
          <>
            <div className="py-4">
              <SignInButton mode="modal">
                <Button className="w-full flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary">
                  <LogIn className="w-5 h-5" />
                  <span>Sign In</span>
                </Button>
              </SignInButton>
            </div>
            <div className="pb-4">
              <Link to="/sign-up">
                <Button className="w-full bg-linkedin-primary hover:bg-linkedin-dark flex items-center gap-2">
                  <LogIn className="w-5 h-5" />
                  <span>Sign Up</span>
                </Button>
              </Link>
            </div>
            <Separator className="bg-sidebar-border" />
          </>
        )}

        {showDashboard && (
          <Link 
            to="/dashboard"
            className={`flex items-center gap-2 ${
              location.pathname === '/dashboard' ? 'text-sidebar-primary' : 'text-sidebar-foreground'
            } hover:text-sidebar-primary`}
          >
            <LayoutDashboard className="w-5 h-5" />
            <span>Dashboard</span>
          </Link>
        )}

        <Link 
          to={getAnalysisPath()}
          className={`flex items-center gap-2 ${
            ['/analysis', '/analysis/free', '/analysis/individual', '/analysis/pro'].some(path => 
              location.pathname.startsWith(path)) 
              ? 'text-sidebar-primary' 
              : 'text-sidebar-foreground'
          } hover:text-sidebar-primary`}
        >
          <Folder className="w-5 h-5" />
          <span>Profile Analysis</span>
        </Link>

        {showAllPosts && (
          <Link 
            to="/all-posts"
            className={`flex items-center gap-2 ${
              location.pathname === '/all-posts' ? 'text-sidebar-primary' : 'text-sidebar-foreground'
            } hover:text-sidebar-primary`}
          >
            <Folder className="w-5 h-5" />
            <span>All Posts</span>
          </Link>
        )}

        <Separator className="bg-sidebar-border" />

        <Link 
          to="/dashboard/settings"
          className={`flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary ${
            location.pathname.includes('/settings') ? 'text-sidebar-primary' : ''
          }`}
        >
          <Settings className="w-5 h-5" />
          <span>Settings</span>
        </Link>

        <Link 
          to="/help"
          className="flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary"
        >
          <HelpCircle className="w-5 h-5" />
          <span>Help</span>
        </Link>

        <Link 
          to="/support"
          className="flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary"
        >
          <Headset className="w-5 h-5" />
          <span>Support</span>
        </Link>
        
        {showAuthButtons && isSignedIn && (
          <>
            <Separator className="bg-sidebar-border" />
            <SignOutButton>
              <Button variant="ghost" className="w-full flex items-center gap-2 text-sidebar-foreground hover:text-sidebar-primary">
                <LogOut className="w-5 h-5" />
                <span>Sign Out</span>
              </Button>
            </SignOutButton>
          </>
        )}
      </nav>
      
      {showUpgradeButton && upgradeConfig && (
        <div className="mt-6 space-y-4">
          <Button
            className="w-full bg-gradient-to-r from-orange-400 to-orange-500 hover:from-orange-500 hover:to-orange-600 text-white transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
            onClick={upgradeConfig.action}
          >
            {upgradeConfig.buttonText}
          </Button>
          <p className="text-xs text-muted-foreground text-center px-2">
            {upgradeConfig.text}
          </p>
        </div>
      )}

      {showProUpgrade && pricingPlans && pricingPlans.length > 1 && (
        <LemonSqueezyPaymentDialog
          plan={{
            name: pricingPlans[1].name,
            price: Number(pricingPlans[1].price),
            features: [...pricingPlans[1].features],
          }}
          billingPeriod="monthly"
          onSuccess={handleUpgradeSuccess}
          onClose={() => setShowProUpgrade(false)}
        />
      )}
    </aside>
  );
};
