
import { FC, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { Plus, Lock, Trophy, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogClose } from "@/components/ui/dialog";
import { useIsMobile } from "@/hooks/use-mobile";

interface Goal {
  id: string;
  name: string;
  current: number;
  target: number;
  unit: string;
}

interface GoalTrackingProps {
  tier: 'free' | 'individual' | 'pro';
  goals: Goal[];
  onAddGoal: (goal: Omit<Goal, 'id'>) => void;
}

export const GoalTracking: FC<GoalTrackingProps> = ({
  tier,
  goals,
  onAddGoal
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isFree = tier === 'free';
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

  const calculateProgress = (current: number, target: number): number => {
    const progress = current / target * 100;
    return Math.min(progress, 100); // Cap at 100%
  };

  const handleAddGoalClick = () => {
    if (isFree) {
      setIsUpgradeDialogOpen(true);
    } else {
      // For paid tiers, implement goal adding modal here
      // This would be additional functionality
    }
  };

  return <div className="space-y-6 mb-10">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {goals.map(goal => <div key={goal.id} className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-all duration-300 bg-white">
            <div className="flex justify-between items-start mb-2">
              <h4 className="font-medium">{goal.name}</h4>
              <span className="text-sm text-gray-500">{goal.unit}</span>
            </div>
            <div className="flex justify-between text-sm text-gray-700 mb-1">
              <span>Current: {goal.current}</span>
              <span>Target: {goal.target}</span>
            </div>
            <Progress value={calculateProgress(goal.current, goal.target)} className="h-2 mb-2" />
            <div className="text-xs text-right">
              {calculateProgress(goal.current, goal.target).toFixed(0)}% complete
            </div>
          </div>)}
      </div>
      
      {/* Upgrade Dialog */}
      <Dialog open={isUpgradeDialogOpen} onOpenChange={setIsUpgradeDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Trophy className="h-5 w-5 text-orange-500" /> 
              Goal Customization Locked
            </DialogTitle>
            <DialogDescription>
              Custom goals help you track your LinkedIn growth more effectively. Upgrade to our Individual plan to create, modify, and track unlimited custom goals.
            </DialogDescription>
          </DialogHeader>
          <div className="p-4 bg-gray-50 rounded-md">
            <h4 className="font-medium mb-2">With the Individual plan, you can:</h4>
            <ul className="space-y-2 text-sm">
              <li className="flex items-start gap-2">
                <div className="h-5 w-5 text-green-500 flex-shrink-0">✓</div>
                <span>Create custom goals based on your LinkedIn strategy</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="h-5 w-5 text-green-500 flex-shrink-0">✓</div>
                <span>Set specific targets for growth and engagement</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="h-5 w-5 text-green-500 flex-shrink-0">✓</div>
                <span>Track progress with detailed analytics</span>
              </li>
            </ul>
          </div>
          <DialogFooter className="flex-col sm:flex-row gap-2">
            <DialogClose asChild>
              <Button variant="outline" className="sm:w-auto w-full">
                <X className="h-4 w-4 mr-2" />
                Maybe Later
              </Button>
            </DialogClose>
            <Button className="bg-orange-500 hover:bg-orange-600 sm:w-auto w-full" onClick={() => {
            setIsUpgradeDialogOpen(false);
            navigate('/pricing');
          }}>
              <Trophy className="h-4 w-4 mr-2" />
              Upgrade Now
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>;
};
