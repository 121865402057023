import { FC, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Plus, Users, TrendingUp, TrendingDown, BarChart4, X, User, RefreshCw } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { useNavigate } from "react-router-dom";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useToast } from "@/components/ui/use-toast";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
interface CompetitorProfile {
  id: string;
  name: string;
  avatarUrl: string;
  followers: number;
  followerChange: number;
  engagement: number;
  postFrequency: number;
}
interface CompetitorBenchmarkProps {
  tier: 'free' | 'individual' | 'pro';
  competitors?: CompetitorProfile[];
  onAddCompetitor?: (competitor: Omit<CompetitorProfile, 'id'>) => void;
  onRemoveCompetitor?: (id: string) => void;
  onRefreshCompetitor?: (id: string) => void;
}
export const CompetitorBenchmark: FC<CompetitorBenchmarkProps> = ({
  tier,
  competitors = [],
  onAddCompetitor,
  onRemoveCompetitor,
  onRefreshCompetitor
}) => {
  const navigate = useNavigate();
  const {
    toast
  } = useToast();
  const {
    maxCompetitors
  } = usePlanFeatures();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userMetrics, setUserMetrics] = useState({
    engagement: 4.7,
    postFrequency: 2.3
  });
  const handleAddCompetitor = () => {
    if (tier === 'free') {
      navigate("/pricing");
      return;
    }
    if (competitors.length >= maxCompetitors) {
      toast({
        title: "Competitor Limit Reached",
        description: `You can track up to ${maxCompetitors} competitors on your current plan.`,
        variant: "destructive"
      });
      return;
    }
    setIsAddDialogOpen(true);
  };
  const handleSubmitCompetitor = async () => {
    if (!linkedinUrl) {
      toast({
        title: "LinkedIn URL required",
        description: "Please enter a valid LinkedIn profile URL",
        variant: "destructive"
      });
      return;
    }
    setIsSubmitting(true);
    try {
      // In a real implementation, this would call an API to fetch competitor data
      // For this demo, we'll simulate an API call with a timeout
      setTimeout(() => {
        const seed = linkedinUrl.split('/').pop() || Math.random().toString(36).substring(7);
        const newCompetitor = {
          name: generateNameFromSeed(seed),
          avatarUrl: `https://api.dicebear.com/7.x/avataaars/svg?seed=${seed}`,
          followers: Math.floor(1000 + Math.random() * 10000),
          followerChange: parseFloat((Math.random() * 20 - 5).toFixed(1)),
          engagement: parseFloat((Math.random() * 8 + 1).toFixed(1)),
          postFrequency: parseFloat((Math.random() * 5 + 0.5).toFixed(1))
        };
        if (onAddCompetitor) {
          onAddCompetitor(newCompetitor);
        }
        setIsSubmitting(false);
        setIsAddDialogOpen(false);
        setLinkedinUrl("");
        toast({
          title: "Competitor Added",
          description: `${newCompetitor.name}'s profile has been added for tracking.`
        });
      }, 1500);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: "Failed to add competitor",
        description: "There was a problem processing your request.",
        variant: "destructive"
      });
    }
  };
  const handleRemoveCompetitor = (id: string) => {
    if (onRemoveCompetitor) {
      onRemoveCompetitor(id);
      toast({
        title: "Competitor Removed",
        description: "The competitor has been removed from your tracking list."
      });
    }
  };
  const handleRefreshCompetitor = (id: string) => {
    if (onRefreshCompetitor) {
      onRefreshCompetitor(id);
      toast({
        title: "Refreshing Competitor Data",
        description: "Updating competitor metrics. This may take a moment."
      });
    }
  };

  // Helper function to generate a realistic name from a seed
  const generateNameFromSeed = (seed: string): string => {
    const firstNames = ["Sarah", "Michael", "Emma", "David", "Jessica", "Alex", "Jennifer", "Thomas", "Emily", "Robert"];
    const lastNames = ["Johnson", "Smith", "Williams", "Brown", "Jones", "Miller", "Davis", "Wilson", "Roberts", "Chen"];

    // Generate a consistent index based on the seed
    const charSum = seed.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const firstNameIndex = charSum % firstNames.length;
    const lastNameIndex = charSum * 13 % lastNames.length;
    return `${firstNames[firstNameIndex]} ${lastNames[lastNameIndex]}`;
  };
  if (tier === 'free') {
    return <div className="space-y-4 mb-10">
        <Card className="border border-gray-200">
          
          
        </Card>
      </div>;
  }
  return <div className="space-y-4 mb-10">
      <Card className="border border-gray-200">
        <CardHeader className="pb-2 flex flex-row items-center justify-between">
          <div>
            <CardTitle className="text-base">Competitor Benchmarking</CardTitle>
            <div className="text-xs text-gray-500 mt-1">
              Tracking {competitors.length} of {maxCompetitors} competitors
            </div>
          </div>
          <Button size="sm" variant="outline" onClick={handleAddCompetitor}>
            <Plus className="h-4 w-4 mr-2" />
            <span className="text-sm">Add Competitor</span>
          </Button>
        </CardHeader>
        <CardContent>
          {competitors.length === 0 ? <div className="flex flex-col items-center justify-center py-8 text-center">
              <BarChart4 className="h-12 w-12 text-gray-300 mb-3" />
              <h3 className="text-lg font-medium mb-2">No Competitors Added</h3>
              <p className="text-gray-500 text-sm mb-4 max-w-md">
                Start tracking competitors to benchmark your LinkedIn performance.
              </p>
              <Button onClick={handleAddCompetitor} variant="outline">
                <Plus className="h-4 w-4 mr-2" />
                Add Your First Competitor
              </Button>
            </div> : <div className="space-y-6">
              {competitors.map(competitor => <div key={competitor.id} className="border rounded-md p-4 relative">
                  <div className="absolute top-2 right-2 flex gap-2">
                    <Button variant="ghost" size="icon" className="h-6 w-6" onClick={() => handleRefreshCompetitor(competitor.id)} title="Refresh competitor data">
                      <RefreshCw className="h-4 w-4 text-gray-500 hover:text-gray-700" />
                    </Button>
                    <Button variant="ghost" size="icon" className="h-6 w-6" onClick={() => handleRemoveCompetitor(competitor.id)} title="Remove competitor">
                      <X className="h-4 w-4 text-gray-500 hover:text-gray-700" />
                    </Button>
                  </div>
                  
                  <div className="flex items-center mb-4">
                    <img src={competitor.avatarUrl} alt={competitor.name} className="h-10 w-10 rounded-full mr-3" />
                    <div>
                      <h3 className="font-medium">{competitor.name}</h3>
                      <div className="text-sm text-gray-500">
                        {competitor.followers.toLocaleString()} followers
                        <span className={`ml-2 ${competitor.followerChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                          {competitor.followerChange >= 0 ? <TrendingUp className="h-3 w-3 inline mr-1" /> : <TrendingDown className="h-3 w-3 inline mr-1" />}
                          {Math.abs(competitor.followerChange)}%
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="space-y-3">
                    <div>
                      <div className="flex justify-between text-sm mb-1">
                        <span>Engagement Rate</span>
                        <span>
                          <span className="font-medium">{competitor.engagement}%</span>
                          {" "}
                          <span className={`text-xs ${competitor.engagement > userMetrics.engagement ? 'text-red-500' : 'text-green-500'}`}>
                            {competitor.engagement > userMetrics.engagement ? '↑' : '↓'} vs Your {userMetrics.engagement}%
                          </span>
                        </span>
                      </div>
                      <Progress value={competitor.engagement * 10} className={`h-2 ${competitor.engagement > userMetrics.engagement ? 'bg-red-100' : 'bg-green-100'}`} />
                    </div>
                    
                    <div>
                      <div className="flex justify-between text-sm mb-1">
                        <span>Posting Frequency</span>
                        <span>
                          <span className="font-medium">{competitor.postFrequency}/week</span>
                          {" "}
                          <span className={`text-xs ${competitor.postFrequency > userMetrics.postFrequency ? 'text-red-500' : 'text-green-500'}`}>
                            {competitor.postFrequency > userMetrics.postFrequency ? '↑' : '↓'} vs Your {userMetrics.postFrequency}/week
                          </span>
                        </span>
                      </div>
                      <Progress value={competitor.postFrequency * 20} className={`h-2 ${competitor.postFrequency > userMetrics.postFrequency ? 'bg-red-100' : 'bg-green-100'}`} />
                    </div>
                    
                    <div className="mt-4 pt-3 border-t border-gray-100">
                      <h4 className="text-sm font-medium mb-2">Performance Insights:</h4>
                      <p className="text-xs text-gray-600">
                        {competitor.engagement > userMetrics.engagement ? `${competitor.name} has a ${(competitor.engagement - userMetrics.engagement).toFixed(1)}% higher engagement rate. Analyze their content strategy.` : `Your engagement is ${(userMetrics.engagement - competitor.engagement).toFixed(1)}% higher. You're outperforming in audience interaction.`}
                      </p>
                      <p className="text-xs text-gray-600 mt-1">
                        {competitor.postFrequency > userMetrics.postFrequency ? `They post ${(competitor.postFrequency - userMetrics.postFrequency).toFixed(1)} more times per week than you. Consider increasing your posting frequency.` : `You're posting more frequently, which helps maintain visibility in your audience's feed.`}
                      </p>
                    </div>
                  </div>
                </div>)}
            </div>}
        </CardContent>
      </Card>
      
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Add Competitor Profile</DialogTitle>
            <DialogDescription>
              Enter the LinkedIn URL of the competitor you want to track and compare with your profile.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="linkedin-url" className="text-right col-span-1">
                LinkedIn URL
              </Label>
              <div className="col-span-3">
                <Input id="linkedin-url" placeholder="https://linkedin.com/in/competitor" value={linkedinUrl} onChange={e => setLinkedinUrl(e.target.value)} className="w-full" />
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsAddDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmitCompetitor} disabled={isSubmitting || !linkedinUrl} className="bg-linkedin-primary hover:bg-linkedin-dark">
              {isSubmitting ? <>
                  <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                  Processing...
                </> : <>
                  <User className="h-4 w-4 mr-2" />
                  Add Competitor
                </>}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>;
};