import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { Trophy, Star, ArrowUpRight } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface ProfileHeaderProps {
  name: string;
  title: string;
  avatarUrl: string;
  profileStrength: number;
  subtext?: string;
}

export const ProfileHeader = ({ name, title, avatarUrl, profileStrength, subtext }: ProfileHeaderProps) => {
  return (
    <div className="relative p-6 rounded-lg bg-gradient-to-r from-linkedin-primary/5 to-linkedin-dark/5">
      <div className="absolute inset-0 opacity-10">
        <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none">
          <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
            <path d="M 10 0 L 0 0 0 10" fill="none" stroke="currentColor" strokeWidth="0.5"/>
          </pattern>
          <rect width="100" height="100" fill="url(#grid)"/>
        </svg>
      </div>
      
      <div className="relative flex gap-6 items-start justify-between">
        <div className="flex gap-4">
          <div className="relative">
            <div className="absolute inset-0 bg-linkedin-primary/20 rounded-full blur opacity-20 animate-pulse"></div>
            <Avatar className="w-16 h-16 border-2 border-white shadow-lg">
              <AvatarImage src={avatarUrl} />
              <AvatarFallback>{name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
            </Avatar>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">{name}</h2>
            <p className="text-gray-600">{title}</p>
            <div className="mt-3 space-y-2">
              <div className="flex items-center gap-2">
                <div className="text-sm font-medium text-linkedin-primary">
                  Profile Strength: {profileStrength}%
                </div>
                <Progress 
                  value={profileStrength} 
                  className="w-32 h-2 bg-linkedin-light/20"
                />
              </div>
              <p className="text-sm text-gray-600 animate-fade-up">
                {subtext || "Unlock advanced insights to reach 100% profile strength!"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};