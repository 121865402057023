
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";
import { ReportCreation } from "@/components/ReportCreation";

export const GuestHomePage = () => {
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [email, setEmail] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!agreedToTerms) {
      alert("Please agree to the Terms of Service to continue.");
      return;
    }

    // Start processing
    setIsProcessing(true);
    console.log("LinkedIn URL:", linkedinUrl);
    console.log("Email:", email);
    console.log("Agreed to Terms:", agreedToTerms);

    // In a real implementation, this would make an API call to start analysis
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      <div className="flex-1 flex items-start justify-center px-4 py-0">
        <div className="w-full max-w-md bg-white rounded-xl shadow-md overflow-hidden p-6 sm:p-8 mt-2">
          {isProcessing ? (
            <div className="animate-fade-in">
              <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6">
                Generating Your LinkedIn Report
              </h2>
              <ReportCreation linkedinUrl={linkedinUrl} />
              {email ? (
                <div className="mt-6 text-center text-gray-600">
                  <p>Great! We're analyzing your LinkedIn profile.</p>
                  <p className="mt-2">
                    We'll email the link to <span className="font-medium">{email}</span> as soon as it's ready.
                  </p>
                </div>
              ) : (
                <div className="mt-6 p-4 bg-blue-50 rounded-lg">
                  <h3 className="font-medium text-center mb-2">Want to skip the wait?</h3>
                  <div className="flex gap-2">
                    <Input 
                      type="email" 
                      placeholder="Enter your email" 
                      value={email} 
                      onChange={e => setEmail(e.target.value)} 
                      className="h-10" 
                    />
                    <Button size="sm" className="whitespace-nowrap bg-linkedin-primary hover:bg-linkedin-dark">
                      Email Me
                    </Button>
                  </div>
                  <p className="text-xs text-center mt-2 text-gray-500">
                    We'll send you the link when it's ready
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>
              <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">
                Analyze Your LinkedIn Impact for Free
              </h1>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-2">
                  <label htmlFor="linkedin-url" className="block font-medium text-gray-700">
                    LinkedIn URL
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg className="h-5 w-5 text-linkedin-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                      </svg>
                    </span>
                    <Input 
                      id="linkedin-url" 
                      value={linkedinUrl} 
                      onChange={e => setLinkedinUrl(e.target.value)} 
                      className="pl-10 h-12" 
                      placeholder="https://linkedin.com/in/yourprofile" 
                      required 
                    />
                  </div>
                  <p className="text-sm text-gray-500">
                    Enter the full URL to your LinkedIn profile
                  </p>
                </div>
                
                <div className="space-y-2">
                  <label htmlFor="email" className="block font-medium text-gray-700">
                    Email Address
                  </label>
                  <Input 
                    id="email" 
                    type="email" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    className="h-12" 
                    placeholder="you@example.com" 
                  />
                  <p className="text-sm text-gray-500">
                    Skip the wait: Enter email to get the link when ready & receive updates.
                  </p>
                </div>
                
                <Button 
                  type="submit" 
                  className="w-full h-12 bg-linkedin-primary hover:bg-linkedin-dark transition-colors text-white flex items-center justify-center gap-2 group"
                >
                  <span>Start My Analysis</span>
                  <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Button>
                
                <div className="flex items-center space-x-2 pt-2">
                  <Checkbox 
                    id="terms" 
                    checked={agreedToTerms} 
                    onCheckedChange={checked => setAgreedToTerms(checked as boolean)} 
                    required 
                  />
                  <label htmlFor="terms" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    I agree to the <Link to="/terms" className="text-linkedin-primary hover:underline">Terms of Service</Link>
                  </label>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      
      <footer className="py-4 text-center">
        {/* Footer content */}
      </footer>
    </div>
  );
};
