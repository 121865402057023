
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { testBrightDataConnection } from "@/utils/linkedinReportAPI";
import { useToast } from "@/components/ui/use-toast";
import { AlertCircle, RefreshCw, ExternalLink } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const BrightDataConnectionTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [testUrl, setTestUrl] = useState("https://www.linkedin.com/in/gal-mor/");
  const [isPro, setIsPro] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  const runConnectionTest = async () => {
    if (!testUrl) {
      toast({
        title: "URL Required",
        description: "Please enter a LinkedIn URL to test",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      // Determine which tier to test with
      let tierIsPro = isPro;
      let tierIsIndividual = !isPro && isIndividual;
      
      console.log("Running echo test with tier:", {
        isPro: tierIsPro,
        isIndividual: tierIsIndividual,
        tier: tierIsPro ? "Pro" : tierIsIndividual ? "Individual" : "Free"
      });
      
      const response = await testBrightDataConnection(testUrl, tierIsPro, tierIsIndividual);
      setResult(response);
      
      toast({
        title: "Test Completed",
        description: "The echo test was completed successfully. Check the results below.",
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      
      toast({
        title: "Test Failed",
        description: err instanceof Error ? err.message : "An unknown error occurred",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row items-end gap-2">
        <div className="flex-1">
          <label htmlFor="test-url" className="block text-sm font-medium mb-1">
            LinkedIn URL for Echo Test
          </label>
          <Input
            id="test-url"
            value={testUrl}
            onChange={(e) => setTestUrl(e.target.value)}
            placeholder="https://www.linkedin.com/in/username/"
          />
        </div>
        
        <div className="flex flex-row space-x-4 my-2 sm:my-0">
          <div className="flex items-center">
            <label htmlFor="is-individual-test" className="text-sm font-medium mr-2">
              Individual
            </label>
            <Switch
              id="is-individual-test"
              checked={isIndividual && !isPro}
              onCheckedChange={(checked) => {
                setIsIndividual(checked);
                if (checked) setIsPro(false);
              }}
              disabled={isPro}
            />
          </div>
          
          <div className="flex items-center">
            <label htmlFor="is-pro-test" className="text-sm font-medium mr-2">
              Pro
            </label>
            <Switch
              id="is-pro-test"
              checked={isPro}
              onCheckedChange={(checked) => {
                setIsPro(checked);
                if (checked) setIsIndividual(false);
              }}
            />
          </div>
        </div>
        
        <Button 
          onClick={runConnectionTest} 
          disabled={isLoading}
          className="gap-2 w-full sm:w-auto"
        >
          {isLoading ? (
            <>
              <RefreshCw className="h-4 w-4 animate-spin" />
              Testing...
            </>
          ) : (
            "Run Echo Test"
          )}
        </Button>
      </div>
      
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      {result && (
        <div className="border rounded-md p-4 bg-gray-50">
          <h3 className="font-medium mb-2">Echo Test Results</h3>
          <pre className="text-xs overflow-auto bg-white p-3 border rounded max-h-[400px]">
            {JSON.stringify(result, null, 2)}
          </pre>
        </div>
      )}
      
      <div className="text-sm text-gray-500 border-t pt-4 mt-4">
        <div className="flex justify-between items-start">
          <div>
            <p>This test calls a separate edge function that simply echoes back the request parameters without making any API calls to BrightData.</p>
            <p className="mt-1">It helps verify that data is being sent correctly from the frontend to the Edge Function before attempting actual API calls.</p>
          </div>
          
          <a 
            href="https://brightdata.com/products/datasets"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-600 hover:text-blue-800 ml-4"
          >
            BrightData Datasets <ExternalLink className="h-3 w-3 ml-1" />
          </a>
        </div>
        
        <div className="mt-4 px-4 py-3 bg-blue-50 border border-blue-100 rounded-md">
          <p className="font-medium text-blue-800 mb-1">BrightData Dataset Information:</p>
          <ul className="list-disc ml-6 text-blue-700">
            <li>Standard version uses the LinkedIn profiles dataset (<code className="bg-blue-100 px-1 rounded">gd_l1viktl72bvl7bjuj0</code>)</li>
            <li>Pro version uses the LinkedIn posts dataset (<code className="bg-blue-100 px-1 rounded">gd_lyy3tktm25m4avu764</code>)</li>
            <li>Each tier (Free, Individual, Pro) applies different data filtering/processing</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
