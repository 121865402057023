
import { Check } from "lucide-react";

interface PricingPlanFeatureProps {
  feature: string;
}

export const PricingPlanFeature = ({ feature }: PricingPlanFeatureProps) => {
  // Process the feature text to handle bold markers and icons
  const renderFeatureText = () => {
    // Check if the feature has bold markdown
    if (feature.includes("**")) {
      // Replace markdown bold with actual HTML bold tags
      const parts = feature.split("**");
      const hasBold = parts.length > 1;
      
      // Extract any trailing icons (emoji)
      const textPart = parts[parts.length - 1];
      const hasEmoji = /[\p{Emoji}]/u.test(textPart);
      
      let displayText = feature;
      let icon = "";
      
      if (hasEmoji) {
        // Find emoji at the end of the string
        const emojiMatch = textPart.match(/([\s][\p{Emoji}]+)$/u);
        if (emojiMatch) {
          icon = emojiMatch[0];
          // Remove emoji from the display text
          displayText = feature.replace(emojiMatch[0], "");
        }
      }
      
      // Create the HTML structure with bold parts
      return (
        <>
          <span 
            dangerouslySetInnerHTML={{ 
              __html: displayText
                .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            }} 
          />
          {icon && <span className="ml-1 text-[#F97316]">{icon.trim()}</span>}
        </>
      );
    }
    
    return <span>{feature}</span>;
  };

  return (
    <li className="flex items-start">
      <div className="bg-orange-50 rounded-full p-1 mr-3 mt-1">
        <Check className="w-4 h-4 text-[#F97316]" />
      </div>
      <span className="text-gray-700">{renderFeatureText()}</span>
    </li>
  );
};
