import { ExternalLink, Info } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface Post {
  rank: number;
  type: string;
  title: string;
  views: number;
  likes: number;
  comments: number;
  shares: number;
  engagement: number;
  date: string;
  tooltip: string;
}

interface PostsListProps {
  posts: Post[];
}

export const PostsList = ({ posts }: PostsListProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleViewAll = () => {
    toast({
      title: "Navigation",
      description: "Viewing all posts...",
    });
    navigate('/all-posts');
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="font-medium">Top 5 Posts This Period</h4>
        <Button
          variant="outline"
          className="flex items-center gap-2"
          onClick={handleViewAll}
        >
          <span>View All Posts</span>
          <ExternalLink className="w-4 h-4" />
        </Button>
      </div>

      <div className="space-y-4">
        {posts.map((post) => (
          <div 
            key={post.rank}
            className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <span className="font-bold text-lg text-linkedin-primary">#{post.rank}</span>
                <div>
                  <div className="flex items-center gap-2">
                    <h5 className="font-medium">{post.title}</h5>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="w-4 h-4 text-gray-400 hover:text-linkedin-primary transition-colors" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="max-w-xs">{post.tooltip}</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <p className="text-sm text-gray-600">{post.type} • {post.date}</p>
                </div>
              </div>
              <div className="flex items-center gap-6 text-sm">
                <span>👁️ {post.views.toLocaleString()}</span>
                <span>👍 {post.likes}</span>
                <span>💬 {post.comments}</span>
                <span>↗️ {post.shares}</span>
                <span className="font-medium text-linkedin-primary">{post.engagement}% Engagement</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Separator className="my-6" />
    </div>
  );
};