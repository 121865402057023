
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { Search } from "lucide-react";
import { getMockProfileData } from "@/utils/mockProfileData";
import { InfluenceScore } from "./InfluenceScore";
import { useIsMobile } from "@/hooks/use-mobile";

interface AnalyzeUrlFieldProps {
  onAnalyzeComplete: (action: 'upgrade' | 'replace' | 'discard', profile: any) => void;
}

export function AnalyzeUrlField({ onAnalyzeComplete }: AnalyzeUrlFieldProps) {
  const [url, setUrl] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newProfile, setNewProfile] = useState<any>(null);
  const isMobile = useIsMobile();
  
  const handleAnalyze = () => {
    if (!url || !url.includes('linkedin.com')) {
      return;
    }
    
    setIsAnalyzing(true);
    setProgress(0);
    
    // Simulate progress updates
    const interval = setInterval(() => {
      setProgress(prev => {
        const newProgress = prev + 10;
        if (newProgress >= 100) {
          clearInterval(interval);
          // Get mock data for the new analysis
          const mockData = getMockProfileData('free');
          setNewProfile(mockData);
          setIsDialogOpen(true);
          setIsAnalyzing(false);
          return 100;
        }
        return newProgress;
      });
    }, 500);
  };
  
  const handleAction = (action: 'upgrade' | 'replace' | 'discard') => {
    onAnalyzeComplete(action, newProfile);
    setIsDialogOpen(false);
    setUrl("");
    setNewProfile(null);
  };
  
  return (
    <>
      <div className="bg-white p-4 rounded-xl shadow-sm border border-gray-100 mb-6">
        <h3 className="text-sm font-medium mb-2">Analyze a LinkedIn Profile</h3>
        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} ${isMobile ? 'space-y-2' : 'space-x-2'}`}>
          <Input
            placeholder="Enter LinkedIn URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            disabled={isAnalyzing}
            className="w-full"
          />
          <Button 
            onClick={handleAnalyze} 
            disabled={isAnalyzing || !url.includes('linkedin.com')}
            className={`${isMobile ? 'w-full' : 'shrink-0'}`}
          >
            {isAnalyzing ? (
              <span className="flex items-center">
                Analyzing...
              </span>
            ) : (
              <span className="flex items-center">
                Analyze <Search className="ml-2 h-4 w-4" />
              </span>
            )}
          </Button>
        </div>
        
        {isAnalyzing && (
          <div className="mt-3">
            <Progress value={progress} className="h-2" />
            <p className="text-xs text-gray-500 mt-1">Analyzing profile... {progress}%</p>
          </div>
        )}
      </div>
      
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className={`${isMobile ? 'w-[95%] max-w-lg' : 'sm:max-w-lg'}`}>
          <DialogHeader>
            <DialogTitle>New Profile Analyzed!</DialogTitle>
            <DialogDescription>
              You analyzed '{newProfile?.name || url}'. Since your current plan only supports tracking one profile, what would you like to do?
            </DialogDescription>
          </DialogHeader>
          
          {newProfile && (
            <div className="p-4 bg-gray-50 rounded-md">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h3 className="font-medium">{newProfile.name}</h3>
                  <p className="text-sm text-gray-500">{newProfile.headline}</p>
                </div>
                {newProfile.avatarUrl && (
                  <img 
                    src={newProfile.avatarUrl} 
                    alt={newProfile.name} 
                    className="w-12 h-12 rounded-full"
                  />
                )}
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <InfluenceScore 
                    score={newProfile.score} 
                    size="small" 
                  />
                </div>
                <div className="space-y-2">
                  <div>
                    <p className="text-xs text-gray-500">Followers</p>
                    <p className="font-medium">{newProfile.metrics.followers}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500">Engagement Rate</p>
                    <p className="font-medium">{newProfile.metrics.engagementRate}%</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <DialogFooter className={`flex ${isMobile ? 'flex-col' : 'flex-col sm:flex-row'} ${isMobile ? 'gap-2' : 'sm:justify-between gap-3'}`}>
            <Button
              onClick={() => handleAction('upgrade')}
              className={`${isMobile ? 'w-full' : 'w-full sm:w-auto'} bg-gradient-to-r from-blue-600 to-indigo-600`}
            >
              Add & Upgrade to Pro
            </Button>
            <div className={`flex ${isMobile ? 'flex-col' : 'flex-col sm:flex-row'} gap-2 w-full sm:w-auto`}>
              <Button
                onClick={() => handleAction('replace')}
                variant="outline"
                className={`${isMobile ? 'w-full' : 'w-full sm:w-auto'}`}
              >
                Replace Existing
              </Button>
              <Button
                onClick={() => handleAction('discard')}
                variant="ghost"
                className={`${isMobile ? 'w-full' : 'w-full sm:w-auto'}`}
              >
                Discard New Analysis
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
