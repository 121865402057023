import { Card } from "@/components/ui/card";
import { ArrowUpRight, Star, Trophy, Users, Target, Zap, Lock } from "lucide-react";
import { ProUpgradeButton } from "./ProUpgradeButton";

export const UpgradeCTA = () => (
  <Card className="p-8 bg-gradient-to-br from-linkedin-primary/5 via-linkedin-light/5 to-linkedin-dark/5 border-none sticky bottom-4 md:relative md:bottom-0">
    <div className="text-center space-y-6">
      <div className="space-y-2">
        <h3 className="text-xl font-semibold text-gray-900">
          Maximize Your LinkedIn Performance
        </h3>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Get detailed insights, competitor analysis, and content optimization recommendations with our Pro plan.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-3xl mx-auto">
        <div className="flex flex-col items-center gap-2 p-4 rounded-lg bg-white/50 group hover:bg-white/80 transition-all duration-300">
          <Users className="w-8 h-8 text-linkedin-primary group-hover:scale-110 transition-transform" />
          <p className="text-sm font-medium">Compare with competitors</p>
          <Lock className="w-4 h-4 text-gray-400" />
        </div>
        <div className="flex flex-col items-center gap-2 p-4 rounded-lg bg-white/50 group hover:bg-white/80 transition-all duration-300">
          <Target className="w-8 h-8 text-linkedin-primary group-hover:scale-110 transition-transform" />
          <p className="text-sm font-medium">Advanced audience insights</p>
          <Lock className="w-4 h-4 text-gray-400" />
        </div>
        <div className="flex flex-col items-center gap-2 p-4 rounded-lg bg-white/50 group hover:bg-white/80 transition-all duration-300">
          <Zap className="w-8 h-8 text-linkedin-primary group-hover:scale-110 transition-transform" />
          <p className="text-sm font-medium">Top-performing strategies</p>
          <Lock className="w-4 h-4 text-gray-400" />
        </div>
      </div>

      <div className="pt-4">
        <div className="mb-4">
          <div className="text-sm text-gray-600 italic bg-white/50 p-3 rounded-lg inline-block">
            "I grew my audience by 35% in 3 months with Pro!" 
            <span className="font-medium block">– Alex, Marketing Manager</span>
          </div>
        </div>
        
        <div className="flex justify-center">
          <ProUpgradeButton />
        </div>
      </div>
    </div>
  </Card>
);