
import { FC } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Lightbulb } from "lucide-react";

interface RecommendationsListProps {
  tier: 'free' | 'individual' | 'pro';
  recommendations: string[];
}

export const RecommendationsList: FC<RecommendationsListProps> = ({
  tier,
  recommendations,
}) => {
  const isFree = tier === 'free';
  
  // Use more recommendations
  const enhancedRecommendations = isFree ? [
    "Complete your LinkedIn profile with all sections filled out",
    "Post consistently at least 2-3 times per week",
    "Use relevant industry hashtags to expand your reach",
    "Engage with content from your network daily",
    "Share a mix of text, image, and video content",
    "Comment thoughtfully on trending posts in your industry",
    "Respond to all comments on your posts within 24 hours"
  ] : recommendations.slice(0, 7); // Show 7 recommendations instead of 6
  
  return (
    <div className="space-y-6">
      <Card className="border border-gray-200 hover:shadow-md transition-all duration-300">
        <CardHeader className="bg-gray-50 pb-2">
          <CardTitle className="text-base flex items-center">
            <Lightbulb className="h-5 w-5 text-orange-500 mr-2" />
            Action Plan
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-4">
          <ul className="space-y-3">
            {enhancedRecommendations.map((recommendation, idx) => (
              <li key={idx} className="flex items-start gap-3">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-orange-50 flex items-center justify-center text-orange-500 mt-0.5">
                  {idx + 1}
                </div>
                <div>
                  <p className="text-sm">{recommendation}</p>
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};
