
import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LayoutDashboard, FileText, Settings, HelpCircle, Headset, Trophy, FileSearch } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";
import { useIsMobile } from "@/hooks/use-mobile";

interface ProfileAnalysisSidebarProps {
  tier: 'free' | 'individual' | 'pro';
}

export const ProfileAnalysisSidebar: FC<ProfileAnalysisSidebarProps> = ({ tier }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { canAccessPro } = usePlanFeatures();
  const isMobile = useIsMobile();
  
  // Don't render the sidebar on mobile
  if (isMobile) {
    return null;
  }
  
  const isActive = (path: string) => location.pathname === path;
  
  const getAnalysisPath = () => {
    switch (tier) {
      case 'pro': return '/analysis/pro';
      case 'individual': return '/analysis/individual';
      default: return '/analysis/free';
    }
  };
  
  // Only show Dashboard and All Posts for Pro users
  const showDashboard = canAccessPro;
  const showAllPosts = canAccessPro;
  
  const getUpgradeText = () => {
    switch (tier) {
      case 'free': return 'Upgrade to Individual';
      case 'individual': return 'Upgrade to Pro';
      default: return '';
    }
  };
  
  return (
    <aside className="w-full md:w-64 bg-sidebar-background border-r border-sidebar-border p-4 flex flex-col">
      <nav className="flex-1 space-y-4">
        {showDashboard && (
          <Button
            variant="ghost"
            className={`w-full justify-start ${isActive('/dashboard') ? 'text-sidebar-primary bg-sidebar-active' : 'text-sidebar-foreground'}`}
            onClick={() => navigate('/dashboard')}
          >
            <LayoutDashboard className="h-5 w-5 mr-2" />
            <span>Dashboard</span>
          </Button>
        )}
        
        <Button
          variant="ghost"
          className={`w-full justify-start ${isActive(getAnalysisPath()) ? 'text-sidebar-primary bg-sidebar-active' : 'text-sidebar-foreground'}`}
          onClick={() => navigate(getAnalysisPath())}
        >
          <FileSearch className="h-5 w-5 mr-2" />
          <span>Profile Analysis</span>
        </Button>
        
        {showAllPosts && (
          <Button
            variant="ghost"
            className={`w-full justify-start ${isActive('/all-posts') ? 'text-sidebar-primary bg-sidebar-active' : 'text-sidebar-foreground'}`}
            onClick={() => navigate('/all-posts')}
          >
            <FileText className="h-5 w-5 mr-2" />
            <span>All Posts</span>
          </Button>
        )}
        
        <Separator className="bg-sidebar-border" />
        
        <Button
          variant="ghost"
          className={`w-full justify-start ${isActive('/dashboard/settings') ? 'text-sidebar-primary bg-sidebar-active' : 'text-sidebar-foreground'}`}
          onClick={() => navigate('/dashboard/settings')}
        >
          <Settings className="h-5 w-5 mr-2" />
          <span>Settings</span>
        </Button>
        
        <Button
          variant="ghost"
          className="w-full justify-start text-sidebar-foreground"
          onClick={() => navigate('/help')}
        >
          <HelpCircle className="h-5 w-5 mr-2" />
          <span>Help</span>
        </Button>
        
        <Button
          variant="ghost"
          className="w-full justify-start text-sidebar-foreground"
          onClick={() => navigate('/support')}
        >
          <Headset className="h-5 w-5 mr-2" />
          <span>Support</span>
        </Button>
      </nav>
      
      {tier !== 'pro' && (
        <div className="mt-6 space-y-4">
          <Button
            className="w-full bg-gradient-to-r from-orange-400 to-orange-500 hover:from-orange-500 hover:to-orange-600 text-white transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
            onClick={() => navigate('/pricing')}
          >
            <Trophy className="h-4 w-4 mr-2" />
            {getUpgradeText()}
          </Button>
          <p className="text-xs text-muted-foreground text-center px-2">
            {tier === 'free' 
              ? 'Unlock 90-day history, export, and more insights' 
              : 'Unlock multi-profile tracking, advanced features, and CSV export'}
          </p>
        </div>
      )}
    </aside>
  );
};
