import { Card } from "@/components/ui/card";
import { Info } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { EngagementChart } from "./charts/EngagementChart";
import { PerformanceInsights } from "./insights/PerformanceInsights";

const data = [
  { date: "Jan", engagement: 65, benchmark: 45, followers: 3200 },
  { date: "Feb", engagement: 75, benchmark: 48, followers: 3800 },
  { date: "Mar", engagement: 85, benchmark: 52, followers: 4100 },
  { date: "Apr", engagement: 82, benchmark: 55, followers: 4300 },
  { date: "May", engagement: 90, benchmark: 58, followers: 4532 },
];

export const AdvancedAnalytics = () => {
  return (
    <Card className="p-6 space-y-6 hover:shadow-lg transition-all duration-300">
      <div className="space-y-1">
        <h3 className="text-xl font-semibold flex items-center gap-2">
          Advanced Analytics
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Info className="w-4 h-4 text-gray-400" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-sm">Compare your performance against industry benchmarks</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </h3>
        <p className="text-sm text-gray-600 animate-fade-up">
          Your engagement grew by 15% this quarter, outperforming 75% of industry peers
        </p>
      </div>

      <div className="bg-green-50 p-4 rounded-lg border border-green-100 animate-fade-up">
        <div className="flex items-center gap-2 text-green-700">
          <Info className="w-4 h-4" />
          <p className="font-medium">Key Achievement</p>
        </div>
        <p className="text-sm text-green-600 mt-1">
          Your engagement is 15% higher than your peers
        </p>
      </div>

      <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
        <p className="text-sm text-blue-700">
          <span className="font-medium">Key Milestone:</span> Reached 5,000 followers on January 15th
        </p>
      </div>

      <EngagementChart data={data} />
      <PerformanceInsights />
    </Card>
  );
};