
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useToast } from "@/components/ui/use-toast";
import { getMockProfileData } from "@/utils/mockProfileData";
import { ProProfileWizard } from "@/components/onboarding/ProProfileWizard";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

const ProProfileAnalysis = () => {
  const { isLoaded, isSignedIn } = useUser();
  const { canAccessPro } = usePlanFeatures();
  const [hasProjects, setHasProjects] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  
  useEffect(() => {
    if (isLoaded) {
      // Redirect non-pro users
      if (!canAccessPro) {
        navigate('/dashboard', { replace: true });
        return;
      }
      
      // Check if there are any projects
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      setHasProjects(projects.length > 0);
    }
  }, [isLoaded, canAccessPro, navigate]);

  // Listen for storage updates to refresh projects count
  useEffect(() => {
    const handleStorageUpdate = () => {
      const projects = JSON.parse(localStorage.getItem('projects') || '[]');
      setHasProjects(projects.length > 0);
    };
    
    window.addEventListener('storageUpdated', handleStorageUpdate);
    return () => {
      window.removeEventListener('storageUpdated', handleStorageUpdate);
    };
  }, []);
  
  // If not loaded yet, show nothing
  if (!isLoaded) {
    return null;
  }
  
  // If not pro, don't show anything (redirect handled in useEffect)
  if (!canAccessPro) {
    return null;
  }
  
  // If user is signed in and has no projects, show the wizard
  if (isSignedIn && !hasProjects) {
    return (
      <div className="flex-1 p-4 md:p-6 overflow-auto flex items-center justify-center">
        <ProProfileWizard />
      </div>
    );
  }
  
  // If they have projects, redirect to the dashboard
  navigate('/dashboard', { replace: true });
  return null;
};

export default ProProfileAnalysis;
