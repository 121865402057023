import { Card } from "@/components/ui/card";
import { Lock } from "lucide-react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';

interface EngagementOverviewProps {
  posts: Array<{
    id: number;
    likes: number;
    comments: number;
    shares?: number;
  }>;
}

export const EngagementOverview = ({ posts }: EngagementOverviewProps) => {
  const calculateEngagementRate = (post: { likes: number; comments: number; shares?: number }): number => {
    const total = post.likes + post.comments + (post.shares || 0);
    return (total / 15234) * 100;
  };

  const averageEngagement = posts.reduce((acc, post) => acc + calculateEngagementRate(post), 0) / posts.length;

  const engagementData = posts.map(post => ({
    id: post.id,
    engagement: calculateEngagementRate(post),
  }));

  const industryAverage = 2.0;

  return (
    <Card className="p-6 space-y-6 hover:shadow-lg transition-all duration-300">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold mb-1">Engagement Overview</h3>
          <div className="text-2xl font-bold text-linkedin-primary animate-fade-up">
            {averageEngagement.toFixed(1)}%
          </div>
          <div className="text-sm text-gray-600">Average Engagement Rate</div>
        </div>
      </div>
      
      <div className="h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={engagementData} className="animate-fade-up">
            <XAxis 
              dataKey="id" 
              label="Post" 
              stroke="#94a3b8"
              fontSize={12}
            />
            <YAxis stroke="#94a3b8" fontSize={12} />
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const value = payload[0].value;
                  if (typeof value === 'number') {
                    return (
                      <div className="bg-white p-2 border rounded shadow-lg">
                        <p className="text-sm">
                          Post {payload[0].payload.id}: {value.toFixed(1)}%
                        </p>
                      </div>
                    );
                  }
                }
                return null;
              }}
            />
            <Bar 
              dataKey="engagement" 
              fill="#0A66C2"
              radius={[4, 4, 0, 0]}
              className="transition-all duration-300 hover:opacity-80"
            />
            <ReferenceLine 
              y={industryAverage} 
              stroke="#94a3b8" 
              strokeDasharray="3 3" 
              label={{ 
                value: 'Industry Avg', 
                position: 'right',
                fill: '#94a3b8',
                fontSize: 12
              }} 
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="space-y-4 border-t pt-4">
        <div className="text-sm text-gray-600">
          Your engagement rate is {averageEngagement > industryAverage ? 
            <span className="text-green-500 font-medium">
              {((averageEngagement - industryAverage) / industryAverage * 100).toFixed(0)}% higher
            </span> : 
            <span className="text-gray-500">
              {((industryAverage - averageEngagement) / industryAverage * 100).toFixed(0)}% lower
            </span>
          } than industry average
        </div>
        
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Lock className="w-4 h-4 text-linkedin-primary" />
            <span>See which post types drive engagement with Pro</span>
          </div>
        </div>
      </div>
    </Card>
  );
};