import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Progress } from "@/components/ui/progress";
import { Trophy, Info, ArrowUp, AlertCircle } from "lucide-react";
import { useState, useEffect } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface ProfileHeaderProProps {
  name: string;
  title: string;
  avatarUrl: string;
  profileStrength: number;
  engagementScore: number;
}

export const ProfileHeaderPro = ({
  name,
  title,
  avatarUrl,
  profileStrength,
  engagementScore,
}: ProfileHeaderProProps) => {
  const [animatedScore, setAnimatedScore] = useState(0);
  const [animatedStrength, setAnimatedStrength] = useState(0);

  useEffect(() => {
    const duration = 1000;
    const steps = 60;
    const strengthIncrement = profileStrength / steps;
    const scoreIncrement = engagementScore / steps;
    const stepDuration = duration / steps;
    let currentStep = 0;

    const timer = setInterval(() => {
      currentStep++;
      setAnimatedStrength(prev => Math.min(profileStrength, prev + strengthIncrement));
      setAnimatedScore(prev => Math.min(engagementScore, prev + scoreIncrement));

      if (currentStep >= steps) {
        clearInterval(timer);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [profileStrength, engagementScore]);

  const getScoreColor = (score: number) => {
    if (score >= 80) return "text-green-500";
    if (score >= 60) return "text-yellow-500";
    return "text-red-500";
  };

  const improvementSuggestions = [
    {
      text: "Complete your summary section",
      impact: 15,
      priority: "high",
      tooltip: "A complete summary section helps visitors understand your expertise and increases profile views by up to 30%"
    },
    {
      text: "Add more work experience details",
      impact: 10,
      priority: "medium",
      tooltip: "Detailed work experience helps validate your skills and attracts more relevant connections"
    },
    {
      text: "Increase post frequency to 3x per week",
      impact: 20,
      priority: "high",
      tooltip: "Regular posting increases your visibility and engagement rate by up to 40%"
    }
  ];

  return (
    <div className="relative space-y-6 bg-white rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="absolute inset-0 bg-gradient-to-br from-linkedin-primary/5 to-transparent rounded-lg" />
      
      <div className="relative flex items-start gap-6">
        <div className="relative group">
          <div className="absolute inset-0 bg-gradient-to-r from-linkedin-primary to-linkedin-light rounded-full blur-sm opacity-20 group-hover:opacity-30 transition-opacity" />
          <Avatar className="w-20 h-20 border-2 border-white shadow-xl transition-transform duration-300 group-hover:scale-105">
            <AvatarImage src={avatarUrl} />
            <AvatarFallback>{name.split(" ").map(n => n[0]).join("")}</AvatarFallback>
          </Avatar>
        </div>
        
        <div className="flex-1 space-y-4">
          <div className="animate-fade-up">
            <h2 className="text-2xl font-semibold text-gray-900">{name}</h2>
            <p className="text-gray-600">{title}</p>
          </div>
          
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Profile Strength</span>
                <span className="text-sm font-medium">{Math.round(animatedStrength)}%</span>
              </div>
              <Progress 
                value={animatedStrength} 
                className="h-2 transition-all duration-300"
              />
            </div>

            <div className="grid gap-4 md:grid-cols-2">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="flex items-center gap-2 p-3 rounded-lg bg-gradient-to-br from-linkedin-primary/10 to-transparent hover:from-linkedin-primary/20 transition-colors">
                    <Trophy className="w-5 h-5 text-linkedin-primary" />
                    <span className={`font-semibold ${getScoreColor(engagementScore)}`}>
                      {Math.round(animatedScore)}/100
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="w-64 p-3">
                    <p className="text-sm">
                      Engagement Score based on follower growth, engagement rate, and content performance
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <div className="flex items-center gap-2 p-3 rounded-lg bg-green-50">
                <ArrowUp className="w-5 h-5 text-green-500" />
                <span className="text-sm text-green-700">15% growth this month</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative space-y-2 mt-4 animate-fade-up">
        <div className="flex items-center gap-2">
          <AlertCircle className="w-4 h-4 text-linkedin-primary" />
          <h4 className="font-medium text-sm">Improvement Suggestions</h4>
        </div>
        <div className="grid gap-2">
          {improvementSuggestions.map((suggestion, index) => (
            <TooltipProvider key={index}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div 
                    className={`p-3 rounded-lg transition-all duration-300 cursor-help
                      ${suggestion.priority === 'high' 
                        ? 'bg-orange-50 text-orange-700 hover:bg-orange-100' 
                        : 'bg-blue-50 text-blue-700 hover:bg-blue-100'
                      }`}
                  >
                    <div className="flex items-center justify-between">
                      <span className="text-sm">{suggestion.text}</span>
                      <span className="text-xs font-medium">+{suggestion.impact} points</span>
                    </div>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="max-w-xs p-3">
                  <p className="text-sm">{suggestion.tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      </div>
    </div>
  );
};