import { TimeframeBanner } from "../../TimeframeBanner";
import { ActionButtons } from "./ActionButtons";
import { ProfileSelector } from "../ProfileSelector";

interface ReportHeaderProps {
  timeframe: {
    start: string;
    end: string;
  };
  onTimeframeChange: (value: string) => void;
}

export const ReportHeader = ({ timeframe, onTimeframeChange }: ReportHeaderProps) => {
  const handleProfileChange = (profile: any) => {
    console.log("Profile changed:", profile);
  };

  return (
    <div className="space-y-4 animate-fade-up">
      <h2 className="text-2xl font-semibold text-linkedin-primary mb-6">
        Pro Report Analysis Period: {timeframe.start} - {timeframe.end}
      </h2>
      <div className="flex flex-col md:flex-row gap-4 items-start">
        <ProfileSelector onProfileChange={handleProfileChange} />
        <div className="flex-1 flex justify-end">
          <ActionButtons />
        </div>
      </div>
      <TimeframeBanner 
        {...timeframe} 
        isPro={true}
        onTimeframeChange={onTimeframeChange}
      />
    </div>
  );
};