
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FeaturesList } from "./FeaturesList";
import { LinkedInCard } from "./LinkedInCard";
import { ReportCreation } from "./ReportCreation";
import { LinkedInReport } from "./LinkedInReport";
import { LinkedInProReport } from "./LinkedInProReport";
import { useToast } from "@/components/ui/use-toast";
import { URLInputSection } from "./linkedin/URLInputSection";
import { TooltipProvider } from "@/components/ui/tooltip";
import { useSubscription } from "@/hooks/useSubscription";
import { supabase } from "@/integrations/supabase/client";
import { useUser } from "@clerk/clerk-react";
import { useIsMobile } from "@/hooks/use-mobile";

export const EmptyState = () => {
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [isCreatingReport, setIsCreatingReport] = useState(false);
  const [isReportComplete, setIsReportComplete] = useState(false);
  const [isProVersion, setIsProVersion] = useState(false);
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const [activeProfile, setActiveProfile] = useState<{url: string, isProVersion?: boolean} | null>(null);
  const location = useLocation();
  const isAuditRoute = location.pathname === "/audit";
  const { data: subscription } = useSubscription();
  const { user } = useUser();

  useEffect(() => {
    if (!isAuditRoute) {
      const checkActiveProfile = () => {
        const projectsData = localStorage.getItem('projects');
        if (!projectsData) return;
        
        try {
          const projects = JSON.parse(projectsData);
          if (!Array.isArray(projects)) return;
          
          // Find first active profile in any project
          const activeProfile = projects.reduce((acc: any, project: any) => {
            if (!project.profiles || !Array.isArray(project.profiles)) return acc;
            
            const active = project.profiles.find((profile: any) => profile.isActive);
            return active || acc;
          }, null);
          
          if (activeProfile) {
            setLinkedinUrl(activeProfile.url);
            setIsReportComplete(true);
            setActiveProfile(activeProfile);
            setIsProVersion(!!activeProfile.isProVersion);
          }
        } catch (error) {
          console.error("Error parsing projects data:", error);
        }
      };

      checkActiveProfile();
      window.addEventListener('storageUpdated', checkActiveProfile);
      
      return () => {
        window.removeEventListener('storageUpdated', checkActiveProfile);
      };
    }
  }, [isAuditRoute]);

  useEffect(() => {
    if (isAuditRoute) {
      setLinkedinUrl("");
      setIsReportComplete(false);
      setIsCreatingReport(false);
      setActiveProfile(null);
      setIsProVersion(false);
    }
  }, [isAuditRoute]);

  const checkReportLimits = async () => {
    if (!user?.id) return true; // Allow free users to create reports

    const { data, error } = await supabase
      .rpc('check_report_limits', {
        user_clerk_id: user.id
      });

    if (error) {
      console.error('Error checking report limits:', error);
      return true; // Default to allowing report creation if check fails
    }

    return data;
  };

  const handleStartAnalysis = async () => {
    if (!linkedinUrl) {
      toast({
        title: "Please enter a LinkedIn URL",
        description: "A valid LinkedIn profile URL is required to generate the report.",
        variant: "destructive",
      });
      return;
    }

    // Only check subscription for pro version
    if (isProVersion && !subscription) {
      toast({
        title: "Pro Version Restricted",
        description: "Please upgrade your plan to access Pro features.",
        variant: "destructive",
      });
      return;
    }

    // Check report limits only for subscribed users or when creating pro reports
    if (isProVersion || subscription) {
      const withinLimits = await checkReportLimits();
      if (!withinLimits) {
        toast({
          title: "Report Limit Reached",
          description: `You've reached the maximum number of reports allowed for your ${subscription?.plan || 'free'} plan. Please upgrade to analyze more profiles.`,
          variant: "destructive",
        });
        return;
      }
    }

    setIsCreatingReport(true);
    setTimeout(() => {
      setIsReportComplete(true);
      setIsCreatingReport(false);
      toast({
        title: "Report Generated Successfully",
        description: "Your LinkedIn profile analysis is ready to view.",
      });
    }, 5000);
  };

  const handleNewAnalysis = () => {
    setLinkedinUrl("");
    setIsReportComplete(false);
    setIsCreatingReport(false);
    setActiveProfile(null);
  };

  return (
    <TooltipProvider>
      <div className="min-h-screen bg-gradient-to-br from-white to-blue-50">
        <main className="p-4 md:p-8 overflow-auto">
          <div className="max-w-4xl mx-auto space-y-8">
            {(!activeProfile || isAuditRoute) && !isReportComplete && (
              <URLInputSection
                linkedinUrl={linkedinUrl}
                setLinkedinUrl={setLinkedinUrl}
                isProVersion={isProVersion}
                setIsProVersion={setIsProVersion}
                isReportComplete={isReportComplete}
                handleNewAnalysis={handleNewAnalysis}
                handleStartAnalysis={handleStartAnalysis}
                subscription={subscription}
              />
            )}

            {!isReportComplete && !isCreatingReport && (!activeProfile || isAuditRoute) && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-12 animate-fade-up">
                <FeaturesList />
                <LinkedInCard />
              </div>
            )}

            {isCreatingReport && (
              <div className="relative bg-gradient-to-r from-[#F8F9FF] to-[#F1F0FB] rounded-lg p-8 space-y-4 shadow-sm">
                <ReportCreation linkedinUrl={linkedinUrl} />
              </div>
            )}

            {(isReportComplete || (activeProfile && !isAuditRoute)) && !isCreatingReport && (
              isProVersion ? (
                <LinkedInProReport url={linkedinUrl} />
              ) : (
                <LinkedInReport url={linkedinUrl} />
              )
            )}
          </div>
        </main>
      </div>
    </TooltipProvider>
  );
};
