
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useUser } from "@clerk/clerk-react";
import { ArrowRight, Plus, X, Check } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

interface ProfileData {
  url: string;
  name: string;
  keywords: string[];
  competitors: string[];
}

export function ProProfileWizard() {
  const [step, setStep] = useState(1);
  const [profiles, setProfiles] = useState<ProfileData[]>([]);
  const [currentProfile, setCurrentProfile] = useState<ProfileData>({
    url: "",
    name: "",
    keywords: [],
    competitors: ["", "", ""]
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();
  
  const handleStartAddingProfiles = () => {
    setStep(2);
  };
  
  const handleKeywordAdd = () => {
    if (currentKeyword && currentProfile.keywords.length < 20) {
      setCurrentProfile({
        ...currentProfile,
        keywords: [...currentProfile.keywords, currentKeyword]
      });
      setCurrentKeyword("");
    }
  };
  
  const handleKeywordRemove = (index: number) => {
    setCurrentProfile({
      ...currentProfile,
      keywords: currentProfile.keywords.filter((_, i) => i !== index)
    });
  };
  
  const handleCompetitorChange = (index: number, value: string) => {
    const newCompetitors = [...currentProfile.competitors];
    newCompetitors[index] = value;
    setCurrentProfile({
      ...currentProfile,
      competitors: newCompetitors
    });
  };
  
  const handleSaveProfile = (andAddAnother = false) => {
    if (!currentProfile.url) {
      toast({
        title: "LinkedIn URL Required",
        description: "Please enter a LinkedIn profile URL to continue.",
        variant: "destructive"
      });
      return;
    }
    
    const newProfiles = [...profiles, currentProfile];
    setProfiles(newProfiles);
    
    if (andAddAnother && newProfiles.length < 5) {
      // Reset form for next profile
      setCurrentProfile({
        url: "",
        name: "",
        keywords: [],
        competitors: ["", "", ""]
      });
    } else {
      // Move to final step
      setStep(3);
    }
  };
  
  const handleSkipAndFinish = () => {
    if (profiles.length === 0) {
      toast({
        title: "Add at least one profile",
        description: "Please add at least one LinkedIn profile to continue.",
        variant: "destructive"
      });
      return;
    }
    setStep(3);
  };
  
  const handleFinish = async () => {
    setIsLoading(true);
    
    try {
      // Save all profiles to localStorage
      const projects = profiles.map(profile => ({
        id: crypto.randomUUID(),
        name: profile.name || "Unnamed Project",
        profiles: [{
          id: crypto.randomUUID(),
          name: profile.name || "Unnamed Profile",
          url: profile.url,
          isActive: true,
          keywords: profile.keywords,
          competitors: profile.competitors.filter(c => c)
        }]
      }));
      
      localStorage.setItem('projects', JSON.stringify(projects));
      window.dispatchEvent(new Event('storageUpdated'));
      
      // If user is authenticated, store in database too
      if (user?.id) {
        const reportPromises = profiles.map(profile => 
          supabase.from('linkedin_reports').insert({
            clerk_user_id: user.id,
            linkedin_url: profile.url,
            project_name: profile.name || "Unnamed Project",
            status: 'pending',
            keywords: profile.keywords,
            competitors: profile.competitors.filter(c => c)
          })
        );
        
        await Promise.all(reportPromises);
      }
      
      toast({
        title: "Setup Complete!",
        description: `You've added ${profiles.length} profile${profiles.length > 1 ? 's' : ''}. We'll start analyzing them now.`,
      });
      
      navigate('/dashboard');
    } catch (error) {
      console.error("Error saving profiles:", error);
      toast({
        title: "Something went wrong",
        description: "Failed to save your profiles. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Set Up Your Pro Profiles</h1>
            <p className="text-gray-600 mb-8">
              Let's add the LinkedIn profiles you want to track. You can add up to 5 profiles
              and provide optional keywords and competitors for each.
            </p>
            <Button 
              onClick={handleStartAddingProfiles}
              className="h-12 px-8 bg-linkedin-primary hover:bg-linkedin-dark"
            >
              Start Adding Profiles
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        );
        
      case 2:
        return (
          <div>
            <h1 className="text-2xl font-bold mb-4">
              Add Profile ({profiles.length + 1} of 5)
            </h1>
            <form className="space-y-6">
              <div className="space-y-2">
                <label htmlFor="linkedin-url" className="text-sm font-medium">
                  LinkedIn Profile URL <span className="text-red-500">*</span>
                </label>
                <Input
                  id="linkedin-url"
                  placeholder="https://linkedin.com/in/username"
                  value={currentProfile.url}
                  onChange={(e) => setCurrentProfile({...currentProfile, url: e.target.value})}
                  required
                />
              </div>
              
              <div className="space-y-2">
                <label htmlFor="project-name" className="text-sm font-medium">
                  Project Name <span className="text-red-500">*</span>
                </label>
                <Input
                  id="project-name"
                  placeholder="My Project"
                  value={currentProfile.name}
                  onChange={(e) => setCurrentProfile({...currentProfile, name: e.target.value})}
                  required
                />
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium flex justify-between">
                  <span>Target Keywords/Topics (up to 20)</span>
                  <span className="text-gray-500 text-xs">Optional</span>
                </label>
                <div className="flex space-x-2">
                  <Input
                    placeholder="Add a keyword"
                    value={currentKeyword}
                    onChange={(e) => setCurrentKeyword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleKeywordAdd();
                      }
                    }}
                  />
                  <Button 
                    type="button" 
                    onClick={handleKeywordAdd}
                    disabled={!currentKeyword || currentProfile.keywords.length >= 20}
                    variant="outline"
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
                <p className="text-xs text-gray-500">
                  Add keywords relevant to this profile's industry or content focus.
                </p>
                
                {currentProfile.keywords.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {currentProfile.keywords.map((keyword, i) => (
                      <div key={i} className="flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm">
                        {keyword}
                        <button
                          type="button"
                          onClick={() => handleKeywordRemove(i)}
                          className="ml-2 text-gray-500 hover:text-red-500"
                        >
                          <X className="h-3 w-3" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium flex justify-between">
                  <span>Competitor URLs (up to 3)</span>
                  <span className="text-gray-500 text-xs">Optional</span>
                </label>
                <div className="space-y-2">
                  {[0, 1, 2].map((i) => (
                    <Input
                      key={i}
                      placeholder={`Competitor ${i + 1} URL`}
                      value={currentProfile.competitors[i] || ""}
                      onChange={(e) => handleCompetitorChange(i, e.target.value)}
                    />
                  ))}
                </div>
                <p className="text-xs text-gray-500">
                  Add competitor profiles for benchmarking.
                </p>
              </div>
              
              <div className="flex flex-col sm:flex-row sm:justify-between gap-4 pt-4">
                <Button
                  type="button"
                  onClick={() => handleSaveProfile(true)}
                  disabled={profiles.length >= 4 || !currentProfile.url}
                  className="w-full sm:w-auto bg-linkedin-primary hover:bg-linkedin-dark"
                >
                  Save & Add Another
                </Button>
                
                <Button
                  type="button"
                  onClick={() => handleSaveProfile(false)}
                  disabled={!currentProfile.url}
                  className="w-full sm:w-auto bg-linkedin-primary hover:bg-linkedin-dark"
                >
                  Save & Finish
                </Button>
                
                {profiles.length > 0 && (
                  <Button
                    type="button"
                    onClick={handleSkipAndFinish}
                    variant="outline"
                    className="w-full sm:w-auto"
                  >
                    Skip & Finish
                  </Button>
                )}
              </div>
            </form>
          </div>
        );
        
      case 3:
        return (
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Setup Complete!</h1>
            <div className="bg-gray-50 rounded-lg p-6 mb-8">
              <p className="text-gray-600 mb-4">
                You've added {profiles.length} profile{profiles.length > 1 ? 's' : ''}.
                We'll start analyzing them now. You can view progress and results on your dashboard.
              </p>
              <div className="space-y-2">
                {profiles.map((profile, i) => (
                  <div key={i} className="flex items-center text-left">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span className="font-medium">{profile.name || profile.url}</span>
                  </div>
                ))}
              </div>
            </div>
            <Button 
              onClick={handleFinish}
              className="h-12 px-8 bg-linkedin-primary hover:bg-linkedin-dark"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                <span className="flex items-center">
                  Go to Dashboard
                  <ArrowRight className="ml-2 h-5 w-5" />
                </span>
              )}
            </Button>
          </div>
        );
        
      default:
        return null;
    }
  };
  
  return (
    <div className="w-full max-w-2xl mx-auto p-6 bg-white rounded-xl shadow-md animate-fade-up">
      {renderStep()}
    </div>
  );
}
