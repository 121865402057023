
import { FC } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { ChevronUp, ChevronDown } from "lucide-react";

interface InfluenceScoreProps {
  score: number;
  summary?: string;
  metrics?: {
    followers: number;
    followerChange?: number;
    engagementRate: number;
    engagementChange?: number;
    postFrequency: number;
    postFrequencyChange?: number;
    profileStrength: number;
    profileStrengthChange?: number;
  };
  tier?: 'free' | 'individual' | 'pro';
  size?: 'small' | 'medium' | 'large';
}

export const InfluenceScore: FC<InfluenceScoreProps> = ({ 
  score, 
  summary,
  metrics,
  tier = 'free',
  size = 'medium'
}) => {
  const showTrends = tier !== 'free';
  
  // If size is small, render a simplified version
  if (size === 'small') {
    return (
      <div className="flex items-center gap-2">
        <div className={`w-14 h-14 rounded-full border-4 flex items-center justify-center ${
          score >= 80 ? 'border-green-500' : 
          score >= 60 ? 'border-orange-500' : 
          'border-red-500'
        }`}>
          <span className="text-lg font-bold">{score}</span>
        </div>
        <div>
          <p className="text-xs text-gray-500">Influence Score</p>
          <p className="text-sm font-medium">
            {score >= 80 ? 'Strong' : 
             score >= 60 ? 'Good' : 
             score >= 40 ? 'Fair' : 
             'Needs Work'}
          </p>
        </div>
      </div>
    );
  }
  
  // If we don't have metrics, don't try to render them
  if (!metrics) {
    return (
      <Card>
        <CardContent className="p-6">
          <div className="flex flex-col md:flex-row md:items-center gap-8">
            <div className="flex items-center justify-center">
              <div className={`w-28 h-28 md:w-32 md:h-32 rounded-full border-8 flex items-center justify-center ${
                score >= 80 ? 'border-green-500' : 
                score >= 60 ? 'border-orange-500' : 
                'border-red-500'
              }`}>
                <span className="text-3xl md:text-4xl font-bold">{score}</span>
              </div>
            </div>
            
            {summary && <div className="flex-1"><p className="text-sm md:text-base">{summary}</p></div>}
          </div>
        </CardContent>
      </Card>
    );
  }
  
  return (
    <Card>
      <CardContent className="p-6">
        <div className="flex flex-col md:flex-row md:items-center gap-8">
          <div className="flex items-center justify-center">
            <div className={`w-28 h-28 md:w-32 md:h-32 rounded-full border-8 flex items-center justify-center ${
              score >= 80 ? 'border-green-500' : 
              score >= 60 ? 'border-orange-500' : 
              'border-red-500'
            }`}>
              <span className="text-3xl md:text-4xl font-bold">{score}</span>
            </div>
          </div>
          
          <div className="flex-1 space-y-4">
            {summary && <p className="text-sm md:text-base">{summary}</p>}
            
            <div className="grid grid-cols-3 gap-3 md:gap-4">
              <div className="p-3 bg-gray-50 rounded-lg">
                <p className="text-xs md:text-sm text-gray-500">Followers</p>
                <p className="text-base md:text-xl font-semibold">{metrics.followers.toLocaleString()}</p>
                {showTrends && metrics.followerChange !== undefined && (
                  <div className={`flex items-center text-xs md:text-sm mt-1 ${
                    metrics.followerChange >= 0 ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {metrics.followerChange >= 0 ? 
                      <ChevronUp className="h-3 w-3 mr-1" /> : 
                      <ChevronDown className="h-3 w-3 mr-1" />
                    }
                    {Math.abs(metrics.followerChange)}%
                  </div>
                )}
              </div>
              
              <div className="p-3 bg-gray-50 rounded-lg">
                <p className="text-xs md:text-sm text-gray-500">Engagement</p>
                <p className="text-base md:text-xl font-semibold">{metrics.engagementRate}%</p>
                {showTrends && metrics.engagementChange !== undefined && (
                  <div className={`flex items-center text-xs md:text-sm mt-1 ${
                    metrics.engagementChange >= 0 ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {metrics.engagementChange >= 0 ? 
                      <ChevronUp className="h-3 w-3 mr-1" /> : 
                      <ChevronDown className="h-3 w-3 mr-1" />
                    }
                    {Math.abs(metrics.engagementChange)}%
                  </div>
                )}
              </div>
              
              <div className="p-3 bg-gray-50 rounded-lg">
                <p className="text-xs md:text-sm text-gray-500">Post Frequency</p>
                <p className="text-base md:text-xl font-semibold">{metrics.postFrequency}/wk</p>
                {showTrends && metrics.postFrequencyChange !== undefined && (
                  <div className={`flex items-center text-xs md:text-sm mt-1 ${
                    metrics.postFrequencyChange >= 0 ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {metrics.postFrequencyChange >= 0 ? 
                      <ChevronUp className="h-3 w-3 mr-1" /> : 
                      <ChevronDown className="h-3 w-3 mr-1" />
                    }
                    {Math.abs(metrics.postFrequencyChange)}/wk
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

