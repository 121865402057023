
import { FC, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Eye, Heart, MessageSquare, Share2, Calendar, FileText, ArrowUpDown } from "lucide-react";
import { Post } from "./TopPostsList";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { usePlanFeatures } from "@/hooks/usePlanFeatures";

interface AllPostsTableProps {
  tier: 'free' | 'individual' | 'pro';
  posts: Post[];
}

type SortDirection = "asc" | "desc";
type SortField = "date" | "likes" | "comments" | "shares" | "views";

export const AllPostsTable: FC<AllPostsTableProps> = ({
  tier,
  posts
}) => {
  const { dataHistory } = usePlanFeatures();
  const [sortField, setSortField] = useState<SortField>("date");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [filterType, setFilterType] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  
  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("desc");
    }
  };
  
  // Filter posts
  const filteredPosts = posts.filter(post => {
    // Apply search filter
    if (searchQuery && !post.text.toLowerCase().includes(searchQuery.toLowerCase())) {
      return false;
    }
    
    // Apply type filter
    if (filterType !== "all" && post.type !== filterType) {
      return false;
    }
    
    return true;
  });
  
  // Sort posts
  const sortedPosts = [...filteredPosts].sort((a, b) => {
    switch (sortField) {
      case "date":
        return sortDirection === "asc" 
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : new Date(b.date).getTime() - new Date(a.date).getTime();
      case "likes":
        return sortDirection === "asc" ? a.likes - b.likes : b.likes - a.likes;
      case "comments":
        return sortDirection === "asc" ? a.comments - b.comments : b.comments - a.comments;
      case "shares":
        return sortDirection === "asc" ? a.shares - b.shares : b.shares - a.shares;
      case "views":
        return sortDirection === "asc" ? a.views - b.views : b.views - a.views;
      default:
        return 0;
    }
  });
  
  const timeframeMessage = () => {
    switch (tier) {
      case 'pro':
        return 'Full History Available';
      case 'individual':
        return `Last ${dataHistory} Days`;
      case 'free':
      default:
        return 'Last 30 Days (Limited)';
    }
  };
  
  return (
    <div className="space-y-4 mb-10">
      <Card className="border border-gray-200">
        <CardHeader className="pb-2">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <CardTitle>All Posts Analysis</CardTitle>
            <div className="text-sm text-gray-500 italic">{timeframeMessage()}</div>
          </div>
          
          <div className="mt-4 flex flex-col md:flex-row gap-3">
            <div className="flex-1">
              <Input
                placeholder="Search posts..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full"
              />
            </div>
            <Select value={filterType} onValueChange={setFilterType}>
              <SelectTrigger className="w-full md:w-[180px]">
                <SelectValue placeholder="Filter by type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Types</SelectItem>
                <SelectItem value="text">Text</SelectItem>
                <SelectItem value="image">Image</SelectItem>
                <SelectItem value="video">Video</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </CardHeader>
        
        <CardContent>
          <Tabs defaultValue="table" className="w-full">
            <TabsList className="mb-4">
              <TabsTrigger value="table">Table View</TabsTrigger>
              <TabsTrigger value="cards">Card View</TabsTrigger>
            </TabsList>
            
            <TabsContent value="table">
              <div className="rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Post Content</TableHead>
                      <TableHead>
                        <Button 
                          variant="ghost" 
                          onClick={() => handleSort("date")}
                          className="flex items-center p-0 h-8"
                        >
                          <Calendar className="h-4 w-4 mr-1" />
                          Date
                          {sortField === "date" && (
                            <ArrowUpDown className="h-3 w-3 ml-1" />
                          )}
                        </Button>
                      </TableHead>
                      <TableHead>
                        <Button 
                          variant="ghost" 
                          onClick={() => handleSort("likes")}
                          className="flex items-center p-0 h-8"
                        >
                          <Heart className="h-4 w-4 mr-1" />
                          Likes
                          {sortField === "likes" && (
                            <ArrowUpDown className="h-3 w-3 ml-1" />
                          )}
                        </Button>
                      </TableHead>
                      <TableHead>
                        <Button 
                          variant="ghost" 
                          onClick={() => handleSort("comments")}
                          className="flex items-center p-0 h-8"
                        >
                          <MessageSquare className="h-4 w-4 mr-1" />
                          Comments
                          {sortField === "comments" && (
                            <ArrowUpDown className="h-3 w-3 ml-1" />
                          )}
                        </Button>
                      </TableHead>
                      <TableHead>
                        <Button 
                          variant="ghost" 
                          onClick={() => handleSort("shares")}
                          className="flex items-center p-0 h-8"
                        >
                          <Share2 className="h-4 w-4 mr-1" />
                          Shares
                          {sortField === "shares" && (
                            <ArrowUpDown className="h-3 w-3 ml-1" />
                          )}
                        </Button>
                      </TableHead>
                      <TableHead>
                        <Button 
                          variant="ghost" 
                          onClick={() => handleSort("views")}
                          className="flex items-center p-0 h-8"
                        >
                          <Eye className="h-4 w-4 mr-1" />
                          Views
                          {sortField === "views" && (
                            <ArrowUpDown className="h-3 w-3 ml-1" />
                          )}
                        </Button>
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedPosts.map(post => (
                      <TableRow key={post.id}>
                        <TableCell className="max-w-[300px]">
                          <div className="flex items-center gap-2">
                            <div className="w-8 h-8 bg-linkedin-primary/10 rounded flex items-center justify-center flex-shrink-0">
                              {post.type === 'image' ? (
                                <img 
                                  src={post.image || `https://api.dicebear.com/7.x/shapes/svg?seed=${post.id}`} 
                                  alt="Post thumbnail" 
                                  className="w-full h-full object-cover rounded" 
                                />
                              ) : post.type === 'video' ? (
                                <div className="relative w-full h-full bg-linkedin-primary/20 rounded flex items-center justify-center">
                                  <div className="w-2 h-2 bg-white rounded-full"></div>
                                </div>
                              ) : (
                                <FileText className="h-4 w-4 text-linkedin-primary" />
                              )}
                            </div>
                            <span className="text-sm line-clamp-1">{post.text}</span>
                          </div>
                        </TableCell>
                        <TableCell className="text-sm text-gray-600">
                          {new Date(post.date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric'
                          })}
                        </TableCell>
                        <TableCell className="text-sm">{post.likes}</TableCell>
                        <TableCell className="text-sm">{post.comments}</TableCell>
                        <TableCell className="text-sm">{post.shares}</TableCell>
                        <TableCell className="text-sm">{post.views.toLocaleString()}</TableCell>
                      </TableRow>
                    ))}
                    {sortedPosts.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={6} className="text-center py-6 text-gray-500">
                          No posts found matching your criteria
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TabsContent>
            
            <TabsContent value="cards">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {sortedPosts.map(post => (
                  <div key={post.id} className="border rounded-md p-4 hover:bg-gray-50 transition-colors">
                    <div className="flex items-start gap-3">
                      <div className="flex-shrink-0 w-10 h-10 bg-linkedin-primary/10 rounded-md flex items-center justify-center">
                        {post.type === 'image' ? (
                          <img 
                            src={post.image || `https://api.dicebear.com/7.x/shapes/svg?seed=${post.id}`} 
                            alt="Post thumbnail" 
                            className="w-full h-full object-cover rounded-md" 
                          />
                        ) : post.type === 'video' ? (
                          <div className="relative w-full h-full bg-linkedin-primary/20 rounded-md flex items-center justify-center">
                            <div className="w-3 h-3 bg-white rounded-full"></div>
                          </div>
                        ) : (
                          <FileText className="h-5 w-5 text-linkedin-primary" />
                        )}
                      </div>
                      
                      <div className="flex-1 min-w-0">
                        <p className="text-sm line-clamp-3 mb-2">{post.text}</p>
                        
                        <div className="flex items-center gap-3 text-xs text-gray-500 flex-wrap">
                          <div className="flex items-center gap-1">
                            <Calendar className="h-3 w-3" />
                            <span>{new Date(post.date).toLocaleDateString('en-US', {
                              month: 'short',
                              day: 'numeric'
                            })}</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <Heart className="h-3 w-3" />
                            <span>{post.likes}</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <MessageSquare className="h-3 w-3" />
                            <span>{post.comments}</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <Share2 className="h-3 w-3" />
                            <span>{post.shares}</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <Eye className="h-3 w-3" />
                            <span>{post.views.toLocaleString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {sortedPosts.length === 0 && (
                  <div className="col-span-full text-center py-10 text-gray-500">
                    No posts found matching your criteria
                  </div>
                )}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};
